import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  ListGroupItem,
  ListGroup,
  Container,
  FormControl, Spinner
} from "react-bootstrap";
import Icons from '../../Component/Icons/Icons'
import pic from "../../Asset/img/pic.png";
import { GET, POST, _ip } from "../../service";
import swal from "sweetalert";
import Swal from 'sweetalert2';
import icon_danger from "../../Asset/img/icon-danger.png";
import { async } from "q";
import SunEditor, { buttonList } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export default class Add_new extends Component {
  constructor(props) {

    super(props);
    this.state = {
      filePic: {},
      index: 0,
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      isExampleLoading: false,
      editorState: EditorState.createEmpty(),
      newsInfo: {
        name_news: "",
        source_news: "",
        des_news: "",
        head_des_news: "",
        descript_news: ""
      },
      // desNewsInfo:{
      //   head_des_news:"",
      //   descript_news:""
      // },
      pic: ""
    };
  }
  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

  onClose = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };
  handleChange = (action, value) => {
    let { newsInfo } = this.state;
    // let { desNewsInfo } = this.state;
    newsInfo[action] = value;
    // desNewsInfo[action] = value;
    this.setState({
      newsInfo,
      // desNewsInfo
    });
  };
  handleSubmit = async () => {
    const formData = new FormData();
    let { filePic, newsInfo, desNewsInfo } = this.state;
    await formData.append("myImage", filePic);
    // formData.append("myImage", this.state.filePic);
    formData.append("name_news", newsInfo.name_news);
    formData.append("des_news", newsInfo.des_news);
    formData.append("head_des_news", newsInfo.head_des_news);
    // formData.append("descript_news", draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())));
    // console.log(draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())))
    formData.append("descript_news", this.state.editorState);
    try {
      Swal.fire({
        title: 'คุณต้องการเพิ่มข้อมูลใช่หรือไม่?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'cancel'
      }).then(async (result) => {
        if (result.value) {
          this.setState({ isExampleLoading: true })
          try {
            let check = await POST("create_news", formData, true);
            Swal.fire(
              'เพิ่มข้อมูลสำเร็จ',
              '',
              'success'
            ).then(() => {
              this.props.toggle("1");
              this.props.fetch();
              this.setState({
                desNewsInfo: {
                  head_des_news: "",
                  descript_news: ""
                },

                newsInfo: {
                  name_news: "",
                  source_news: "",
                  des_news: "",
                  head_des_news: "",
                  descript_news: ""
                },
                pic: ""
              });
            })
          } catch (error) {
            Swal.fire(
              'เกิดข้อผิดพลาด',
              '',
              'warning'
            )
          }
        }
        this.setState({
          isExampleLoading: false,
        });
      })


    } catch (error) {
    }
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState
    });
  };

  handleImage = selectorFiles => {
    if (selectorFiles.length > 0) {
      var file = selectorFiles[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.setState({
          pic: reader.result
        });
        this.setState({
          filePic: selectorFiles[0]
        });
      };
      reader.onerror = function (error) {
      };
    } else {
      this.setState({
        pic: ""
      });
    }
  };
  clearImage = () => {
    this.setState({
      pic: ""
    });
  };
  onClickUpload = async () => {
    // const formData = new FormData();
    // formData.append('myImage', this.state.filePic);
    // try {
    //     await POST('upload', formData, true)
    // } catch (error) {
    // }
  };
  handleImageUploadBefore(files, info) {
    console.log(files, info)
  }
  handleImageUpload(targetImgElement, index, state, imageInfo, remainingFilesCount) {
    console.log(targetImgElement, index, state, imageInfo, remainingFilesCount)
  }
  handlePaste(e, cleanData, maxCharCount) {
    console.log(e, cleanData, maxCharCount)
  }
  handleImageUploadError(errorMessage, result) {
    console.log(errorMessage, result)
  }
  handleImageUploadError(errorMessage, result) {
    console.log(errorMessage, result)
  }
  render() {
    let { filePicCar, editorState } = this.state;
    return (
      <div>
        <div>
          <Row style={{ padding: "1rem" }}>
            <FormControl
              style={{ display: "none" }}
              id="some"
              type="file"
              onChange={e => this.handleImage(e.target.files)}
              accept=".jpg,.png"
            />
            <div
              style={{
                margin: "auto",
                width: "50rem",
                height: "400px",
                backgroundColor: "#eee",
                position: "relative"
              }}
              hidden={this.state.pic !== ""}
            >
              <label for="some">
                <div
                  className="border-banner text-center"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    style={{ width: "200px", height: "200px", margin: "auto" }}
                    src={pic}
                  />
                    เลือกรูปภาพ
              </div>
              </label>
            </div>

            <div style={{ margin: "auto" }} hidden={this.state.pic === ""}>
              <img
                src={this.state.pic}
                style={{ width: "auto", height: '400px' }}
              />
            </div>
          </Row>

          <div
            style={{
              paddingLeft: "15rem",
              paddingRight: "15rem",
              justifyContent: "center",
              display: "flex"
            }}
          >
            <a className='trash'>
              <img className="imgdelete"
                src={Icons.IconDelete}
                style={{
                  cursor: "pointer", textAlign: "center", height: "2rem",
                  width: "2rem"
                }}
                onClick={e => this.clearImage()}
              />
            </a>
            {/* <FaTrash
                style={{ cursor: "pointer", height: "2rem", width: "2rem" }}
                onClick={e => this.clearImage()}
              /> */}
          </div>

          <Form className="admin_news">

            <div>
              <Form.Group as={Row} controlId="exampleForm.ControlTextarea1">
                <Form.Label
                  column
                  sm="2.5"
                  className='fontNew'
                >
                  ชื่อหัวข้อ :
              </Form.Label>
                <Col>
                  <Form.Control
                    onChange={e => this.handleChange("name_news", e.target.value)}
                    value={this.state.newsInfo.name_news}
                    type="text"
                    placeholder="ชื่อหัวข้อ"
                  />
                </Col>
              </Form.Group>
            </div>

            <div>
              <Form.Group as={Row} controlId="exampleForm.ControlTextarea1">
                <Form.Label
                  column
                  sm="2.5"
                  className='fontNew'
                >
                  คำอธิบาย :
              </Form.Label>
                <Col>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    placeholder="เขียนคำอธิบาย"
                    onChange={e => this.handleChange("des_news", e.target.value)}
                    value={this.state.newsInfo.des_news}
                  />
                </Col>
              </Form.Group>
            </div>
            <div>
              <Form.Group as={Row} controlId="exampleForm.ControlTextarea1">
                <Form.Label
                  column
                  sm="2.5" className='fontNew'
                >
                  ชื่อหัวข้อรายละเอียด :
              </Form.Label>
                <Col>
                  <Form.Control
                    onChange={e => this.handleChange("head_des_news", e.target.value)}
                    value={this.state.newsInfo.head_des_news}
                    type="text"
                    placeholder="ชื่อหัวข้อ"
                  />
                </Col>
              </Form.Group>
            </div>


            <Form.Group as={Row} controlId="exampleForm.ControlTextarea1">
              <Form.Label
                column
                sm="2.5"
                className='fontNew'
              >
                รายละเอียด :
              </Form.Label>
              <Col>
                <div style={{ border: "1px solid #ddd" }}>
                  <SunEditor setOptions={{
                    height: 200,
                    buttonList: buttonList.complex // Or Array of button list, eg. [['font', 'align'], ['image']]
                    // Other option
                  }}
                    onImageUploadBefore={this.handleImageUploadBefore()}
                    onImageUpload={this.handleImageUpload()}
                    onPaste={this.handlePaste()}
                    onImageUploadError={this.handleImageUploadError()}
                    //setContents={this.state.editorState}
                    onChange={this.onEditorStateChange} />

                </div>
              </Col>
            </Form.Group>

          </Form>
          <br />
          <div
            style={{
              paddingLeft: "15rem",
              paddingRight: "15rem",
              justifyContent: "center",
              display: "flex"
            }}
          >
            <button
              className="rcornersnew1"
              onClick={() => this.handleSubmit()}
            >
              {this.state.isExampleLoading && <Spinner animation="border" variant="primary" />}
                บันทึก
          </button>
          </div>
        </div>
      </div >
    );
  }
}
