import React, { Component } from 'react'
import { Navbar, Form, Button, Row } from "react-bootstrap";
import logo from '../Asset/img/logo_mitsu.png'
import { thisTypeAnnotation } from '@babel/types';
import { GET, POST, _ip } from "../service";
import swal from 'sweetalert';
import Responsive from 'react-responsive';
import icon_danger from '../Asset/img/icon-danger.png'
import '../Asset/scss/LoginStyle.scss'
const Desktop = props => <Responsive {...props} minWidth={768} />;
const Mobile = props => <Responsive {...props} maxWidth={767} />;

export default class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      login: {
        password_admin: '',
        user_admin: '',
      }
    }
  }

  handleChange = (action, value) => {
    let { login } = this.state;
    login[action] = value;
    this.setState({
      login,
    });
  };

  // handleSubmit = async () => {
  //   let { login } = this.state;
  //   try {
  //     let check = await POST("admin/login", login);

  //     localStorage.setItem("user_admin", true)
  //     // this.props.history.push("/people");
  //     // window.location.href = "/people"



  //   } catch (error) {
  //     swal({
  //       title: "รหัสผ่านไม่ถูกต้อง",
  //       icon: "error"
  //     });
  //   }
  // };

  render() {
    const {
      presshome,
      presslogin,
    } = this.props;
    return (
      <div>
        <Desktop>
          <div>
            <Navbar className="navbar-expand-lg navbar-light  w-100 justify-content-center" style={{ height: "15vh", background: "black", cursor: 'pointer' }} expand="lg">
              <Navbar onClick={presshome}  ><img style={{ maxHeight: "5rem", width: "auto" }} src={logo} /> </Navbar>
            </Navbar>
            <div className="banner-admin " >
              <div className="BoxLogin ">
                <div className='Fontadmin' >ลงชื่อเข้าสู่ระบบ
                        <Form >
                    <Form.Group controlId="formBasicEmail" style={{ textAlign: 'left' }}>
                      <Form.Label >Username</Form.Label>
                      <Form.Control type="user" placeholder="Username" onChange={e => this.handleChange("user_admin", e.target.value)}
                        value={this.state.login.user_admin} />
                      <Form.Text className="text-muted">
                      </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword" style={{ textAlign: 'left' }}>
                      <Form.Label >Password</Form.Label>
                      <Form.Control type="password" placeholder="Password" onChange={e => this.handleChange("password_admin", e.target.value)}
                        value={this.state.login.password_admin} />
                    </Form.Group>
                    <Button variant="dark" onClick={e => presslogin(this.state.login)}>
                      เข้าสู่ระบบ
                        </Button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </Desktop >
        <Mobile>
          <div className="mobilesize">
            <div className="danger">
              <Row style={{ justifyContent: 'center', padding: '1rem 0 2rem 0' }}><img src={icon_danger} width='100vw' height='auto' /><p /></Row>
              <Row ><center>ไม่รองรับการทำงานผ่าน Mobile</center></Row>
            </div>
          </div>
        </Mobile>
      </div >
    )
  }
}

