export default{
    IconBangkok:require('../../Asset/icons/icon_bangkok.png'),
    IconSCB:require('../../Asset/icons/ico_scb.png'),
    IconComplete:require('../../Asset/icons/icon_complete.png'),
    IconCorrectActive:require('../../Asset/icons/icon_correct_active.png'),
    IconCorrectNormal:require('../../Asset/icons/icon_correct_normal.png'),
    IconDanger:require('../../Asset/icons/icon_danger.png'),
    IconDelete:require('../../Asset/icons/icon_delete.png'),
    IconDown:require('../../Asset/icons/icon_down.png'),
    IconDownload:require('../../Asset/icons/icon_download.png'),
    IconEmail:require('../../Asset/icons/icon_email.png'),
    IconKasikorn:require('../../Asset/icons/icon_kasikorn.png'),
    IconKTB:require('../../Asset/icons/icon_ktb.png'),
    IconLocation:require('../../Asset/icons/icon_location.png'),
    IconMegaphone:require ('../../Asset/icons/icon_megaphone.png'),
    IconMoney:require('../../Asset/icons/icon_money.png'),
    IconPassword:require('../../Asset/icons/icon_password.png'),
    IconPhone:require('../../Asset/icons/icon_phone.png'),
    IconPrinter:require('../../Asset/icons/icon_printer.png'),
    IconRecommend:require('../../Asset/icons/icon_recommend.png'),
    IconRegister:require ('../../Asset/icons/icon_register.png'),
    IconTime:require ('../../Asset/icons/icon_time.png'),
    IconUp:require ('../../Asset/icons/icon_up.png'),
    IconUploadColor:require ('../../Asset/icons/icon_upload_color.png'),
    IconUpload:require ('../../Asset/icons/icon_upload.png'),
    IconUser:require ('../../Asset/icons/icon_user.png'),
    IconUser2:require ('../../Asset/icons/icon_user2.png'),
    Member:require ('../../Asset/icons/member.png'),
    Newpaper:require ('../../Asset/icons/newpaper.png'),
    Slip1:require ('../../Asset/icons/slip_1.png'),
    Slip2:require ('../../Asset/icons/slip_2.png'),
    User1:require ('../../Asset/icons/user_1.png'),
    User2:require ('../../Asset/icons/user_2.png'),
    Next:require ('../../Asset/icons/next.png')

}