import React, { Component } from 'react'
import '../../Asset/scss/Service.scss'
export default class Center extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="header header-white">
                    <div className="header-title">ศูนย์บริการมิตซูอุดร</div>
                    <div className="header-sub_title">เปิดบริการเต็มรูปแบบ</div>
                </div>
                <div className="service-center">
                    <div className="card-type-1">
                        <div className="card-type-1-img">
                            <img src={require('../../Asset/images/service/service6.jpg')} alt="" />
                        </div>
                        <div className="card-type-1-content">
                            <p>ศูนย์บริการมิตซูบิชิเต็มรูปแบบ อุปกรณ์เครื่องมือที่ทันสมัยได้รับมาตราฐานจาก มิตซูบิชิ มอเตอร์ส (ประเทศไทย)
                            พร้อมกับอุปกรณ์อำนวยความสะดวกสำหรับลูกค้าที่เข้ามาใช้บริการ
                            เพลิดเพลินกับบรรยากาศสบายๆ เป็นกันเอง</p>
                            <p>เพื่อความสะดวกรวดเร็วในการให้บริการ จองคิวนัดหมายล่วงหน้า
                            โทร. 088-548-8282 (ศูนย์บริการมิตซูอุดร)</p>
                        </div>
                    </div>
                    <div className="card-type-2 ">
                        <div className="card-type-2-img">
                            <img src={require('../../Asset/images/service/service1.jpg')} alt="" />
                        </div>
                        <div className="card-type-2-content">
                            <div>
                                <div className="card-type-2-content-title">Genuine Service</div>
                                <p>คุณภาพสินค้า และบริการที่ดี พร้อมบริการช่วยเหลือฉุกเฉิน ตลอด 24 ชม.</p>
                            </div>
                        </div>
                    </div>
                    <div className="card-type-2">
                        <div className="card-type-2-img">
                            <img src={require('../../Asset/images/service/article2.jpg')} alt="" />
                        </div>
                        <div className="card-type-2-content">
                            <div>
                                <div className="card-type-2-content-title">Genuine Parts</div>
                                <p>อะไหล่แท้ที่ตอบโจทย์ และเป็นศูนย์กลางอะไหล่ ช่วยลดทอนเวลาในการรอ พร้อมทั้งการคุ้มครองชิ้นส่วนในระยะยาว</p>
                            </div>
                        </div>
                    </div>
                    <div className="card-type-2">
                        <div className="card-type-2-img">
                            <img src={require('../../Asset/images/service/service3.jpg')} alt="" />
                        </div>
                        <div className="card-type-2-content">
                            <div>
                                <div className="card-type-2-content-title">Genuine Technician</div>
                                <p>ความเชี่ยวชาญที่เป็นของจริง ของช่างที่ผ่านการฝึกฝนจาก Education Academy</p>
                            </div>
                        </div>
                    </div>
                    <div className="card-type-2">
                        <div className="card-type-2-img">
                            <img src={require('../../Asset/images/service/article3.jpg')} alt="" />
                        </div>
                        <div className="card-type-2-content">
                            <div>
                                <div className="card-type-2-content-title">Genuine Performance</div>
                                <p>การดูแลและเข้าใจในสินค้าและการบริการ เพื่อให้ได้ที่สุดของความพึงพอใจ และคุณภาพของรถยนต์ที่ยาวนาน</p>
                            </div>
                        </div>
                    </div>
                    <div className="card-type-2">
                        <div className="card-type-2-img">
                            <img src={require('../../Asset/images/service/article5.jpg')} alt="" />
                        </div>
                        <div className="card-type-2-content">
                            <div>
                                <div className="card-type-2-content-title">Our Genuine Network & Accessibility</div>
                                <p>ที่สุดของความง่ายในการเข้าถึงผ่าน Mobile App และศูนย์บริการที่มีเครือข่ายกว่า 200 แห่ง ทั่วประเทศ</p>
                            </div>
                        </div>
                    </div>
                    <div className="card-type-1">
                        <div className="card-type-1-img">
                            <img src={require('../../Asset/images/service/article4.jpg')} alt="" />
                        </div>
                        <div className="card-type-1-content">
                            <p>ห้องพักลูกค้าโฉมใหม่ กว้างขวางกว่าเดิม เพื่อรองรับลูกค้าที่เข้ามาใช้บริการ
                            พร้อมกับสิ่งอำนวยความสะดวกสบาย มุมพักผ่อน , อินเตอร์เน็ต Free Wifi อาหารและเครื่องดื่มคอยให้บริการทุกวัน สำหรับลูกค้าทุกท่าน</p>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
