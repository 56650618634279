import React from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types';

export default function NavLink(props) {
    let to = typeof props.to === 'string' ? props.to : '#'
    let classActive = typeof props.classActive === 'string' ? props.classActive : ''
    let className = typeof props.className === 'string' ? props.className : ''
    let tag = typeof props.tag === 'string' ? props.tag : ''
    let location = useLocation();
    //const checkActive = (to.slice(0, to.indexOf('?')) === location.pathname || location.pathname.includes(tag || null)) ? classActive : ''
    const checkActive = to.split("/")[1] === location.pathname.split("/")[1] || tag.split("/")[1] === location.pathname.split("/")[1] ? classActive : ''

    return to
        ? <a href={to} className={`${className} ${checkActive}`} >{props.children}</a>
        : <button className={`${className} ${checkActive}`}>{props.children}</button>
}

NavLink.defaultProps = {
    to: '',
    tag: '',
    classActive: '',
    className: '',
}

NavLink.propTypes = {
    to: PropTypes.string,
    tag: PropTypes.string,
    classActive: PropTypes.string,
    className: PropTypes.string,
}