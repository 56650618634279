import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  ListGroupItem,
  ListGroup,
  Container,
  FormControl, Spinner
} from "react-bootstrap";
import SunEditor, { buttonList } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import Icons from '../../Component/Icons/Icons'
import pic from "../../Asset/img/pic.png";
import { GET, POST, _ip } from "../../service";
import swal from "sweetalert";
import Responsive from "react-responsive";
import icon_danger from "../../Asset/img/icon-danger.png";
import Swal from "sweetalert2";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export default class Edit_new extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filePicNews: {},
      id: "",
      index: 0,
      news: "",
      showingInfoWindow: false,
      editorState: EditorState.createEmpty(),
      content: EditorState.createEmpty(),
      activeMarker: {},
      selectedPlace: {},
      newsInfo: {
        name_news: "",
        source_news: "",
        des_news: ""
      },
      isExampleLoading: false,
      desNewsInfo: {
        head_des_news: "",
        descript_news: ""
      },
      //  buffer: {
      //     name_news: "",
      //     source_news: "",
      //     des_news: ""
      //   },
      picNews: ""
    };
  }
  componentDidMount = async () => {
    let { id } = this.props.match.params;
    let id_news = id

    try {
      let news = await POST("news_by_id", { id_news });

      this.setState({
        picNews: news.news.source_news,
        des_news: news.news.des_news,
        name_news: news.news.name_news
      });


      this.setState({
        descript_news: news.detail.descript_news,
        head_des_news: news.detail.head_des_news
      });

      // const { contentBlocks, entityMap } = htmlToDraft(news.detail.descript_news);
      // const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      // this.setState({ editorState: EditorState.createWithContent(contentState) });
      this.setState({ content: news.detail.descript_news })

    } catch (error) { }
  };

  handleChange = ({ target: { value, name } }) =>
    this.setState(
      { [name]: value },
    );

  handleSubmit = async () => {
    const { name_news, des_news, head_des_news, descript_news } = this.state;
    const formData = new FormData();
    if (name_news && des_news && head_des_news && descript_news) {
      formData.append("id_news", this.props.match.params.id);
      formData.append("myImage", this.state.filePicNews);
      formData.append("name_news", this.state.name_news);
      formData.append("des_news", this.state.des_news);
      formData.append("head_des_news", this.state.head_des_news);
      formData.append("descript_news", this.state.editorState);
      try {
        Swal.fire({
          title: 'คุณต้องการแก้ไขข้อมูลใช่หรือไม่?',
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          cancelButtonText: 'cancel'
        }).then(async (result) => {
          if (result.value) {
            this.setState({ isExampleLoading: true })
            try {
              let check = await POST("/update_news", formData, true);
              Swal.fire(
                'แก้ไขข้อมูลสำเร็จ',
                '',
                'success'
              ).then(() => {
                this.props.history.push("/news_admin");
              })
            } catch (error) {
              Swal.fire(
                'กรุณากรอกข้อมูลใหครบ',
                '',
                'warning'
              )
            }






          }
          // this.props.fetch();
          this.setState({
            showingInfoWindow: false,
          });
        })


      } catch (error) {
      }
    } else {
      swal({
        title: "กรุณากรอกข้อมูลให้ครบ",
        icon: "warning"
      });
    }
  };
  // try {
  //   let check = await POST("/update_news", formData, true);
  //   if (check.message === "success") {
  //     swal({
  //       title: "บันทึกสำเร็จ",
  //       icon: "success"
  //     }).then(() => {
  //       this.props.history.push("/news_admin");
  //     });
  // this.setState({
  //     newsInfo: {
  //     name_banner: "",
  //     des_news:""
  //   },
  //   pic: ""
  // });
  //     } else {
  //       swal({
  //         title: "กรุณากรอกข้อมูลให้ครบ",
  //         icon: "warning"
  //       });
  //     }
  //   } catch (error) {
  //   }
  // };

  handleImage = selectorFiles => {

    if (selectorFiles.length > 0) {
      var file = selectorFiles[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.setState({
          picNews: reader.result
        });
        this.setState({
          filePicNews: selectorFiles[0]
        });
      };
      reader.onerror = function (error) {

      };
    } else {
      this.setState({
        picNews: ""
      });
    }
  };

  clearImage = () => {
    this.setState({
      picNews: ""
    });
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState
    });
  };

  onClickUpload = async () => {
    // const formData = new FormData();
    // formData.append('myImage', this.state.filePic);
    // try {
    //     await POST('upload', formData, true)
    // } catch (error) {
    // }
  };
  handleImageUploadBefore(files, info) {
    console.log(files, info)
  }
  handleImageUpload(targetImgElement, index, state, imageInfo, remainingFilesCount) {
    console.log(targetImgElement, index, state, imageInfo, remainingFilesCount)
  }
  handlePaste(e, cleanData, maxCharCount) {
    console.log(e, cleanData, maxCharCount)
  }
  handleImageUploadError(errorMessage, result) {
    console.log(errorMessage, result)
  }
  handleImageUploadError(errorMessage, result) {
    console.log(errorMessage, result)
  }
  render() {
    const { picNews, editorState } = this.state;
    return (
      <div>


        <div style={{ paddingTop: "100px", paddingBottom: "1rem" }}>
          <div
            style={{
              marginTop: "2rem",
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <div
              className="rcornerscontact"
              style={{ color: "rgb(249, 0, 1)" }}
            >
              แก้ไขข่าวและกิจกรรม
              </div>
            <Card.Link className=" rcornersnewsAdmin3  " href="/news_admin">
              กลับ
              </Card.Link>
          </div>
          <Row style={{ padding: "1rem" }}>
            {/* <div style={{ margin: "auto" }} hidden={this.state.picNews === ""}>
            <img
              width={1000}
              height={400}
              // src={this.state.picNews}
              src={_ip + "/" + picNews}
              style={{ width: "auto" }}
            />
            
          </div> */}
            <FormControl
              style={{ display: "none" }}
              id="some"
              type="file"
              onChange={e => this.handleImage(e.target.files)}
              accept=".jpg,.png"
            />
            <div
              style={{
                margin: "auto",
                width: "50rem",
                height: "400px",
                backgroundColor: "#eee",
                position: "relative"
              }}
              hidden={this.state.picNews !== ""}
            >
              <label for="some">
                <div
                  className="border-banner text-center"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    style={{
                      width: "200px",
                      height: "200px",
                      margin: "auto"
                    }}
                    src={pic}
                  />
                    เลือกรูปภาพ
                  </div>
              </label>
            </div>

            <div
              style={{ margin: "auto" }}
              hidden={this.state.picNews === ""}
            >
              <img

                name="picNews"
                src={
                  picNews.indexOf("static") !== -1
                    ? _ip + "/" + picNews
                    : picNews
                }
                style={{ width: "auto", height: '400px' }}
              />
            </div>
          </Row>
          <div
            style={{
              paddingLeft: "15rem",
              paddingRight: "15rem",
              justifyContent: "center",
              display: "flex"
            }}
          >
            <a className='trash'>
              <img className="imgdelete"
                src={Icons.IconDelete}
                style={{
                  cursor: "pointer", textAlign: "center", height: "2rem",
                  width: "2rem"
                }}
                onClick={e => this.clearImage()}
              />
            </a>
            {/* <FaTrash
                    style={{ cursor: "pointer", height: "2rem", width: "2rem" }}
                    onClick={e => this.clearImage()}
                  /> */}
          </div>

          <Form className="admin_news">
            <div>
              <Form.Group as={Row} controlId="exampleForm.ControlTextarea1">
                <Form.Label column sm="2.5" className="fontNew">
                  ชื่อหัวข้อ :
                  </Form.Label>
                <Col>
                  <Form.Control
                    onChange={this.handleChange}
                    value={this.state.name_news}
                    type="text"
                    placeholder="ชื่อหัวข้อ"
                    name="name_news"
                  />
                </Col>
              </Form.Group>
            </div>
            <div>
              <Form.Group as={Row} controlId="exampleForm.ControlTextarea1">
                <Form.Label column sm="2.5" className="fontNew">
                  คำอธิบาย :
                  </Form.Label>
                <Col>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    placeholder="เขียนคำอธิบาย"
                    name="des_news"
                    onChange={this.handleChange}
                    value={this.state.des_news}
                  />
                </Col>
              </Form.Group>
            </div>
            <div>
              <Form.Group as={Row} controlId="exampleForm.ControlTextarea1">
                <Form.Label column sm="2.5" className="fontNew">
                  ชื่อหัวข้อรายละเอียด :
                  </Form.Label>
                <Col>
                  <Form.Control
                    onChange={this.handleChange}
                    value={this.state.head_des_news}
                    name="head_des_news"
                    type="text"
                    placeholder="ชื่อหัวข้อ"
                  />
                </Col>
              </Form.Group>
            </div>

            <div>
              <Form.Group as={Row} controlId="exampleForm.ControlTextarea1">
                <Form.Label column sm="2.5" className="fontNew">
                  รายละเอียด :
                  </Form.Label>
                <Col>


                  <div style={{ border: "1px solid #ddd" }}>
                    <SunEditor setOptions={{
                      height: 200,
                      buttonList: buttonList.complex // Or Array of button list, eg. [['font', 'align'], ['image']]
                      // Other option
                    }}
                      onImageUploadBefore={this.handleImageUploadBefore()}
                      onImageUpload={this.handleImageUpload()}
                      onPaste={this.handlePaste()}
                      onImageUploadError={this.handleImageUploadError()}
                      setContents={this.state.content}
                      onChange={this.onEditorStateChange} />

                  </div>
                </Col>
              </Form.Group>
            </div>
          </Form>
          <br />

          <div
            style={{
              paddingLeft: "15rem",
              paddingRight: "15rem",
              justifyContent: "center",
              display: "flex"
            }}
          >
            <button
              className="rcornersnew1"
              onClick={() => this.handleSubmit()}
            >
              {this.state.isExampleLoading && <Spinner animation="border" variant="primary" />}
                บันทึก
              </button>
          </div>
        </div>
      </div>
    );
  }
}
