import React, { Component } from "react";

import {
  TabContent,
  TabPane,
  Row,
  Table
} from "reactstrap";
import { Tabs, Tab } from 'react-bootstrap'
import Add_product from "./Add_product&bro";
import { GET, POST, _ip } from "../../service";
import icon_danger from '../../Asset/img/icon-danger.png'
import Swal from 'sweetalert2'
import Icons from '../../Component/Icons/Icons'

export default class Product_admin extends Component {
  constructor(props) {

    super(props);
    this.toggle = this.toggle.bind(this);
    this.fetch = this.fetch.bind(this);
    this.state = {
      index: 0,
      carInfo: [],
      activeTab: "1"
    };
  }

  fetch = async () => {
    try {
      let carInfo = await GET("/car_detail");

      this.setState({
        carInfo: carInfo
      });

    } catch (error) {
    }
  }

  componentDidMount = async () => {
    this.fetch();
  };

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  deleteProduct = async (id_car) => {
    Swal.fire({
      title: 'คุณต้องการลบใช่หรือไม่?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then((result) => {
      if (result.value) {
        let del = POST("delete_car", { id_car })
        Swal.fire(
          'ลบข้อมูลสำเร็จ',
          '',
          'success'
          // 'Your file has been deleted.',
          // 'success'
        )
      }
      this.fetch();
    })
  };

  render() {
    const { carInfo } = this.state;

    return (
      <div>

        <Tabs defaultActiveKey="รายการทั้งหมด" id="uncontrolled-tab-example" className='tab'>
          <Tab eventKey="รายการทั้งหมด" title="รายการทั้งหมด">
            <div className="rcornerscontact" style={{ color: "rgb(249, 0, 1)", marginTop: '10px' }}>รายการทั้งหมด</div>
            <Table striped bordered hover>
              <thead>
                <tr className="Fontadmin">
                  <th style={{ width: "2rem" }}>ลำดับ</th>
                  <th>ชื่อ</th>
                  <th>ราคาเริ่มต้น</th>
                  <th style={{ width: "10rem" }}> ภาพ </th>
                  <th style={{ width: "6rem" }}>Fnction</th>
                </tr>
              </thead>
              {carInfo.length > 0
                ? carInfo.map((index, i) => (
                  <tbody key={index.id_car}>
                    <tr style={{ textAlign: "center" }}>
                      <td>{i + 1}</td>
                      <td>{index.name_car}</td>
                      <td style={{ width: " 20rem" }}>{index.start_price}</td>
                      <td style={{ display: "", margin: "auto" }}>
                        <div>
                          <img src={_ip + "/" + index.source_car} width="100px" height="1rem" />
                        </div>
                      </td>
                      <td className="function-product">
                        <div>
                          <a href={`/edit_product/${index.id_car}`}>
                            <i class="far fa-edit">
                            </i>
                          </a>
                        </div>
                        <div>
                          <a className='trash'>
                            <img className="imgdelete"
                              src={Icons.IconDelete}
                              style={{
                                cursor: "pointer", textAlign: "center", height: "2rem",
                                width: "2rem"
                              }}
                              onClick={e => this.deleteProduct(index.id_car)}
                            />

                            {/* <FaTrash onClick={() => this.deleteProduct(index.id_car)} /> */}
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ))
                : null}
            </Table>
          </Tab>
          <Tab eventKey="เพิ่มรุ่นรถยนต์" title="เพิ่มรุ่นรถยนต์">
            <div className="rcornerscontact" style={{ color: "rgb(249, 0, 1)", marginTop: '10px' }}>เพิ่มรุ่นรถยนต์</div>
            <Add_product toggle={this.toggle} fetch={this.fetch} />
          </Tab>
        </Tabs>

      </div >
    );
  }
}

