import React, { Component } from "react";
import { Row, Col, Card, Form, FormControl, Spinner } from "react-bootstrap";
import pic from "../../Asset/img/pic.png";
import { GET, POST, _ip } from "../../service";
import swal from "sweetalert";


import icon_danger from "../../Asset/img/icon-danger.png";
import Swal from "sweetalert2";
import './ProductBrochureAdmin.scss'


export default class Edit_product_bro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banner: "",
      buffer: {
        name_sub_car: "",
        price: "",
        source: ""
      },
      buffer1: {
        header_des: "",
        des: "",
        source_des: ""
      },
      index: 0,
      isExampleLoading: false,
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      subModel: [],
      description: [],
      showCar: "",
      head_des: [],
      des: [],
      name_car: [],
      price: [],
      brochure: "",
      carInfo: {
        name_car: "",
        source_car: "",
        des_car: "",
        start_price: "",
        brochure: "",
        header_des_car: ""
      },
      descripHeader: [],
      descripDes: [],
      file_source: [],
      filedescription: [],
      filesubModel: [],
      fileshowCar: {},
      fileBanner: {},
      name_sub_car: [],
      price: [],
      indexSub: 0,
      indexDes: 0,
      splice: [],
      splicedes: []
    };
  }

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

  onClose = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };

  componentDidMount = async () => {
    // console.log(this.props.match.params, 'asdasdsadsad')
    let { id } = this.props.match.params;

    let id_car = id
    this.setState({
      id: id_car
    });

    try {

      let carInfoch = await POST("car_sub_brand", { id_car });

      this.setState({
        name_car: carInfoch.car.name_car,
        showCar: carInfoch.car.source_car,
        header_des_car: carInfoch.car.header_des_car,
        des_car: carInfoch.car.des_car,
        start_price: carInfoch.car.start_price,
        filebrochure: carInfoch.car.brochure
      });
      this.setState({
        subModel: carInfoch.subcar
      });
      this.setState({
        description: carInfoch.description
      });

      this.setState({
        banner: carInfoch.brand.source_ban
      });
    } catch (error) { }
  };

  handleChange = ({ target: { value, name } }) =>
    this.setState(
      { [name]: value },
    );

  handleSubmit = async () => {


    let changeIndex = []
    if (typeof this.state.fileshowCar.name == 'string') {
      changeIndex.push("fileshowCar")
    } if (typeof this.state.filebrochure.name == 'string') {
      changeIndex.push("filebrochure")
    } if (typeof this.state.fileBanner.name == 'string') {
      changeIndex.push("fileBanner")
    }
    const { name_car, des_car, start_price, header_des_car, name_sub_car, price, descripHeader, descripDes } = this.state;
    const formData = new FormData();
    if (name_car && des_car && start_price && header_des_car && name_sub_car && price && descripHeader && descripDes) {
      formData.append("id_car", this.props.match.params.id);
      await formData.append("myImage", this.state.fileshowCar)
      await formData.append("myImage", this.state.filebrochure)
      await formData.append("myImage", this.state.fileBanner)

      await formData.append("changeIndex", JSON.stringify(changeIndex))

      formData.append("name_car", this.state.name_car);
      formData.append("des_car", this.state.des_car);
      formData.append("start_price", this.state.start_price);
      formData.append("header_des_car", this.state.header_des_car);

      try {
        Swal.fire({
          title: 'คุณต้องการแก้ไขข้อมูลใช่หรือไม่?',
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          cancelButtonText: 'cancel'
        }).then(async (result) => {
          if (result.value) {
            this.setState({ isExampleLoading: true })
            /// update car
            let checkUpdate = await POST("update_car", formData, true);

            formData.delete("myImage");

            this.state.splicedes.forEach(async el => {
              await formData.append("splicedes", el);
            });
            this.state.filedescription.forEach(async el => {
              await formData.append("desImage", el);
            });
            await formData.append("head_des", JSON.stringify(this.state.descripHeader));
            await formData.append("des", JSON.stringify(this.state.descripDes));

            let check = await POST("update_des_car", formData, true)
            //// update des

            formData.delete("desImage");
            this.state.filesubModel.forEach(async el => {
              await formData.append("subcar", el);
            });
            await formData.append("name_sub_car", JSON.stringify(this.state.name_sub_car));

            await formData.append("price", JSON.stringify(this.state.price));

            this.state.splice.forEach(async el => {
              await formData.append("splice", el)
            })

            let subRequest = await POST("update_sub_car", formData, true);
            /// update sup

            Swal.fire(
              'แก้ไขข้อมูลสำเร็จ',
              '',
              'success'
            ).then(() => {
              this.props.history.push("/product_admin");
            })



            // this.props.fetch();
            this.setState({
              showingInfoWindow: false,
            });

          }
        })

      } catch (error) {
        swal({
          title: "กรุณากรอกข้อมูลให้ครบ",
          icon: "warning"
        });
      }
    } else {
      swal({
        title: "กรุณากรอกข้อมูลให้ครบ",
        icon: "warning"
      });
    }
  };

  handleInput = e => {
    // e.preventDefault();
    let { name_sub_car, price, indexSub } = this.state;
    let name = e.target.name;
    let value = e.target.value;
    let buffer = this.state.buffer;
    buffer[name] = value;
    this.setState({ buffer: buffer });
    name_sub_car[indexSub] = buffer.subModelName;
    price[indexSub] = parseInt(buffer.subModelPrice);
    this.setState({
      name_sub_car,
      price
    });
  };
  handleInputdes = e => {
    // e.preventDefault();
    let { descripHeader, descripDes, indexDes, buffer1 } = this.state;
    let name = e.target.name;
    let value = e.target.value;
    buffer1[name] = value;
    descripHeader[indexDes] = buffer1.descriptionName;
    descripDes[indexDes] = buffer1.descriptionDes;
    this.setState({
      descripHeader,
      descripDes,
      buffer1
    });
  };

  handleshowCar = selectorFiles => {
    if (selectorFiles.length > 0) {
      var file = selectorFiles[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.setState({
          showCar: reader.result,
          fileshowCar: selectorFiles[0]
        });
      };
      reader.onerror = function (error) {
      };
    } else {
      this.setState({
        showCar: ""
      });
    }
  };
  clearshowCar = () => {
    this.setState({
      showCar: ""
    });
  };

  handleBanner = selectorFiles => {
    if (selectorFiles.length > 0) {
      var file = selectorFiles[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.setState({
          banner: reader.result
        });
        this.setState({
          fileBanner: selectorFiles[0]
        });
      };
      reader.onerror = function (error) {
      };
    } else {
      this.setState({
        banner: ""
      });
    }
  };

  clearBanner = () => {
    this.setState({
      banner: ""
    });
  };

  handlesubModel = event => {
    let selectorFiles = event.target.files;
    if (selectorFiles.length > 0) {
      var file = selectorFiles[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let { buffer, filesubModel } = this.state;
        buffer.source = reader.result;
        // file_source.push(file)
        filesubModel.push(file);
        this.setState({
          buffer: buffer,
          filesubModel: filesubModel
          // file_source:file_source
        });

      };
      reader.onerror = function (error) {
      };
    }
  };

  clearBufferImg = () => {
    let { buffer } = this.state;
    buffer.source = "";
    this.setState({
      buffer: buffer
    });
  };

  removeSubModel = (e, index) => {
    e.preventDefault();
    let { subModel, filesubModel, splice } = this.state;
    splice.push(subModel[index].id)
    // filesubModel.slice(index, 1)
    subModel.splice(index, 1);
    this.setState({
      subModel: subModel,
      splice
    });
  };
  handleDescription = event => {
    let selectorFiles = event.target.files;
    if (selectorFiles.length > 0) {
      var file = selectorFiles[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let { buffer1, filedescription } = this.state;
        buffer1.source_des = reader.result;
        filedescription.push(file);
        this.setState({
          buffer1: buffer1,
          filedescription: filedescription
        });
      };
      reader.onerror = function (error) {
      };
    }
  };
  clearDesBufferImg = () => {
    let { buffer1 } = this.state;
    buffer1.source_des = "";
    this.setState({
      buffer1: buffer1
    });
  };

  removeDescription = (e, index) => {
    e.preventDefault();
    let { description, descripHeader, descripDes, splicedes } = this.state;
    splicedes.push(description[index].id)
    description.splice(index, 1);
    descripHeader.splice(index, 1);
    descripDes.splice(index, 1);
    this.setState({
      description: description,
      descripHeader,
      descripDes,
      splicedes
    });
  };

  handlebrochure = selectorFiles => {
    if (selectorFiles.length > 0) {
      var file = selectorFiles[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.setState({
          brochure: reader.result
        });
        this.setState({
          filebrochure: selectorFiles[0]
        });
      };
      reader.onerror = function (error) {
      };
    } else {
      this.setState({
        brochure: ""
      });
    }
  };
  onSubmitsubModel = e => {
    // e.preventDefault();
    let {
      name_sub_car,
      price,
      indexSub,
      file_source,
      subModel,
      buffer
    } = this.state;
    subModel.push(buffer);

    // source.push(this.state.buffer.subModelImg);
    name_sub_car.push(this.state.buffer.name_sub_car);
    price.push(parseInt(this.state.buffer.price));

    // source.splice(indexSub, 1);
    name_sub_car.splice(indexSub, 1);
    price.splice(indexSub, 1);

    indexSub = indexSub + 1;
    this.setState({
      subModel,
      buffer: { source: "", name_sub_car: "", price: "" },
      name_sub_car,
      price,
      indexSub
    });
  };
  ondesSubmit = e => {
    e.preventDefault();
    let {
      descripHeader,
      descripDes,
      indexDes,
      buffer1,
      description
    } = this.state;
    description.push(buffer1);
    // description.push(this.state.buffer1);

    descripHeader.push(this.state.buffer1.head_des);
    descripHeader.splice(indexDes, 1);

    descripDes.push(this.state.buffer1.des);
    descripDes.splice(indexDes, 1);

    indexDes = indexDes + 1;
    this.setState({
      description,
      buffer1: { source_des: "", head_des: "", des: "" },
      descripHeader,
      descripDes,
      indexDes
    });
  };
  onClickUpload = async () => { };

  render() {
    let {
      buffer,
      subModel,
      description,
      buffer1,
      showCar,
      banner,
      brochure
    } = this.state;

    return (
      <div>

        <div style={{ paddingTop: "100px", paddingBottom: "1rem" }}>
          <div
            style={{
              color: "black",
              marginTop: "2rem",
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <div className="rcornersbanner" style={{ color: "rgb(249, 0, 1)" }}>
              แก้ไขรุ่นรถยนต์
            </div>
            <Card.Link className='rcornersnewsAdminProduct' href="/product_admin">
              กลับ
          </Card.Link>
          </div>
          {/* ใส่ชื่อรุ่นรถ */}
          <Form className="name_car">
            <div>
              <Form.Group as={Row} controlId="exampleForm.ControlTextarea1">
                <Form.Label
                  column
                  sm="2.5"
                  style={{ fontSize: "16px", fontWeight: "bold" }}
                >
                  ชื่อรุ่นรถ :
                  </Form.Label>
                <Col>
                  <Form.Control
                    onChange={this.handleChange}
                    name="name_car"
                    value={this.state.name_car}
                    type="text"
                    placeholder="ชื่อหัวข้อ"
                  />
                </Col>
              </Form.Group>
              {/* ใส่ราคาเริ่มต้น */}
              <Form.Group as={Row} controlId="exampleForm.ControlTextarea1">
                <Form.Label
                  column
                  sm="2.5"
                  style={{ fontSize: "16px", fontWeight: "bold" }}
                >
                  ราคาเริ่มต้น :
                  </Form.Label>
                <Col>
                  <Form.Control
                    onChange={this.handleChange}
                    name="start_price"
                    value={this.state.start_price}
                    type="text"
                    placeholder="ราคาเริ่มต้น"
                  />
                </Col>
              </Form.Group>
              {/* เลือกภาพโชว์หน้าแรก */}
              <Row style={{ padding: "1rem", justifyContent: "center" }}>
                <FormControl
                  style={{ display: "none" }}
                  id="someone"
                  type="file"
                  onChange={e => this.handleshowCar(e.target.files)}
                />
                <div
                  style={{
                    margin: "auto",
                    width: "50rem",
                    height: "17rem",
                    backgroundColor: "#eee",
                    position: "relative"
                  }}
                  hidden={this.state.showCar !== ""}
                >
                  <label for="someone">
                    <div
                      className="border-banner text-center"
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        style={{
                          width: "200px",
                          height: "200px",
                          margin: "auto"
                        }}
                        src={pic}
                      />
                        เลือกรูปรถโชว์หน้าแรก
                      </div>
                  </label>
                </div>

                <div
                  style={{ margin: "auto" }}
                  hidden={this.state.showCar === ""}
                >
                  <img
                    name="showCar"
                    src={
                      showCar.indexOf("static") !== -1
                        ? _ip + "/" + showCar
                        : showCar
                    }
                    // src={this.state.showCar}
                    style={{ width: "auto", height: "17rem" }}
                  />
                </div>
              </Row>
              <div style={{ justifyContent: "center", display: "flex" }}>
                <button
                  className="rcornersaddproduct"
                  onClick={e => this.clearshowCar()}
                >
                  ลบ
                </button>

              </div>
            </div>
          </Form>
          {/* เลือกBanner */}
          <Row style={{ padding: "1rem" }}>
            <FormControl
              style={{ display: "none" }}
              id="upbanner"
              type="file"
              accept="image/x-png,image/jpeg"
              onChange={e => this.handleBanner(e.target.files)}
            />
            <div className="select_banner" hidden={this.state.banner !== ""}>
              <label for="upbanner">
                <div
                  className="border-banner text-center"
                  style={{ cursor: "pointer" }}
                >
                  <img src={pic} />
                    เลือกBanner
                  </div>
              </label>
            </div>
            {/* แสดง banner เมื่ออัพ */}
            <div style={{ margin: "auto" }} hidden={this.state.banner === ""}>
              <img
                width={1000}
                height={400}
                name="banner"
                src={
                  banner.indexOf("static") !== -1
                    ? _ip + "/" + banner
                    : banner
                }
                // src={this.state.banner}
                style={{ width: "auto", height: '400px' }}
              />
            </div>
          </Row>
          {/* ตัวลบ banner */}
          <div className="delete_banner">
            <button
              className="rcornersaddproduct"
              onClick={e => this.clearBanner()}
            >
              ลบ
                </button>

          </div>

          {/* เพิ่มรุ่นรถย่อย */}
          <Row style={{ padding: "1rem", margin: "0 8rem" }}>
            {subModel &&
              subModel.map((el, i) => (
                <Col className="sub_model">
                  <div>
                    <div>
                      <img
                        height={175}
                        // src={el.subModelImg}
                        src={
                          el.source.indexOf("static") !== -1
                            ? _ip + "/" + el.source
                            : el.source
                        }
                      // src={_ip + "/" + el.source}
                      />
                    </div>
                    <Form>
                      <Form.Group
                        as={Row}
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Label
                          column
                          sm="2.5 "
                          style={{ padding: "0 0 0 1rem" }}
                        >
                          ชื่อรุ่นรถย่อย:
                          </Form.Label>
                        <Col>
                          <Form.Control
                            type="text"
                            placeholder="ชื่อรุ่นรถย่อย"
                            value={el.name_sub_car}
                            disabled
                          />
                          {/* <Form.Control
                          type="text"
                          placeholder="ชื่อรุ่นรถย่อย"
                          value={el.subModelName}
                          disabled
                        /> */}
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Label
                          column
                          sm="2.5"
                          style={{ padding: "0 0 0 1rem" }}
                        >
                          ราคา :
                          </Form.Label>
                        <Col>
                          <Form.Control
                            type="text"
                            placeholder="ราคา"
                            value={el.price}
                            disabled
                          />
                          {/* <Form.Control
                          type="text"
                          placeholder="ราคา"
                          value={el.subModelPrice}
                          disabled
                        /> */}
                        </Col>
                      </Form.Group>
                      <Form.Group />
                    </Form>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        position: "absolute",
                        bottom: "1rem"
                      }}
                    >
                      <button
                        className="rcornersaddproduct"
                        onClick={e => this.removeSubModel(e, i)}
                      >
                        ลบ
                        </button>
                    </div>
                  </div>
                </Col>
              ))}
            <Col className="sub_model1">
              <FormControl
                id="subcar"
                type="file"
                accept="image/x-png,image/jpeg"
                onChange={this.handlesubModel}
              />
              <div>
                <div hidden={buffer.source !== ""}>
                  <label for="subcar">
                    <div className="border-car text-center">
                      <img src={pic} />
                        เลือกรูปรถย่อย
                      </div>
                  </label>
                </div>
                <div hidden={buffer.source === ""}>
                  <img

                    alt="source"
                    src={buffer.source}
                    style={{
                      position: "absolute",
                      top: "2rem",
                      objectFit: "contain",
                      ObjectPosition: "center",
                      width: "100%",
                      height: '175px',
                      padding: "0 1rem"
                    }}
                  />
                </div>
                <div>
                  {/* ตัวลบ */}
                  <button
                    className="rcornersaddproduct"
                    onClick={e => this.clearBufferImg()}
                  >
                    ลบ
                </button>

                  {/* <FaTrash
                          style={{
                            cursor: "pointer",
                            height: "1.6rem",
                            width: "1.6rem"
                          }}
                          onClick={e => this.clearBufferImg()}
                        /> */}
                </div>
                <Form
                  style={{
                    paddingTop: "1rem",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                    position: "absolute",
                    bottom: "3rem"
                  }}
                >
                  <Form.Group
                    as={Row}
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label
                      column
                      sm="2.5"
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        padding: "0 0 0 1rem"
                      }}
                    >
                      ชื่อรุ่นรถย่อย:
                      </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder="ชื่อรุ่นรถย่อย"
                        name="name_sub_car"
                        value={buffer.name_sub_car}
                        onChange={this.handleInput}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label
                      column
                      sm="2.5"
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        padding: "0 0 0 1rem"
                      }}
                    >
                      ราคา :
                      </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder="ราคา"
                        name="price"
                        value={buffer.price}
                        onChange={this.handleInput}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group />
                </Form>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    position: "absolute",
                    bottom: "1rem"
                  }}
                >
                  <button
                    className="rcornersaddproduct"
                    onClick={this.onSubmitsubModel}
                  >
                    เพิ่ม
                    </button>
                </div>
              </div>
            </Col>
          </Row>
          {/* กรอกข้อมูล */}
          <Form style={{ padding: "1rem" }}>
            <Row style={{ justifyContent: "center" }}>
              {/*  */}
              <Col md={7}>
                <Form.Group as={Row} controlId="exampleForm.ControlTextarea1">
                  <Form.Label
                    column
                    sm="2.5"
                    style={{ fontSize: "16px", fontWeight: "bold" }}
                  >
                    ชื่อหัวข้อ :
                    </Form.Label>
                  <Col>
                    <Form.Control
                      onChange={this.handleChange}
                      name="header_des_car"
                      value={this.state.header_des_car}
                      type="text"
                      placeholder="ชื่อหัวข้อ"
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            {/*  */}
            <Row style={{ justifyContent: "center" }}>
              <Col md={7}>
                <Form.Group as={Row} controlId="exampleForm.ControlTextarea1">
                  <Form.Label
                    column
                    sm="2.5"
                    style={{ fontSize: "16px", fontWeight: "bold" }}
                  >
                    รายละเอียด :
                    </Form.Label>
                  <Col>
                    <Form.Control
                      as="textarea"
                      rows="3"
                      placeholder="รายละเอียด"
                      onChange={this.handleChange}
                      name="des_car"
                      value={this.state.des_car}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            {/* เพิ่มโปรชัวร์ */}
            <Row style={{ justifyContent: "center" }}>
              <Col md={4}>
                <div
                  style={{
                    width: "300px",
                    height: "120px",
                    backgroundColor: "#eee",
                    position: "relative"
                  }}
                >
                  <div className="border-car1 text-center">
                    เลือกโปรชัวร์
                      <FormControl
                      type="file"
                      name="fileData"
                      accept="application/pdf"
                      onChange={e => this.handlebrochure(e.target.files)}
                    // value={this.state.carInfo.brochure} 
                    // src={
                    //   brochure.indexOf("static") !== -1
                    //     ? _ip + "/" + brochure
                    //     : brochure
                    // }
                    // value={this.state.carInfo.brochure}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
          {/* เพิ่มภาพและรายละเอียด */}
          <Row
            style={{
              padding: "1rem",
              display: "flex",
              justifyContent: "center"
            }}
          >
            {description &&
              description.map((el, i) => (
                <Row style={{ margin: "1rem 0 1rem 0" }}>
                  <div
                    style={{
                      margin: "0 3rem",
                      height: "250px",
                      backgroundColor: "#eee",
                      position: "relative",
                      width: "1200px"
                    }}
                  >
                    <Row>
                      <Col md={7}>
                        <Form
                          style={{
                            paddingTop: "2rem",
                            paddingLeft: "1rem",
                            paddingRight: "1rem"
                          }}
                        >
                          <Form.Group
                            as={Row}
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label
                              column
                              sm="2.5"
                              style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                padding: "0 0 0 1rem"
                              }}
                            >
                              หัวข้อ :
                              </Form.Label>
                            <Col>
                              <Form.Control
                                type="text"
                                placeholder="หัวข้อ"
                                value={el.head_des}
                                disabled
                              />
                              {/* <Form.Control
                              type="text"
                              placeholder="หัวข้อ"
                              value={el.descriptionName}
                              disabled
                            /> */}
                            </Col>
                          </Form.Group>
                          <Form.Group
                            as={Row}
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label
                              column
                              sm="2.5"
                              style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                padding: "0 0 0 1rem"
                              }}
                            >
                              รายละเอียด :
                              </Form.Label>
                            <Col>
                              <Form.Control
                                type="textarea"
                                rows="3"
                                type="text"
                                placeholder="รายละเอียด"
                                value={el.des}
                                disabled
                              />

                              {/* <Form.Control
                              type="textarea"
                              rows="3"
                              placeholder="รายละเอียด"
                              value={el.descriptionDes}
                              disabled
                            /> */}
                            </Col>
                          </Form.Group>
                          <Form.Group />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center"
                            }}
                          >
                            <button
                              className="rcornersaddproduct"
                              onClick={e => this.removeDescription(e, i)}
                            >
                              ลบ
                              </button>
                          </div>
                        </Form>
                      </Col>
                      <Col md={5}>
                        <div className="afterAddDesCar">
                          <img
                            height={175}
                            src={
                              el.source_des.indexOf("static") !== -1
                                ? _ip + "/" + el.source_des
                                : el.source_des
                            }
                          // src={el.descriptionImg}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Row>
              ))}
            <FormControl
              style={{ display: "none" }}
              id="descrip"
              type="file"
              accept="image/x-png,image/jpeg"
              onChange={this.handleDescription}
            />
            <div className="bgDesCar">
              <Row>
                <Col md={7}>
                  <Form className="formDesCar">
                    <Form.Group
                      as={Row}
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label column sm="2.5">
                        หัวข้อ:
                        </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          placeholder="หัวข้อ"
                          name="head_des"
                          value={buffer1.head_des}
                          onChange={this.handleInputdes}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group
                      as={Row}
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label column sm="2.5">
                        รายละเอียด :
                        </Form.Label>
                      <Col>
                        <Form.Control
                          as="textarea"
                          rows="3"
                          type="text"
                          placeholder="รายละเอียด"
                          name="des"
                          value={buffer1.des}
                          onChange={this.handleInputdes}
                        />
                      </Col>
                    </Form.Group>
                    <div
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <button
                        className="rcornersaddproduct"
                        onClick={this.ondesSubmit}
                      >
                        เพิ่ม
                        </button>
                    </div>
                    <Form.Group />
                  </Form>
                </Col>
                <Col md={5}>
                  <div
                    className="selectDesCar"
                    hidden={buffer1.source_des !== ""}
                  >
                    <label for="descrip">
                      <div className="border-car text-center">
                        <img src={pic} />
                          รูปรายละเอียดรถ
                        </div>
                    </label>
                  </div>
                  <div
                    className="beforeAddDesCar"
                    hidden={buffer1.source_des === ""}
                  >
                    <img height={175} src={buffer1.source_des} />
                  </div>
                  <div className="styleTarsh">
                    {/* ตัวลบ */}
                    <button
                      className="rcornersaddproduct"
                      onClick={e => this.clearDesBufferImg()}
                    >
                      ลบ
                </button>
                    {/* <FaTrash onClick={e => this.clearDesBufferImg()} /> */}
                  </div>
                </Col>
              </Row>
            </div>
          </Row>

          <div className="styleButton">
            <button
              className="rcornersproduct1"
              onClick={() => this.handleSubmit()}
            >
              {this.state.isExampleLoading && <Spinner animation="border" variant="primary" />}
                บันทึก
              </button>
          </div>
        </div>
      </div>
    );
  }
}
