import React, { Component } from 'react'
import { _ip, POST } from '../../service'
import '../../Asset/scss/Products.scss'

export default class ProductCarDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            car: {
                car: {
                    start_price: 0
                },
                subcar: [],
                brand: {},
                description: []
            }
        }
    }
    componentDidMount() {
        this.getCar()
    }
    async getCar() {
        let id_car = this.props.match.params.carId
        try {
            let res = await POST('car_sub_brand', { id_car })
            this.setState({ car: res })

        } catch (error) {
            console.log('getCar err', error)
        }
    }
    render() {
        let { car } = this.state
        return (
            <React.Fragment>
                <div className="product_dt-banner">
                    <img src={_ip + '/' + car.brand.source_ban} alt="" />
                    <a target="_blank" href={_ip + "/" + car.car.brochure} download >
                        <button className='btn'>
                            โปรชัวร์
                            </button>
                    </a>
                    {/* <button className="btn" >โปรชัวร์</button> */}
                </div>
                {car.subcar.length > 0 ? <div className="description-car">
                    <div className="col-sm-12 col-md-12 col-lg-12 text-center h3">
                        รถยนต์รุ่นย่อย
                      </div>
                    {
                        car.subcar.map((index, i) =>
                            <div className="col-sm-6 col-md-4 col-lg-3">
                                <div >
                                    <a href="#1">
                                        <img src={_ip + "/" + index.source} className="image-description" />
                                    </a>
                                </div>
                                <div>
                                    <h5>{index.name_sub_car}</h5>
                                        ราคารถ {parseInt(index.price).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}
                                </div>

                            </div>
                        )

                    }
                </div> : null}

                <div className="product_dt-car">
                    {/* <div className="product_dt-car-title">{car.car.name_car}</div> */}
                    <div className="product_dt-car-title">{car.car.name_car}</div>
                    <div className="product_dt-car-content">ราคาเริ่มต้นที่</div>
                    <div className="product_dt-car-price">{car.car.start_price.toLocaleString()} บาท</div>
                </div>
                {/* <div>ดีไซน์ภายนอก/ภายใน</div> */}
                {car.description.map((e, i) =>
                    <div className={`feature ${e.des ? 'half' : 'full'}`} key={i}>
                        <div className="feature-overlay"></div>
                        <div className="feature-img">
                            <img src={_ip + '/' + e.source_des} alt="" />
                        </div>
                        <div className="feature-content">
                            <strong>{e.head_des}</strong>
                            <p>{e.des}</p>
                        </div>
                    </div>
                )}
            </React.Fragment>
        )
    }
}
