import React, { Component } from 'react'

import Responsive from 'react-responsive';

import Swal from 'sweetalert2'
const Mobile = props => <Responsive {...props} maxWidth={767} />;
const Desktop = props => <Responsive {...props} minWidth={768} />;
export default class NavbarAdmin extends Component {
    logout = () => {
        Swal.fire({
            title: 'คุณต้องการออกจากระบบใช่หรือไม่?',

            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes!'
        }).then((result) => {
            if (result.value) {
                localStorage.clear();

                Swal.fire(
                    'ออกจากระบบสำเร็จ',
                    '',
                    'success'
                )
            }
            window.location.reload()
        })

    }
    render() {
        let path = window.location.pathname
        // console.log('path',path)
        return (
            <div className="admin-navbar">
                <Desktop>
                    <span>รายการ</span>
                    <a href style={{
                        cursor: "pointer",
                    }}
                        onClick={this.logout}>ออกจากระบบ</a>
                </Desktop>
                <Mobile>
                    <div style={{ paddingTop: "100px", width: '100vw', height: '100vh', backgroundColor: 'black', justifyContent: 'center' }}>

                    </div>
                </Mobile>
            </div>

        )
    }
}
