import React, { Component } from "react";
import { Table, Row, Col } from "react-bootstrap";
import moment from "moment";

import { GET, POST, _ip } from "../../service";
import Responsive from 'react-responsive';
import icon_danger from '../../Asset/img/icon-danger.png'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { Calendar, momentLocalizer } from 'react-big-calendar'
const localizer = momentLocalizer(moment)
export default class Contact_admin extends Component {
  constructor(props) {
    // if (localStorage.getItem("user") == null) {
    //   window.location.href = "/login"
    // }
    super(props);
    this.state = {
      index: 0,
      contact: [],
      events_date: [],
      events: [
        {
          start: moment().toDate(),
          end: moment()
            .add(1, "days")
            .toDate(),
          title: "test5555"
        }
      ],

    };
  }
  fetch = async () => {
    try {
      let contact = await GET("appointment_detail");
      this.setState({
        contact: contact

      })
      for (let i = 0; i < contact.length; i++) {
        let joined = this.state.events_date.concat({
          start: moment(contact[i].date).toDate(),
          end: moment(contact[i].date).toDate(),
          title: contact[i].name_lastname,
          resourceTitle: contact[i].detail,
          allday: true
        });
        this.setState({
          events_date: joined
        })
      }
      console.log(this.state.events_date, 'asdasdsadsa')
    } catch (error) {
    }
  }
  mapToRBCFormat = e => Object.assign({}, e, {
    start: moment(e.date),
    end: moment(e.date),
    title: e.name_lastname
  })
  componentDidMount = async () => {
    this.fetch()
  };
  render() {
    const { contact } = this.state;

    return (
      <div>
        <div className="" style={{ paddingTop: "10px" }}>
          <div className="rcornerscontact" style={{ color: "rgb(249, 0, 1)", marginBottom: '10px' }}>
            ติดต่อรับบริการ
              </div>
          <BootstrapTable data={this.state.contact} pagination >
            <TableHeaderColumn dataField='appointment_id' dataFormat={this.userIcon} width='50' dataAlign="center"> </TableHeaderColumn>
            <TableHeaderColumn dataField='name_lastname' dataFormat={this.addNumber} isKey>ชื่อ-นามสกุล</TableHeaderColumn>
            <TableHeaderColumn dataField='email'>Email</TableHeaderColumn>
            <TableHeaderColumn dataField='phone'>เบอร์โทรศัพท์</TableHeaderColumn>
            <TableHeaderColumn dataField='date' dataFormat={(cell) => moment(cell).format('DD-MM-YYYY HH:mm')} >วันที่รับบริการ</TableHeaderColumn>
            <TableHeaderColumn dataField='car_number'>ทะเบียนรถ</TableHeaderColumn>
            <TableHeaderColumn dataField='branch'>สถานที่</TableHeaderColumn>
            <TableHeaderColumn dataField='appoint'>นัดหมายเกี่ยวกับเรื่อง</TableHeaderColumn>

            {/* s<TableHeaderColumn dataField='user_id' dataFormat={this.userInfo}></TableHeaderColumn> */}
          </BootstrapTable>
          <Calendar
            events={this.state.events_date}
            step={60}
            defaultDate={new Date()}
            defaultView="month"
            localizer={localizer}
            style={{ height: "100vh" }}
          />
        </div>
      </div >
    );
  }
}

