import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    useHistory,
    useLocation
} from "react-router-dom";
import { GET, POST, _ip } from "../service";
import swal from 'sweetalert';
import admin_router from './Admin'
import user_router from './User'
import LoginAdmin from '../PageAdmin/Login'
import App from '../App'
import NoMatch from '../Page/NoMatch'
import AppAdmin from '../AppAdmin';
import Home from "../Page/Home";
export default function AuthRouter() {
    return (
        <Router>
            <Switch>
                {user_router.map((route, index) =>
                    <PublicRoute path={route.path} exact={route.exact} key={index} component={route.main} />
                )}
                {admin_router.map((route, index) =>
                    <PrivateRoute path={route.path} exact={route.exact} key={index} component={route.main} />
                )}
                <Route path="/login"> <LoginPage /></Route>
                <Route path="*" component={NoMatch} />
            </Switch>
        </Router>
    );
}

const fakeAuth = {
    isAuthenticated: localStorage.getItem('user_admin') ? localStorage.getItem('user_admin') : false,
    authenticate(cb) {
        fakeAuth.isAuthenticated = true;
        setTimeout(cb, 100); // fake async
    },
    signout(cb) {
        fakeAuth.isAuthenticated = false;
        setTimeout(cb, 100);
    }
};

// function AuthButton() {
//     let history = useHistory();

//     return fakeAuth.isAuthenticated ? (
//         <p>
//             Welcome!{" "}
//             <button
//                 onClick={() => {
//                     fakeAuth.signout(() => history.push("/"));
//                 }}
//             >
//                 Sign out
//       </button>
//         </p>
//     ) : (
//             <p>You are not logged in.</p>
//         );
// }

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ component: Component, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) =>
                fakeAuth.isAuthenticated ? (
                    <AppAdmin>
                        <Component {...props} />
                    </AppAdmin>
                ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: props.location }
                            }}
                        />
                    )
            }
        />
    );
}
function PublicRoute({ component: Component, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) =>
                <App>
                    <Component {...props} />
                </App>
            }
        />
    );
}


function LoginPage() {
    let history = useHistory();
    let location = useLocation();

    let { from } = location.state || { from: { pathname: "/test_driver" } };
    let login = async Loginnaja => {
        try {
            let check = await POST("admin/login", Loginnaja);
            localStorage.setItem("user_admin", true)
            fakeAuth.authenticate(() => {
                history.replace(from);
            });
        } catch (error) {
            swal({
                title: "รหัสผ่านไม่ถูกต้อง",
                icon: "error"
            });
        }

    };

    let home = () => {
        history.replace('/')
    }
    return (
        <div>
            <LoginAdmin
                presshome={home}
                presslogin={login.bind(this)}
            />

            {/* <p>You must log in to view the page at {from.pathname}</p> */}
            {/* <button onClick={login}>Log in</button> */}
        </div>
    );
}
