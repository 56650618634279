import React, { Component } from "react";
import {
  Row,
  Col,
  Form,
  FormControl, Spinner
} from "react-bootstrap";
import Icons from '../../Component/Icons/Icons'
import SunEditor, { buttonList } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import pic from "../../Asset/img/pic.png";
import { POST, _ip } from "../../service";
import Swal from "sweetalert2";
import { EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
export default class Add_new extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filePic: {},
      index: 0,
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      editorState: EditorState.createEmpty(),
      promoInfo: {
        name_pro: "",
        source_pro: "",
        des_pro: "",
        head_des_promo: "",
        des_promo: ""
      },
      pic: "",
      isExampleLoading: false
    };
  }
  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

  onClose = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };
  handleChange = (action, value) => {
    let { promoInfo } = this.state;
    promoInfo[action] = value;
    this.setState({
      promoInfo
    });
  };
  handleSubmit = async () => {
    const formData = new FormData();
    formData.append("myImage", this.state.filePic);
    formData.append("name_pro", this.state.promoInfo.name_pro);
    formData.append("des_pro", this.state.promoInfo.des_pro);
    formData.append("head_des_promo", this.state.promoInfo.head_des_promo);
    // formData.append("des_promo", draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())));
    formData.append("des_promo", this.state.editorState);


    try {
      Swal.fire({
        title: 'คุณต้องการเพิ่มข้อมูลใช่หรือไม่?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'cancel'
      }).then(async (result) => {
        if (result.value) {
          try {
            this.setState({ isExampleLoading: true })
            let check = await POST("create_promo", formData, true);

            Swal.fire(
              'เพิ่มข้อมูลสำเร็จ',
              '',
              'success'
            ).then(() => {
              this.props.toggle("1");
              this.props.fetch();
              this.setState({
                promoInfo: {
                  name_pro: "",
                  des_pro: "",
                  head_des_promo: "",
                  des_promo: ""

                },

                pic: "",
                // isExampleLoading: false,
              });
            })
          } catch (error) {
            Swal.fire(
              'กรุณากรอกข้อมูลใหครบ',
              '',
              'warning'
            )
          }
        }

        this.setState({

          isExampleLoading: false,
        });
      })


    } catch (error) {
    }
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState
    });

  };

  handleImage = selectorFiles => {
    if (selectorFiles.length > 0) {
      var file = selectorFiles[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.setState({
          pic: reader.result
        });
        this.setState({
          filePic: selectorFiles[0]
        });
      };
      reader.onerror = function (error) {
      };
    } else {
      this.setState({
        pic: ""
      });
    }
  };
  clearImage = () => {
    this.setState({
      pic: ""
    });
  };
  onClickUpload = async () => {
    // const formData = new FormData();
    // formData.append('myImage', this.state.filePic);
    // try {
    //     await POST('upload', formData, true)
    // } catch (error) {
    // }
  };
  handleImageUploadBefore(files, info) {
    console.log(files, info)
  }
  handleImageUpload(targetImgElement, index, state, imageInfo, remainingFilesCount) {
    console.log(targetImgElement, index, state, imageInfo, remainingFilesCount)
  }
  handlePaste(e, cleanData, maxCharCount) {
    console.log(e, cleanData, maxCharCount)
  }
  handleImageUploadError(errorMessage, result) {
    console.log(errorMessage, result)
  }
  handleImageUploadError(errorMessage, result) {
    console.log(errorMessage, result)
  }
  render() {
    let { editorState } = this.state;
    return (
      <div>

        <div>
          <Row style={{ padding: "1rem" }}>
            <FormControl
              style={{ display: "none" }}
              id="some"
              type="file"
              onChange={e => this.handleImage(e.target.files)}
              accept=".jpg,.png"
            />
            <div
              style={{
                margin: "auto",
                width: "50rem",
                height: "400px",
                backgroundColor: "#eee",
                position: "relative"
              }}
              hidden={this.state.pic !== ""}
            >
              <label for="some">
                <div
                  className="border-banner text-center"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    style={{ width: "200px", height: "200px", margin: "auto" }}
                    src={pic}
                  />
                    เลือกรูปภาพ
              </div>
              </label>
            </div>

            <div style={{ margin: "auto" }} hidden={this.state.pic === ""}>
              <img
                src={this.state.pic}
                style={{ width: "auto", height: '400px' }}
              />
            </div>
          </Row>

          <div
            style={{
              paddingLeft: "15rem",
              paddingRight: "15rem",
              justifyContent: "center",
              display: "flex"
            }}
          >
            <a className='trash'>
              <img className="imgdelete"
                src={Icons.IconDelete}
                style={{
                  cursor: "pointer", textAlign: "center", height: "2rem",
                  width: "2rem"
                }}
                onClick={e => this.clearImage()}
              />
            </a>
            {/* <FaTrash
                style={{ cursor: "pointer", height: "2rem", width: "2rem" }}
                onClick={e => this.clearImage()}
              /> */}
          </div>

          <Form
            className="promo"
          >
            <div>
              <Form.Group as={Row} controlId="exampleForm.ControlTextarea1">
                <Form.Label
                  column
                  sm="2.5"
                  className='fontPromotion'
                >
                  ชื่อหัวข้อ :
              </Form.Label>
                <Col>
                  <Form.Control
                    onChange={e => this.handleChange("name_pro", e.target.value)}
                    value={this.state.promoInfo.name_pro}
                    type="text"
                    placeholder="ชื่อหัวข้อ"
                  />
                </Col>
              </Form.Group>
            </div>
            <div>
              <Form.Group as={Row} controlId="exampleForm.ControlTextarea1">
                <Form.Label
                  column
                  sm="2.5"
                  className='fontPromotion'
                >
                  คำอธิบาย :
              </Form.Label>
                <Col>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    placeholder="เขียนคำอธิบาย"
                    onChange={e => this.handleChange("des_pro", e.target.value)}
                    value={this.state.promoInfo.des_pro}
                  />
                </Col>
              </Form.Group>
            </div>
            <div>
              <Form.Group as={Row} controlId="exampleForm.ControlTextarea1">
                <Form.Label
                  column
                  sm="2.5"
                  className='fontPromotion'
                >
                  ชื่อหัวข้อรายละเอียด :
              </Form.Label>
                <Col>
                  <Form.Control
                    onChange={e =>
                      this.handleChange("head_des_promo", e.target.value)
                    }
                    value={this.state.promoInfo.head_des_promo}
                    type="text"
                    placeholder="ชื่อหัวข้อ"
                  />
                </Col>
              </Form.Group>
            </div>
            <div>
              <Form.Group as={Row} controlId="exampleForm.ControlTextarea1">
                <Form.Label
                  column
                  sm="2.5"
                  className='fontPromotion'
                >
                  รายละเอียด :
              </Form.Label>
                <Col>
                  <div style={{ border: "1px solid #ddd" }}>
                    <SunEditor setOptions={{
                      height: 200,
                      buttonList: buttonList.complex
                    }}
                      onImageUploadBefore={this.handleImageUploadBefore()}
                      onImageUpload={this.handleImageUpload()}
                      onPaste={this.handlePaste()}
                      onImageUploadError={this.handleImageUploadError()}
                      //setContents={this.state.editorState}
                      onChange={this.onEditorStateChange} />
                  </div>
                </Col>
              </Form.Group>
            </div>
          </Form>

          <div
            style={{
              paddingLeft: "15rem",
              paddingRight: "15rem",
              justifyContent: "center",
              display: "flex"
            }}
          >
            <button
              className="rcornerspromo1"
              onClick={() => this.handleSubmit()}
            >
              {this.state.isExampleLoading && <Spinner animation="border" variant="primary" />}
                บันทึก
          </button>
          </div>
        </div>

      </div>
    );
  }
}
