import React, { Component } from 'react';
import NavigationBar from './Component/NavigationBar/NavigationBar'
import Footer from './Component/Footer/Footer'

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <NavigationBar />
        <div className="social-list">
          <div>
            <a href="https://www.facebook.com/MitsuudonFanpage/" className="social-icon facebook" target="_blank"><i className="fab fa-facebook-f"></i></a>
          </div>
          <div>
            <a href="https://www.youtube.com/channel/UCTLg0jfi6lAQ-bbkK_yf4JA" className="social-icon youtube" target="_blank"><i className="fab fa-youtube"></i></a>
          </div>
          <div>
            <a href="https://lin.ee/uwiTPCA" className="social-icon line" target="_blank"><img src={require('./Asset/images/icons8-line-240.png')} /></a>
          </div>
          <div>
            <a href="tel:042-237333" className="social-icon phone" target="_blank"><i className="fas fa-phone-alt"></i></a>
          </div>
        </div>
        <section className="app-container">
          {this.props.children}
        </section>
        <Footer />
      </React.Fragment>
    )
  }
}

export default App;
