import React from 'react'
import './Style.scss'

export default function Footer() {
    return (
        <footer className="footer">
            <div className="footer-box">
                <div className="footer-box-title">สำนักงานใหญ่</div>
                <div className="footer-content">
                    <div className="footer-content-title">ติดต่อเรา</div>
                    <p>81/1 ถนนทหาร ตำบลหมากแข้ง<br />
                    อำเภอเมือง จังหวัดอุดรธานี 41000</p>
                </div>
                <div className="footer-content">
                    <div className="footer-content-title">ฝ่ายขาย</div>
                    <p>จันทร์ - เสาร์ 08.00 - 17.00<br />
                    อาทิตย์ 08.30 - 16.30 (เฉพาะฝ่ายขาย)<br />
                    โทร : 042-237333 ต่อ 101</p>
                </div>
                <div className="footer-content">
                    <div className="footer-content-title">นัดหมายศูนย์บริการ</div>
                    <p>โทร : 088-548-8181, 042-237333 ต่อ 100</p>
                </div>
            </div>
            <div className="footer-box">
                <div className="footer-box-title">สาขาบ้านผือ</div>
                <div className="footer-content">
                    <div className="footer-content-title">ติดต่อเรา</div>
                    <p>88/1 หมู่ 8 ตำบลบ้านผือ<br />
                    อำเภอเมือง จังหวัดอุดรธานี 41160</p>
                </div>
                <div className="footer-content">
                    <div className="footer-content-title">ฝ่ายขาย</div>
                    <p>จันทร์ - เสาร์ 08.00 - 17.00<br />
                    โทร : 042-282111</p>
                </div>
                <div className="footer-content">
                    <div className="footer-content-title">นัดหมายศูนย์บริการ</div>
                    <p>โทร : 062-196-7888, 062-196-8777</p>
                </div>
            </div>
            <div className="footer-box mx-auto">
                <div className="footer-content d-flex">
                    <a className="footer-content-social" target="_blank" href="https://www.facebook.com/MitsuudonFanpage/">
                        <div className="facebook"><i className="fab fa-facebook-f" /></div>
                        <span>ถนนทหาร </span>
                    </a>
                    <a className="footer-content-social" target="_blank" href="https://www.facebook.com/MitsuUdon.BaanPhue/">
                        <div className="facebook"><i className="fab fa-facebook-f" /></div>
                        <span>บ้านผือ</span>
                    </a>
                    <a className="footer-content-social" target="_blank" href="https://lin.ee/uwiTPCA">
                        <div className="line"><img src={require('../../Asset/images/icons8-line-240.png')} alt="" /></div>
                        <span>ถนนทหาร</span>
                    </a>
                    <a className="footer-content-social" target="_blank" href="https://lin.ee/3EEAHfJiC">
                        <div className="line"><img src={require('../../Asset/images/icons8-line-240.png')} alt="" /></div>
                        <span>บ้านผือ</span>
                    </a>
                </div>
            </div>
        </footer>
    )
}