import React, { Component } from 'react'
import { _ip, POST, GET } from '../../service'
import Slider from "react-slick";
import '../../Asset/scss/News.scss'

const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [{
        breakpoint: 1480,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
        }
    },
    {
        breakpoint: 1280,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2
        }
    },
    {
        breakpoint: 780,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false
        }
    }
    ]
};

export default class NewsDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            news: [],
            news_dt: {
                news: {
                    source_news: ''
                },
                detail: {
                    head_des_news: '',
                    descript_news: ''
                },
            }
        }
    }
    componentDidMount() {
        this.getNewsDetail()
        this.getNews()
    }
    async getNewsDetail() {
        let id_news = this.props.match.params.newsId
        try {
            let res = await POST('news_by_id', { id_news })
            this.setState({ news_dt: res })

        } catch (error) {
            console.log('getNewsDetail err', error)
        }
    }

    async getNews() {
        try {
            let res = await GET('news_detail')
            this.setState({ news: res })

        } catch (error) {
            console.log('getNews err', error)
        }
    }
    render() {
        let { news_dt, news } = this.state
        return (
            <React.Fragment>
                <div className="header header-75">
                    <div className="header-title">โปรโมชันพิเศษ</div>
                </div>
                <div className="news_dt">
                    <div className="news_dt-title">{news_dt.detail ? news_dt.detail.head_des_news : ''}</div>
                    <div className="news_dt-img">
                        <img src={_ip + '/' + news_dt.news.source_news} alt="" />
                    </div>
                    <div className='news_dt-content' dangerouslySetInnerHTML={{ __html: news_dt.detail ? news_dt.detail.descript_news : '' }}></div>
                </div>
                <div className="home-content-slide plus-100">
                    <div className="home-content-slide-title">
                        <strong>ข่าวสารและกิจกรรม</strong><br />
                        <span>ทันทุกข่าวสารและกิจกรรม ที่สร้างมาเพื่อลูกค้าคนพิเศษ</span>
                    </div>
                    <Slider {...settings}>
                        {news.map((e, i) =>
                            <div className="slide-promotion" key={i} onClick={() => window.location.assign(`/news/${e.id_news}/${e.name_news.replace(/ /g, '-')}`)} >
                                <div className="slide-promotion-thumb">
                                    <div className="slide-promotion-thumb-img">
                                        <img src={_ip + "/" + e.source_news} alt="" />
                                    </div>
                                    <div className="slide-promotion-thumb-content">
                                        {/* <span>{e.name_pro} วันนี้ ถึง {moment(e.time_pro).add('year', 543).format('LL')}</span> */}
                                        <span>{e.name_news}{e.des_news}</span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Slider>
                </div>
                {/* <div className="home-content-slide plus-100">
                    <div className="home-content-slide-title">
                        <strong>ข่าวสารและกิจกรรม</strong><br />
                        <span>ทันทุกข่าวสารและกิจกรรม ที่สร้างมาเพื่อลูกค้าคนพิเศษ</span>
                    </div>
                </div>
                <Slider {...settings}>
                    {news.map((e, i) =>
                        <div className="slide-promotion" key={i}>
                            <div className="slide-promotion-thumb">
                                <div className="slide-promotion-thumb-img">
                                    <img src={_ip + "/" + e.source_news} alt="" />
                                </div>
                                <div className="slide-promotion-thumb-content">
                                    <span>{e.name_news}{e.des_news}</span>
                                </div>
                            </div>
                        </div>
                    )}
                </Slider> */}
            </React.Fragment>
        )
    }
}
