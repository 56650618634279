import React, { Component } from "react";
import { Row, Col, Card, Form, FormControl, Spinner } from "react-bootstrap";
import pic from "../../Asset/img/pic.png";
import { GET, POST, _ip } from "../../service";
import swal from "sweetalert";
import { async } from "q";
import icon_danger from "../../Asset/img/icon-danger.png";
import Swal from "sweetalert2";
import './ProductBrochureAdmin.scss'
export default class Add_product_bro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      carInfocheck: [],
      banner: "",
      buffer: {
        subModelImg: "",
        subModelName: "",
        subModelPrice: "",
        descriptionImg: "",
        descriptionName: "",
        descriptionDes: ""
      },

      buffer1: {
        descriptionImg: "",
        descriptionName: "",
        descriptionDes: ""
      },
      index: 0,
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      subModel: [],
      description: [],
      isExampleLoading: false,
      showCar: "",
      brochure: "",
      carInfo: {
        name_car: "",
        source_car: "",
        des_car: "",
        start_price: "",
        brochure: "",
        header_des_car: ""
      },
      descripHeader: [],
      descripDes: [],
      filebrochure: [],
      filedescription: [],
      filesubModel: [],
      name_sub_car: [],
      price: [],
      indexSub: 0,
      indexDes: 0
    };
  }
  componentDidMount = async () => {

  }
  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

  onClose = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };

  handleChange = (action, value) => {
    let { carInfo } = this.state;
    carInfo[action] = value;
    this.setState({
      carInfo
    });
  };

  handleSubmit = async () => {
    const formData = new FormData();
    let { filedescription, filesubModel, descripHeader, descripDes, name_sub_car, price } = this.state
    formData.append("myImage", this.state.fileshowCar);
    formData.append("myImage", this.state.filebrochure);
    formData.append("myImage", this.state.fileBanner);
    filedescription.forEach(async el => {
      await formData.append("myImage", el)
    });

    formData.append("name_car", this.state.carInfo.name_car);
    formData.append("des_car", this.state.carInfo.des_car);
    formData.append("start_price", this.state.carInfo.start_price);
    // formData.append("brochure", this.state.carInfo.brochure);
    formData.append("header_des_car", this.state.carInfo.header_des_car);
    descripHeader.forEach(async el => {
      await formData.append("head_des", el)

    })
    descripDes.forEach(async el => {
      await formData.append("des", el)
    })


    Swal.fire({
      title: 'คุณต้องการเพิ่มข้อมูลใช่หรือไม่?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'cancel'
    }).then(async (result) => {
      if (result.value) {
        try {
          this.setState({ isExampleLoading: true })
          let check = await POST("create_car_new", formData, true);
          formData.delete("myImage");
          filesubModel.forEach(async el => {
            await formData.append("subcar", el)
          })
          formData.append("id_car", check.id_car)
          name_sub_car.forEach(async el => {
            await formData.append("name_sub_car", el)
          })
          price.forEach(async el => {
            await formData.append("price", el)
          })

          let subRequest = await POST("create_sub_car", formData, true)
          Swal.fire(
            'เพิ่มข้อมูลสำเร็จ',
            '',
            'success'
          ).then(() => {
            this.props.toggle("1");
            this.props.fetch();
            this.setState({
              banner: "",
              buffer: {
                subModelImg: "",
                subModelName: "",
                subModelPrice: "",
                descriptionImg: "",
                descriptionName: "",
                descriptionDes: ""
              },

              buffer1: {
                descriptionImg: "",
                descriptionName: "",
                descriptionDes: ""
              },

              subModel: [],
              description: [],
              showCar: "",
              brochure: "",
              carInfo: {
                name_car: "",
                source_car: "",
                des_car: "",
                start_price: "",
                brochure: "",
                header_des_car: ""
              },
              descripHeader: [],
              descripDes: [],
              filedescription: [],
              filesubModel: [],
              filebrochure: [],
              name_sub_car: [],
              price: [],
              // isExampleLoading:false
            });
          })
          this.setState({
            isExampleLoading: false
          });
        } catch (error) {
          swal({
            title: "กรุณากรอกข้อมูลให้ครบ" + error,
            icon: "warning"
          });
        }
      }

    })
  }


  handleInput = e => {
    let { name_sub_car, price, indexSub } = this.state
    let name = e.target.name;
    let value = e.target.value;
    let buffer = this.state.buffer;
    buffer[name] = value;
    this.setState({ buffer: buffer });
    name_sub_car[indexSub] = buffer.subModelName
    price[indexSub] = parseInt(buffer.subModelPrice)
    this.setState({
      name_sub_car,
      price
    })
  };
  handleInputdes = e => {
    let { descripHeader, descripDes, indexDes } = this.state
    let name = e.target.name;
    let value = e.target.value;
    let buffer1 = this.state.buffer1;
    buffer1[name] = value;
    this.setState({ buffer1: buffer1 });
    descripHeader[indexDes] = buffer1.descriptionName
    descripDes[indexDes] = buffer1.descriptionDes
    this.setState({
      descripHeader,
      descripDes
    })
  };

  handleshowCar = selectorFiles => {
    if (selectorFiles.length > 0) {
      var file = selectorFiles[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.setState({
          showCar: reader.result
        });
        this.setState({
          fileshowCar: selectorFiles[0]
        });
      };
      reader.onerror = function (error) {
      };
    } else {
      this.setState({
        showCar: ""
      });
    }
  };
  clearshowCar = () => {
    this.setState({
      showCar: ""
    });
  };

  handleBanner = selectorFiles => {
    if (selectorFiles.length > 0) {
      var file = selectorFiles[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.setState({
          banner: reader.result
        });
        this.setState({
          fileBanner: selectorFiles[0]
        });
      };
      reader.onerror = function (error) {
      };
    } else {
      this.setState({
        banner: ""
      });
    }
  };

  clearBanner = () => {
    this.setState({
      banner: ""
    });
  };

  handlesubModel = event => {
    let selectorFiles = event.target.files;
    if (selectorFiles.length > 0) {
      var file = selectorFiles[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let { buffer, filesubModel } = this.state;
        buffer.subModelImg = reader.result;
        filesubModel.push(file)
        this.setState({
          buffer: buffer,
        });
        this.setState({
          filesubModel: filesubModel
        });
      };
      reader.onerror = function (error) {
      };
    }
  };

  clearBufferImg = () => {
    let { buffer } = this.state;
    buffer.subModelImg = "";
    this.setState({
      buffer: buffer
    });
  };

  removeSubModel = (e, index) => {
    e.preventDefault();
    let { subModel } = this.state;
    subModel.splice(index, 1);
    this.setState({
      subModel: subModel
    });
  };
  handleDescription = event => {
    let selectorFiles = event.target.files;
    if (selectorFiles.length > 0) {
      var file = selectorFiles[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let { buffer1, filedescription } = this.state;
        buffer1.descriptionImg = reader.result;
        filedescription.push(file)
        this.setState({
          buffer1: buffer1,
          filedescription: filedescription
        });
      };
      reader.onerror = function (error) {
      };
    }
  };
  clearDesBufferImg = () => {
    let { buffer1 } = this.state;
    buffer1.descriptionImg = "";
    this.setState({
      buffer1: buffer1
    });
  };

  removeDescription = (e, index) => {
    e.preventDefault();
    let { description, descripHeader, descripDes } = this.state;
    description.splice(index, 1);
    descripHeader.splice(index, 1);
    descripDes.splice(index, 1);
    this.setState({
      description: description,
      descripHeader,
      descripDes
    });

  };

  handlebrochure = selectorFiles => {
    if (selectorFiles.length > 0) {
      var file = selectorFiles[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.setState({
          brochure: reader.result
        });
        this.setState({
          filebrochure: selectorFiles[0]
        });
      };
      reader.onerror = function (error) {
      };
    } else {
      this.setState({
        brochure: ""
      });
    }
  };
  onSubmitsubModel = e => {
    e.preventDefault();
    let { name_sub_car, price, indexSub } = this.state
    let subModel = this.state.subModel;
    subModel.push(this.state.buffer);

    name_sub_car.push(this.state.buffer.subModelName)
    price.push(parseInt(this.state.buffer.subModelPrice))

    name_sub_car.splice(indexSub, 1);
    price.splice(indexSub, 1);

    indexSub = indexSub + 1
    this.setState({
      subModel,
      buffer: { subModelImg: "", subModelName: "", subModelPrice: "" },
      name_sub_car,
      price,
      indexSub
    });
  };
  ondesSubmit = e => {
    e.preventDefault();
    let { descripHeader, descripDes, indexDes } = this.state

    let description = this.state.description;
    description.push(this.state.buffer1);

    descripHeader.push(this.state.buffer1.descriptionName)
    descripHeader.splice(indexDes, 1);

    descripDes.push(this.state.buffer1.descriptionDes)
    descripDes.splice(indexDes, 1);

    indexDes = indexDes + 1
    this.setState({
      description,
      buffer1: { descriptionImg: "", descriptionName: "", descriptionDes: "" },
      descripHeader,
      descripDes,
      indexDes
    });
  };
  onClickUpload = async () => { };

  render() {
    let { buffer, subModel, description, buffer1 } = this.state;
    return (
      <div>
        <div >
          {/* ใส่ชื่อรุ่นรถ */}
          <Form className="name_car">
            <div>
              <Form.Group as={Row} controlId="exampleForm.ControlTextarea1">
                <Form.Label column sm="2.5" style={{ fontSize: "16px", fontWeight: "bold" }}>
                  ชื่อรุ่นรถ :
              </Form.Label>
                <Col >
                  <Form.Control onChange={(e) => this.handleChange("name_car", e.target.value)} value={this.state.carInfo.name_car} type="text" placeholder="ชื่อหัวข้อ" />
                </Col>
              </Form.Group>
              {/* ใส่ราคาเริ่มต้น */}
              <Form.Group as={Row} controlId="exampleForm.ControlTextarea1">
                <Form.Label column sm="2.5" style={{ fontSize: "16px", fontWeight: "bold" }}>
                  ราคาเริ่มต้น :
              </Form.Label>
                <Col>
                  <Form.Control onChange={(e) => this.handleChange("start_price", e.target.value)} value={this.state.carInfo.start_price} type="text" placeholder="ราคาเริ่มต้น" />
                </Col>
              </Form.Group>
              {/* เลือกภาพโชว์หน้าแรก */}
              <Row style={{ padding: "1rem", justifyContent: 'center' }}>
                <FormControl
                  style={{ display: "none" }}
                  id="someone"
                  type="file"
                  onChange={e => this.handleshowCar(e.target.files)}
                />
                <div
                  style={{
                    margin: "auto",
                    width: "50rem",
                    height: "17rem",
                    backgroundColor: "#eee",
                    position: "relative"
                  }}
                  hidden={this.state.showCar !== ""}
                >
                  <label for="someone">
                    <div
                      className="border-banner text-center"
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        style={{ width: "200px", height: "200px", margin: "auto" }}
                        src={pic}
                      />
                        เลือกรูปรถโชว์หน้าแรก
              </div>
                  </label>
                </div>

                <div style={{ margin: "auto" }} hidden={this.state.showCar === ""}>
                  <img src={this.state.showCar} style={{ width: "auto", height: '17rem' }} />
                </div>

              </Row>
              <div
                style={{ justifyContent: 'center', display: 'flex' }}>
                <button
                  className="rcornersaddproduct"
                  onClick={e => this.clearshowCar()}
                >
                  ลบ
                </button>
                {/* <FaTrash
                    style={{ cursor: "pointer", height: "2rem", width: "2rem" }}
                    onClick={e => this.clearshowCar()}
                  /> */}
              </div>
            </div>
          </Form>
          {/* เลือกBanner */}
          <Row style={{ padding: "1rem" }}>
            <FormControl
              style={{ display: "none" }}
              id="upbanner"
              type="file"
              accept="image/x-png,image/jpeg"
              onChange={e => this.handleBanner(e.target.files)}
            />
            <div className="select_banner" hidden={this.state.banner !== ""}>
              <label for="upbanner">
                <div
                  className="border-banner text-center"
                  style={{ cursor: "pointer" }}
                >
                  <img src={pic} />
                    เลือกBanner
              </div>
              </label>
            </div>
            {/* แสดง banner เมื่ออัพ */}
            <div style={{ margin: "auto" }} hidden={this.state.banner === ""}>
              <img
                width={1000}
                height={400}
                src={this.state.banner}
                style={{ width: "auto", height: '400px' }}
              />
            </div>
          </Row>
          {/* ตัวลบ banner */}
          <div className="delete_banner">
            <button
              className="rcornersaddproduct"
              onClick={e => this.clearBanner()}
            >
              ลบ
                </button>
            {/* <FaTrash
                style={{ cursor: "pointer", height: "2rem", width: "2rem" }}
                onClick={e => this.clearBanner()}
              /> */}
          </div>

          {/* เพิ่มรุ่นรถย่อย */}
          <Row style={{ padding: "1rem", margin: "0 8rem" }}>
            {subModel.length !== 0 &&
              subModel.map((el, i) => (
                <Col className="sub_model">
                  <div>
                    <div>
                      <img src={el.subModelImg}
                        style={{
                          position: "absolute",
                          top: "2rem",
                          objectFit: "contain",
                          ObjectPosition: "center",
                          width: "100%",
                          height: "175px",
                          padding: "0 1rem"
                        }}
                      />
                    </div>
                    <Form>
                      <Form.Group
                        as={Row}
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Label column sm="auto ">
                          ชื่อรุ่นรถย่อย :
                      </Form.Label>
                        <Col>
                          <Form.Control
                            type="text"
                            placeholder="ชื่อรุ่นรถย่อย"
                            value={el.subModelName}
                            disabled
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Label column sm="auto">
                          ราคา :
                      </Form.Label>
                        <Col>
                          <Form.Control
                            type="text"
                            placeholder="ราคา"
                            value={el.subModelPrice}
                            disabled
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group />
                    </Form>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        position: "absolute",
                        bottom: "1rem"
                      }}
                    >
                      <button
                        className="rcornersaddproduct"
                        onClick={e => this.removeSubModel(e, i)}
                      >
                        ลบ
                    </button>
                    </div>
                  </div>
                </Col>
              ))}
            <Col className="sub_model1">
              <FormControl
                id="subcar"
                type="file"
                accept="image/x-png,image/jpeg"
                onChange={this.handlesubModel}
              />
              <div>
                <div hidden={buffer.subModelImg !== ""}>
                  <label for="subcar">
                    <div className="border-car text-center">
                      <img src={pic} />
                        เลือกรูปรถย่อย
                  </div>
                  </label>
                </div>
                <div hidden={buffer.subModelImg === ""}>
                  <img height={175} src={buffer.subModelImg} style={{ position: 'absolute', top: '2rem', objectFit: 'contain', ObjectPosition: 'center', width: '100%', padding: '0 1rem', height: '175px' }} />
                </div>
                <div>
                  {/* ตัวลบ */}
                  <button
                    className="rcornersaddproduct"
                    onClick={e => this.clearBufferImg()}
                  >
                    ลบ
                </button>
                  {/* <FaTrash
                      style={{
                        cursor: "pointer",
                        height: "1.6rem",
                        width: "1.6rem"
                      }}
                      onClick={e => this.clearBufferImg()}
                    /> */}
                </div>
                <Form
                  style={{
                    paddingTop: "1rem",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                    position: "absolute",
                    bottom: "3rem"
                  }}
                >
                  <Form.Group as={Row} controlId="exampleForm.ControlTextarea1">
                    <Form.Label
                      column
                      sm="auto"
                      style={{ fontSize: "16px", fontWeight: "bold" }}
                    >
                      ชื่อรุ่นรถย่อย :
                  </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder="ชื่อรุ่นรถย่อย"
                        name="subModelName"
                        value={buffer.subModelName}
                        onChange={this.handleInput}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="exampleForm.ControlTextarea1">
                    <Form.Label
                      column
                      sm="auto"
                      style={{ fontSize: "16px", fontWeight: "bold" }}
                    >
                      ราคา :
                  </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder="ราคา"
                        name="subModelPrice"
                        value={buffer.subModelPrice}
                        onChange={this.handleInput}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group />
                </Form>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    position: "absolute",
                    bottom: "1rem"
                  }}
                >
                  <button className="rcornersaddproduct" onClick={this.onSubmitsubModel}>
                    เพิ่ม
                </button>
                </div>
              </div>
            </Col>
          </Row>
          {/* กรอกข้อมูล */}
          <Form style={{ padding: '1rem' }}>
            <Row style={{ justifyContent: 'center' }}>
              {/*  */}
              <Col md={7}>
                <Form.Group as={Row} controlId="exampleForm.ControlTextarea1">
                  <Form.Label column sm="3" style={{ fontSize: "16px", fontWeight: "bold" }}>
                    ชื่อหัวข้อ :
                </Form.Label>
                  <Col>
                    <Form.Control onChange={(e) => this.handleChange("header_des_car", e.target.value)} value={this.state.carInfo.header_des_car} type="text" placeholder="ชื่อหัวข้อ" />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            {/*  */}
            <Row style={{ justifyContent: 'center' }}>
              <Col md={7}>
                <Form.Group as={Row} controlId="exampleForm.ControlTextarea1">
                  <Form.Label column sm="3" style={{ fontSize: "16px", fontWeight: "bold" }}>
                    รายละเอียด :
                </Form.Label>
                  <Col>
                    <Form.Control as="textarea" rows="3" placeholder="รายละเอียด" onChange={(e) => this.handleChange("des_car", e.target.value)} value={this.state.carInfo.des_car} />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            {/* เพิ่มโปรชัวร์ */}
            <Row style={{ justifyContent: 'center' }}>
              <Col md={4}>
                <div style={{ width: "300px", height: "120px", backgroundColor: "#eee", position: "relative" }}>
                  <div className="border-car1 text-center">
                    เลือกโปรชัวร์
                  <FormControl
                      type="file"
                      name="fileData"
                      accept="application/pdf"
                      onChange={e => this.handlebrochure(e.target.files)}
                    // value={this.state.carInfo.brochure} 
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
          {/* เพิ่มภาพและรายละเอียด */}
          <Row
            style={{ padding: "1rem", display: "flex", justifyContent: "center" }}
          >
            {description.length !== 0 &&
              description.map((el, i) => (
                <Row style={{ margin: "1rem 0 1rem 0" }}>
                  <div
                    style={{
                      margin: "0 3rem",
                      height: "250px",
                      backgroundColor: "#eee",
                      position: "relative"
                    }}
                  >
                    <Row>
                      <Col md={7}>
                        <Form
                          style={{
                            paddingTop: "2rem",
                            paddingLeft: "1rem",
                            paddingRight: "1rem"
                          }}
                        >
                          <Form.Group
                            as={Row}
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label
                              column
                              sm="auto "
                              style={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              หัวข้อ :
                          </Form.Label>
                            <Col>
                              <Form.Control
                                type="text"
                                placeholder="หัวข้อ"
                                value={el.descriptionName}
                                disabled
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group
                            as={Row}
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label
                              column
                              sm="3"
                              style={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              รายละเอียด :
                          </Form.Label>
                            <Col>
                              <Form.Control
                                type="textarea"
                                rows="3"
                                placeholder="รายละเอียด"
                                value={el.descriptionDes}
                                disabled
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group />
                          <div
                            style={{ display: "flex", justifyContent: "center" }}
                          >
                            <button
                              className="rcornersaddproduct"
                              onClick={e => this.removeDescription(e, i)}
                            >
                              ลบ
                          </button>
                          </div>
                        </Form>
                      </Col>
                      <Col md={5}>
                        <div className='afterAddDesCar'>
                          <img height={175} src={el.descriptionImg} />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Row>
              ))}
            <FormControl
              style={{ display: "none" }}
              id="descrip"
              type="file"
              accept="image/x-png,image/jpeg"
              onChange={this.handleDescription}
            />
            <div className="bgDesCar">
              <Row>
                <Col md={7}>
                  <Form className="formDesCar">
                    <Form.Group as={Row} controlId="exampleForm.ControlTextarea1">
                      <Form.Label column sm="auto">
                        หัวข้อ:
                    </Form.Label>
                      <Col>
                        <Form.Control
                          type="text"
                          placeholder="หัวข้อ"
                          name="descriptionName"
                          value={buffer1.descriptionName}
                          onChange={this.handleInputdes}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="exampleForm.ControlTextarea1">
                      <Form.Label column sm="3">
                        รายละเอียด :
                    </Form.Label>
                      <Col>
                        <Form.Control
                          as="textarea"
                          rows="3"
                          type="text"
                          placeholder="รายละเอียด"
                          name="descriptionDes"
                          value={buffer1.descriptionDes}
                          onChange={this.handleInputdes}
                        />
                      </Col>
                    </Form.Group>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <button
                        className="rcornersaddproduct"
                        onClick={this.ondesSubmit}
                      >
                        เพิ่ม
                    </button>
                    </div>
                    <Form.Group />
                  </Form>
                </Col>
                <Col md={5}>
                  <div
                    className="selectDesCar"
                    hidden={buffer1.descriptionImg !== ""}
                  >
                    <label for="descrip">
                      <div className="border-car text-center">
                        <img src={pic} />
                          รูปรายละเอียดรถ
                    </div>
                    </label>
                  </div>
                  <div className="beforeAddDesCar" hidden={buffer1.descriptionImg === ""} >
                    <img height={175} src={buffer1.descriptionImg} />
                  </div>
                  <div className="styleTarsh">
                    {/* ตัวลบ */}
                    <button
                      className="rcornersaddproduct"
                      onClick={e => this.clearDesBufferImg()}
                    >
                      ลบ
                </button>
                    {/* <FaTrash onClick={e => this.clearDesBufferImg()} /> */}
                  </div>
                </Col>
              </Row>
            </div>
          </Row>




          <div className="styleButton">
            <button className="rcornersproduct1" onClick={() => this.handleSubmit()}>
              {this.state.isExampleLoading && <Spinner animation="border" variant="primary" />}
              บันทึก</button>
          </div>
        </div>

      </div >
    );
  }
}
