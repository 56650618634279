import React, { Component } from "react";

import {
  FormControl,
  Card,
  InputGroup,
  Form,
  Row,
  Col,
  Image, Table
} from "react-bootstrap";

import { GET, POST, _ip } from "../../service";
import moment from "moment";
import Responsive from 'react-responsive';
import icon_danger from '../../Asset/img/icon-danger.png'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
export default class Insurance_admin extends Component {
  constructor(props) {

    super(props);
    this.state = {
      index: 0,
      insuranceInfo: [],
    };
  }
  fatch = async () => {
    try {
      let insuranceInfo = await GET("insurance_detail");

      this.setState({
        insuranceInfo: insuranceInfo
      });


    } catch (error) {
    }
  }
  componentDidMount = async () => {
    this.fatch()
  };
  render() {
    const { insuranceInfo } = this.state;

    return (
      <div>

        <div className="" style={{ paddingTop: "10px" }}>
          <Row>
            <Col md={8}>
              <div className="rcornerscontact" style={{ color: 'rgb(249, 0, 1)' }}>ต่อประกัน</div>
            </Col>
            <BootstrapTable data={this.state.insuranceInfo} pagination >
              <TableHeaderColumn dataField='user_id' dataFormat={this.userIcon} width='50' dataAlign="center"> </TableHeaderColumn>
              <TableHeaderColumn dataField='name_insu' dataFormat={this.addNumber} isKey>ชื่อ-นามสกุล</TableHeaderColumn>
              <TableHeaderColumn dataField='email_insu'>Email</TableHeaderColumn>
              <TableHeaderColumn dataField='tel_insu'>เบอร์โทรศัพท์</TableHeaderColumn>
              <TableHeaderColumn dataField='date_insu' dataFormat={(cell) => moment(cell).format('DD-MM-YYYY')}>วันหมดอายุประกัน</TableHeaderColumn>
              <TableHeaderColumn dataField='name_car'>รุ่นรถ</TableHeaderColumn>
              {/* s<TableHeaderColumn dataField='user_id' dataFormat={this.userInfo}></TableHeaderColumn> */}
            </BootstrapTable>
          </Row>
        </div>
      </div >
    );
  }
}
