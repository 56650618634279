import React, { Component } from "react";
import icon_danger from '../../Asset/img/icon-danger.png'
import {
  TabContent,
  TabPane,
  Row,
  Col,
  Table
} from "reactstrap";
import Icons from '../../Component/Icons/Icons'
import Add_promotion from "./Add_promotion";

import { GET, POST, _ip } from "../../service";
import swal from 'sweetalert';
import Swal from 'sweetalert2'
import './PromotionAdmin.scss'
import { Tabs, Tab } from 'react-bootstrap'

export default class Promotion_Admin extends Component {
  constructor(props) {

    super(props);
    this.toggle = this.toggle.bind(this);
    this.fetch = this.fetch.bind(this);
    this.state = {
      index: 0,
      promoInfo: [],
      activeTab: "1"
    };
  }

  fetch = async () => {
    try {
      let promoInfo = await GET("/promo_detail");

      this.setState({
        promoInfo: promoInfo
      });

    } catch (error) {
    }
  }

  componentDidMount = async () => {
    this.fetch()
  };

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  deletePromo = async (id_pro) => {
    Swal.fire({
      title: 'คุณต้องการลบใช่หรือไม่?',
      // text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then((result) => {
      if (result.value) {
        let del = POST("delete_promo", { id_pro })
        Swal.fire(
          'ลบข้อมูลสำเร็จ',
          '',
          'success'
          // 'Your file has been deleted.',
          // 'success'
        )
      }
      this.fetch();
    })
  };

  render() {
    const { promoInfo } = this.state;

    return (
      <div>

        <Tabs defaultActiveKey="รายการทั้งหมด" id="uncontrolled-tab-example" className="tab">
          <Tab eventKey="รายการทั้งหมด" title="รายการทั้งหมด">
            <div className="rcornerscontact" style={{ color: "rgb(249, 0, 1)", marginTop: '10px' }}>รายการทั้งหมด</div>
            <Table striped bordered hover>
              <thead>
                <tr className="Fontadmin">
                  <th style={{ width: "2rem" }}>ลำดับ</th>
                  <th>หัวข้อ</th>
                  <th style={{ width: "10rem" }}>Function</th>
                </tr>
              </thead>
              {promoInfo.length > 0
                ? promoInfo.map((index, i) => (
                  <tbody key={index.id_pro}>
                    <tr style={{ textAlign: "center" }}>
                      <td>{i + 1}</td>
                      <td>{index.name_pro}</td>
                      <td className="function-promotion">
                        <div >
                          <a href={`/edit_promotion/${index.id_pro}`}>
                            <i class="far fa-edit">
                            </i>
                          </a>
                        </div>
                        <div>
                          <a className='trash'>
                            <img className="imgdelete"
                              src={Icons.IconDelete}
                              style={{
                                cursor: "pointer", textAlign: "center", height: "2rem",
                                width: "2rem"
                              }}
                              onClick={e => this.deletePromo(index.id_pro)}
                            />
                            {/* <FaTrash onClick={() => this.deleteProduct(index.id_car)} /> */}
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ))
                : null}
            </Table>
          </Tab>
          <Tab eventKey="เพิ่มโปรโมชั่น" title="เพิ่มโปรโมชั่น">
            <div className="rcornerscontact" style={{ color: "rgb(249, 0, 1)", marginTop: '10px' }}>เพิ่มโปรโมชั่น</div>
            <Add_promotion toggle={this.toggle} fetch={this.fetch} />
          </Tab>
        </Tabs>
      </div >
    );
  }
}
