// export const _ip = "http://localhost:3048";
//export const _ip = 'http://172.16.63.2:4000';
export const _ip = "https://mitsuudon.com";

export const ip = _ip + '/api/v1/';

function response(json) {
  return json.success
    ? Promise.resolve(json.result)
    : Promise.reject(json.message);
}

export const POST = (path, obj, formData) =>
  fetch(ip + path, {
    method: 'POST',
    headers: formData
      ? {}
      : {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
    body: formData ? obj : JSON.stringify(obj),
    credentials: 'include'
  })
    .then(res => res.json())
    .then(response);

export const GET = path =>
  fetch(ip + path, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include'
  })
    .then(res => res.json())
    .then(response);
