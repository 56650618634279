import React from "react";
import Home from '../Page/Home'
import News from '../Page/News/News'
import NewsDetail from '../Page/News/NewsDetail'
import Service from '../Page/Service/Service'
import About from '../Page/About'
import Products from '../Page/Products/Products'
import ProductCarDetail from '../Page/Products/ProductCarDetail'
import Contact from '../Page/Contact'
import Promotions from '../Page/Promotions/Promotios'
import PromotionDetail from '../Page/Promotions/PromotionDetail'
import Center from '../Page/Service/Center'
import Testdrive from '../Page/Others/Testdrive'
import Insurance from '../Page/Others/Insurance'
import Secondhand from '../Page/Others/SecondHandCar'
import GuaranteeDetail from '../Page/Others/Guarantee/Description'
import Paint from '../Page/Service/Paint'
import ContactService from '../Page/Service/ContactService'

const user_router = [
    {
        path: "/",
        exact: true,
        main: () => <Home />
    },
    {
        path: "/about",
        exact: false,
        main: () => <About />
    },
    {
        path: "/products",
        exact: true,
        main: () => <Products />
    },
    {
        path: "/products/:carId/:carName",
        exact: false,
        main: (props) => <ProductCarDetail {...props} />
    },
    {
        path: "/service",
        exact: true,
        main: () => <Service />
    },
    {
        path: "/service/center",
        exact: false,
        main: () => <Center />
    },
    {
        path: "/service/paint",
        exact: false,
        main: () => <Paint />
    },
    {
        path: "/other/guaranteeusedcar",
        exact: true,
        main: () => <Secondhand />
    },
    {
        path: "/other/guaranteeusedcar/:guaranteeid/:guaranteename",
        exact: false,
        main: (props) => <GuaranteeDetail  {...props} />
    },
    {
        path: "/other/testdrive",
        exact: false,
        main: () => <Testdrive />
    },
    {
        path: "/other/insurance",
        exact: false,
        main: () => <Insurance />
    },
    {
        path: "/promotion",
        exact: true,
        main: () => <Promotions />
    },
    {
        path: "/promotion/:proId/:proContent",
        exact: false,
        main: (props) => <PromotionDetail {...props} />
    },
    {
        path: "/news",
        exact: true,
        main: () => <News />
    },
    {
        path: "/news/:newsId/:newsContent",
        exact: false,
        main: (props) => <NewsDetail {...props} />
    },
    {
        path: "/contact",
        exact: false,
        main: () => <Contact />
    }
    ,
    {
        path: "/service/contact_for_service",
        exact: false,
        main: () => <ContactService />
    }
]

export default user_router