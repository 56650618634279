import React, { Component } from "react";
import { Card, Button, Row, Col } from "react-bootstrap";
import { MDBCollapse } from "mdbreact";
import '../../../Asset/scss/GuaranteeCar.scss'
// import { FaUserCircle } from "react-icons/fa";
import { GET, _ip, ip } from "../../../service";

export default class Description extends Component {
  constructor(props) {
    super(props);
    this.state = {
      secondCar: [],
      id: "",
      banSecondInfo: [],
      desSecond: [],
      bannerPic: "",
      desCurentSecond: [],
      desuseCar: [],
      usePic: []
    };
  }
  componentDidMount = async () => {
    let { guaranteeid } = this.props.match.params;
    this.setState({
      id: guaranteeid
    });

    try {
      let secondCar = await GET("second_detail");
      let banSecondInfo = await GET("detail_ban_second");
      let desSecond = await GET("second_detail");
      let desuseCar = await GET("album_second_detail");

      this.setState({
        secondCar: secondCar
      });


      this.setState({
        banSecondInfo: banSecondInfo
      });


      this.setState({
        desSecond: desSecond
      });


      this.setState({
        desuseCar: desuseCar
      });

      // banner ///////////////////////////////////////
      this.state.banSecondInfo.forEach(element => {
        if (element.id_second === Number(this.state.id)) {
          this.setState({
            bannerPic: element.source_ban_second
          });
        }
      });
      ////////////////////////////////////////////////
      // des car /////////////////////////////////////
      var { desCurentSecond } = this.state;
      this.state.desSecond.forEach(element => {
        if (element.id_second === Number(this.state.id)) {
          desCurentSecond.push(element);
        }
      });
      this.setState({
        desCurentSecond
      });
      // รูปรถมือสอง ///////////////////////////////////////
      this.state.desuseCar.forEach(element => {
        if (element.id_second === Number(this.state.id)) {
          this.setState({
            usePic: JSON.parse(element.source_album_second)
          });
        }
      });
    } catch (error) { }
  };
  state = {
    collapseID: ""
  };
  toggleCollapse = collapseID => () => {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }));
  };
  render() {
    const {
      secondCar,
      bannerPic,
      desCurentSecond,
      desuseCar,
      usePic
    } = this.state;

    return (
      // <React.Fragment>
      //   <div className="product_dt-banner">
      //     <img src={_ip + '/' + bannerPic} alt="" />
      //     <button className="btn">โปรชัวร์</button>
      //   </div>
      //   <div className="product_dt-car">
      //     <div className="product_dt-car-title">{car.car.name_car}</div>
      //     <div className="product_dt-car-content">ราคาเริ่มต้นที่</div>
      //     <div className="product_dt-car-price">{usePic.toLocaleString()} บาท</div>
      //   </div>
      //   {/* <div>ดีไซน์ภายนอก/ภายใน</div> */}
      //   {desCurentSecond.map((e, i) =>
      //     <div className={`feature ${e.des ? 'half' : 'full'}`} key={i}>
      //       <div className="feature-overlay"></div>
      //       <div className="feature-img">
      //         <img src={_ip + '/' + e.source} alt="" />
      //       </div>
      //       <div className="feature-content">
      //         <strong>{e.header_des_second_car}</strong>
      //         <p>{e.des}</p>
      //       </div>
      //     </div>
      //   )}
      // </React.Fragment>
      <div className="FontHeader">

        {/* banner */}
        <div>
          <div className="img-text">
            {/* <div className='img-text' key={index.id_second}> */}

            {/* banner */}
            {/* <img src={mini}  style={{ width: '100%', objectFit: "cover", objectPosition: "center", maxHeight: '25rem' }} />           */}
            <img
              src={_ip + "/" + bannerPic}
              style={{
                width: "100%",
                objectFit: "cover",
                objectPosition: "center",
                maxHeight: "25rem"
              }}
            />
            {/* <div className="text-left" style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }} >
                            <p className="h4" style={{ textAlign: 'center', wordWrap: 'break-word', minWidth: '0' }}>
                                2018 MINI COUNTRYMAN, COUNTRYMAN COOPER S โฉม COUNTRYMAN
                                <p className="h3" style={{ marginTop: '2rem', fontWeight: 'bold' }}>ราคา 1,850,000 บาท</p>
                            </p>
                            <br />
                            <div style={{ position: 'absolute', bottom: '10px', left: 'calc(50% - 42px)' }}>
                                <butoon className="btn  btm-custom h1" >ทดลองขับ</butoon>
                            </div>
                        </div> */}
          </div>

          {/* end banner */}

          {/* ข้อความอธิบายรถมือสอง */}
          <div className="container">
            <Card style={{ border: "unset" }}>
              {desCurentSecond.map(el => (
                <Card.Body className="card-des">
                  <Card.Text className="text-font">
                    <b>{el.header_des_second_car}</b>
                  </Card.Text>
                  <Card.Text className="text-font1">
                    {el.des_second_car}
                  </Card.Text>
                </Card.Body>
              ))}
            </Card>
          </div>
          {/* รูปรถมือสอง */}
          <Card
            className="container"
            style={{
              padding: "2rem",
              border: "unset",
              backgroundColor: "unset"
            }}
          >
            <Row>
              {usePic &&
                usePic.slice(0, 4).map((el, i) => (
                  <Col xs={12} md={6} className="px-0 mx-0">
                    <Card.Img
                      style={{ margin: "0rem 0 0rem 0" }}
                      src={_ip + "/" + el.source}
                    />
                  </Col>
                ))}
            </Row>
            <MDBCollapse id="basicCollapse" isOpen={this.state.collapseID}>
              <Row>
                {usePic &&
                  usePic.slice(4, usePic.length).map((el, i) => (
                    <Col xs={12} md={6} className="px-0 mx-0">
                      <Card.Img
                        style={{ margin: "0rem 0 0rem 0" }}
                        src={_ip + "/" + el.source}
                      />
                    </Col>
                  ))}
              </Row>
            </MDBCollapse>
          </Card>
          {usePic.length > 4 ? (
            !this.state.collapseID ? (
              <div className="container" style={{ paddingTop: "2rem" }}>
                <Row>
                  <Button
                    variant="outline-dark"
                    style={{ left: "50%" }}
                    onClick={this.toggleCollapse("basicCollapse")}
                  >
                    ดูรูปภาพรถยนต์เพิ่มเติม
                  </Button>
                </Row>
              </div>
            ) : null
          ) : null}
          {/* ข้อความพิเศษ */}
          {/* <div className="container" style={{ paddingTop: "5rem" }}>
            <Row>
              <Col xs={12} md={8}>
                <Card style={{ backgroundColor: "black" }}>
                  <Card.Text
                    style={{
                      padding: "3rem",
                      color: "white",
                      textAlign: "center"
                    }}
                  >
                    <h5>"ดอกเบี้ยพิเศษ 2.79% จัดไฟแนนซ์ได้สูง"</h5>
                  </Card.Text>
                </Card>
              </Col>
              ชื่อผู้ขาย
              <Col xs={12} md={4}>
                รายละเอียดผู้ขาย
                <Card style={{ border: "unset" }}>
                  <Row>
                    <Col lg={6} md={12} sm={12} style={{ paddingTop: "1rem" }}>
                      <span> ผู้ขาย ปธานิน ต. </span>
                      <br />
                      <span> ติดต่อ 089-691-3609 </span>
                      <br />
                      <span> 086-900-9663 </span>
                    </Col>
                    <Col lg={6} md={12} sm={12}>
                      <FaUserCircle style={{ width: "80%", height: "80%" }} />
                    </Col>{" "}
                  </Row>
                </Card>
              </Col>
            </Row>
          </div>*/}
        </div>
        <div style={{ paddingTop: "2rem" }} />

      </div>
    );
  }
}
