import React, { Component } from 'react'

import { FormControl, Card, InputGroup, Form, Row, Col, Image } from 'react-bootstrap'
import '../../Asset/scss/Testdrive.scss'
import In from '../../Asset/img/insurance/in.PNG'
import thai from '../../Asset/img/insurance/logoin.PNG'

import { GET, POST } from "../../service"
import swal from 'sweetalert';

export default class Insurance extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},
            insurance: {
                first: "",
                last: "",
                email_insu: "",
                tel_insu: "",
                date_insu: "",
                id_car: ""
            },
            cars: []
        }
    }
    onMarkerClick = (props, marker, e) =>
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });

    onClose = props => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            });
        }
    };
    handleChange = (action, value) => {
        let { insurance } = this.state
        insurance[action] = value
        this.setState({
            insurance
        });

    }
    componentDidMount = async () => {

        try {
            let cars = await GET("car_detail")
            this.setState({
                cars: cars
            })

        } catch (error) {

        }
    }
    handleSubmit = async () => {
        try {
            let check = await POST('create_insurance', this.state.insurance)
            swal({
                title: "ส่งข้อมูลสำเร็จ",
                icon: "success"
            });
            this.setState({
                insurance: {
                    first: "",
                    last: "",
                    email_insu: "",
                    tel_insu: "",
                    date_insu: ""
                }
            })


        } catch (error) {
            swal({
                title: "เกิดข้อผิดพลาดกรุณาลองใหม่",
                icon: "warning",
            });
        }
    }
    render() {
        const { cars } = this.state
        return (
            <div className="FontHeader">
                {/* banner */}
                <div className="header header-50">
                    <div className="header-title">ต่อประกันรถยนต์</div>
                </div>
                {/* end banner */}
                <div className="testdrive">
                    <div className="testdrive-content" >
                        {/* บริการของเรา */}
                        <Card style={{ border: 'unset' }}>
                            <Row>
                                <Col lg={6} md={12} sm={12} >
                                    <Card.Body >
                                        <Card.Title style={{ color: 'black' }}><h4><b>บริการของเรา</b></h4></Card.Title>
                                        <Card.Text style={{ color: 'black', paddingTop: '1rem' }}>"บริการด้านการขาย อะไหล่ ศูนย์ซ่อมรถยนต์ และศูนย์ซ่อมสีและตัวถังมาตรฐาน
                                        โดยทีมงานมืออาชีพผ่านการอบรมทักษะจากมิตซูบิชิมอเตอร์ประเทศไทย ทั้งนี้ศูนย์บริการของเรายังพร้อมทั้งด้าน สถานที่ เครื่องมือ
                                        เทคโนโลยีที่ล้ำสมัย พร้อมด้านทีมงานมากประสบการณ์เพื่อสามารถตอบสนองความพึงพอใจสูงสุดของลูกค้า "</Card.Text>

                                    </Card.Body>
                                </Col>
                                <Col lg={6} md={12} sm={12} >
                                    <Card.Img variant="top" src={In} style={{ objectFit: "cover", objectPosition: "center", maxHeight: '22rem' }} />
                                </Col>
                            </Row>
                        </Card>

                        {/* form กรอกข้อมูลเพื่อรับบริการ */}
                        <h5 ><b>กรอกข้อมูลเพื่อรับบริการ</b></h5>
                        <Row>
                            <Col lg={6} sm={12} >
                                <Form.Group>
                                    <Form.Control onChange={(e) => this.handleChange("first", e.target.value)} value={this.state.insurance.first} placeholder="ชื่อลูกค้า" />
                                </Form.Group>
                            </Col>
                            <Col lg={6} sm={12} >
                                <Form.Group>
                                    <Form.Control onChange={(e) => this.handleChange("last", e.target.value)} value={this.state.insurance.last} placeholder="นามสกุล" />
                                </Form.Group>
                            </Col>
                            <Col lg={6} sm={12} >
                                <Form.Group>
                                    <Form.Control onChange={(e) => this.handleChange("email_insu", e.target.value)} value={this.state.insurance.email_insu} placeholder="Email" />
                                </Form.Group>
                            </Col>
                            <Col lg={6} sm={12} >
                                <Form.Group>
                                    <InputGroup >
                                        <InputGroup.Prepend className="custom-InputGroup-Prepend">
                                            {/* <InputGroup.Text>
                                                <Image style={{ width: '24.5%' }} rel="nofollow" src={thai} ></Image>
                                                &nbsp; +66</InputGroup.Text> */}
                                            <InputGroup.Text id="basic-addon1" style={{ width: "3rem", backgroundColor: 'unset' }}>
                                                <Image style={{ width: '1.6rem' }} rel="nofollow" src={thai} ></Image>
                                            </InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl type="number" onChange={(e) => this.handleChange("tel_insu", e.target.value)} value={this.state.insurance.tel_insu} placeholder="เบอร์โทรศัพท์" />
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            <Col lg={6} sm={12} >
                                <Form.Group>
                                    <InputGroup>
                                        <Form.Control type='date' onChange={(e) => this.handleChange("date_insu", e.target.value)} value={this.state.insurance.date_insu} placeholder="วันที่กรมธรรม์หมดอายุ" />
                                        <InputGroup.Append>
                                            <InputGroup.Text>
                                                {/* <FaCalendarAlt /> */}
                                            </InputGroup.Text>
                                        </InputGroup.Append>
                                    </InputGroup>
                                    <Form.Text className="text-muted">
                                        วันที่กรมธรรม์หมดอายุ
                                        </Form.Text>
                                </Form.Group>
                            </Col>
                            <Col lg={6} sm={12} >
                                <Form.Group controlId="exampleForm.ControlSelect1">
                                    <Form.Control onChange={(e) => this.handleChange("id_car", e.target.value)} value={this.state.insurance.id_car} as="select" >
                                        <option value="">กรุณาเลือกรุ่นรถยนต์ที่สนใจ</option>
                                        {cars.map((index, i) =>
                                            <option value={index.id_car}>{index.name_car}</option>
                                        )}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col className="d-flex justify-content-center">
                                <button className="buttonTest" onClick={() => this.handleSubmit()}>
                                    ส่งข้อมูลถึงเรา </button>
                            </Col>
                        </Row>
                    </div>
                </div>

            </div>
        )
    }
}
