import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  FormControl, Spinner
} from "react-bootstrap";
import Icons from '../../Component/Icons/Icons'
import pic from "../../Asset/img/pic.png";
import Responsive from "react-responsive";
import { GET, POST, _ip } from "../../service";
import swal from "sweetalert";
import Swal from "sweetalert2";
import './PromotionAdmin.scss'
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import SunEditor, { buttonList } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

export default class Edit_promo extends Component {
  constructor(props) {
    // if (localStorage.getItem("user") == null) {
    //   window.location.href = "/login";
    // }
    super(props);
    this.state = {
      filePicPromo: {},
      id: "",
      index: 0,
      editorState: EditorState.createEmpty(),
      content: EditorState.createEmpty(),
      isExampleLoading: false,
      promoInfo: {
        name_pro: "",
        source_pro: "",
        des_pro: ""
      },
      desPromoInfo: {
        head_des_promo: "",
        des_promo: ""
      },
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      picPromo: ""
    };
  }

  componentDidMount = async () => {
    let { id } = this.props.match.params;
    let id_pro = id

    this.setState({
      id: id_pro
    });

    try {
      let promotion = await POST("promotion_detail_id", { id_pro });
      this.setState({
        picPromo: promotion.promo.source_pro,
        des_pro: promotion.promo.des_pro,
        name_pro: promotion.promo.name_pro
      });

      this.setState({
        head_des_promo: promotion.detail.head_des_promo,
        des_promo: promotion.detail.des_promo
      });
      // const { contentBlocks, entityMap } = htmlToDraft(promotion.detail.des_promo);
      // const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      // this.setState({ editorState: EditorState.createWithContent(contentState) });
      this.setState({ content: promotion.detail.des_promo })

    } catch (error) { }
  };
  handleSubmit = async () => {
    const { des_promo, head_des_promo, des_pro, name_pro } = this.state;
    const formData = new FormData();
    if (des_promo && head_des_promo && des_pro && name_pro) {
      formData.append("id_pro", this.props.match.params.id);
      formData.append("myImage", this.state.filePicPromo);
      formData.append("name_pro", this.state.name_pro);
      formData.append("des_pro", this.state.des_pro);
      formData.append("head_des_promo", this.state.head_des_promo);
      formData.append("des_promo", this.state.editorState);
      try {
        Swal.fire({
          title: 'คุณต้องการแก้ไขข้อมูลใช่หรือไม่?',
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          cancelButtonText: 'cancel'
        }).then(async (result) => {
          if (result.value) {
            this.setState({ isExampleLoading: true })
            try {
              let check = await POST("/update_promo", formData, true);
              Swal.fire(
                'แก้ไขข้อมูลสำเร็จ',
                '',
                'success'
              ).then(() => {
                this.props.history.push("/promotion_admin");
              })
            } catch (error) {
              Swal.fire(
                'กรุณากรอกข้อมูลใหครบ',
                '',
                'warning'
              )
            }
          }
          this.setState({
            showingInfoWindow: false,
          });
          // this.props.fetch();
          // this.setState({
          //   newsInfo: {
          //     name_banner: "",
          //     des_news: ""
          //   },
          //   pic: ""
          // });
        })


      } catch (error) {
      }
    } else {
      swal({
        title: "กรุณากรอกข้อมูลให้ครบ",
        icon: "warning"
      });
    }
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState
    });
  };

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

  onClose = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };
  handleChange = ({ target: { value, name } }) => {
    this.setState({ [name]: value });
  };


  handleImage = selectorFiles => {
    if (selectorFiles.length > 0) {
      var file = selectorFiles[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.setState({
          picPromo: reader.result
        });
        this.setState({
          filePicPromo: selectorFiles[0]
        });
      };
      reader.onerror = function (error) {
      };
    } else {
      this.setState({
        picPromo: ""
      });
    }
  };
  clearImage = () => {
    this.setState({
      picPromo: ""
    });
  };
  onClickUpload = async () => { };
  handleImageUploadBefore(files, info) {
    console.log(files, info)
  }
  handleImageUpload(targetImgElement, index, state, imageInfo, remainingFilesCount) {
    console.log(targetImgElement, index, state, imageInfo, remainingFilesCount)
  }
  handlePaste(e, cleanData, maxCharCount) {
    console.log(e, cleanData, maxCharCount)
  }
  handleImageUploadError(errorMessage, result) {
    console.log(errorMessage, result)
  }
  handleImageUploadError(errorMessage, result) {
    console.log(errorMessage, result)
  }
  render() {
    const { picPromo, editorState } = this.state;
    return (
      <div>

        <div style={{ paddingTop: "100px", paddingBottom: "1rem" }}>
          <div
            style={{
              color: "black",
              marginTop: "2rem",
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <div
              className="rcornerscontact"
              style={{ color: "rgb(249, 0, 1)" }}
            >
              แก้โปรโมชัน
              </div>
            <Card.Link
              className="rcornersnewsAdmin3"
              href="/promotion_admin"
            >
              กลับ
              </Card.Link>
          </div>

          <Row style={{ padding: "1rem" }}>
            <FormControl
              style={{ display: "none" }}
              id="some"
              type="file"
              onChange={e => this.handleImage(e.target.files)}
              accept=".jpg,.png"
            />
            <div
              style={{
                margin: "auto",
                width: "50rem",
                height: "400px",
                backgroundColor: "#eee",
                position: "relative"
              }}
              hidden={this.state.picPromo !== ""}
            >
              <label for="some">
                <div
                  className="border-banner text-center"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    style={{
                      width: "200px",
                      height: "200px",
                      margin: "auto"
                    }}
                    src={pic}
                  />
                    เลือกรูปภาพ
                  </div>
              </label>
            </div>

            <div
              style={{ margin: "auto" }}
              hidden={this.state.picPromo === ""}
            >
              <img
                //   src={this.state.pic}
                name="picPromo"
                src={
                  picPromo.indexOf("static") !== -1
                    ? _ip + "/" + picPromo
                    : picPromo
                }
                style={{ width: "auto", height: '400px' }}
              />
            </div>
          </Row>

          <div
            style={{
              paddingLeft: "15rem",
              paddingRight: "15rem",
              justifyContent: "center",
              display: "flex"
            }}
          >
            <a className='trash'>
              <img className="imgdelete"
                src={Icons.IconDelete}
                style={{
                  cursor: "pointer", textAlign: "center", height: "2rem",
                  width: "2rem"
                }}
                onClick={e => this.clearImage()}
              />
            </a>
            {/* <FaTrash
                style={{ cursor: "pointer", height: "2rem", width: "2rem" }}
                onClick={e => this.clearImage()}
              /> */}
          </div>

          <Form className="promo">
            <div>
              <Form.Group as={Row} controlId="exampleForm.ControlTextarea1">
                <Form.Label column sm="2.5" className="fontPromotion">
                  ชื่อหัวข้อ :
                  </Form.Label>
                <Col>
                  <Form.Control
                    onChange={this.handleChange}
                    name="name_pro"
                    value={this.state.name_pro}
                    type="text"
                    placeholder="ชื่อหัวข้อ"
                  />
                </Col>
              </Form.Group>
            </div>
            <div>
              <Form.Group as={Row} controlId="exampleForm.ControlTextarea1">
                <Form.Label column sm="2.5" className="fontPromotion">
                  คำอธิบาย :
                  </Form.Label>
                <Col>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    placeholder="เขียนคำอธิบาย"
                    name="des_pro"
                    onChange={this.handleChange}
                    value={this.state.des_pro}
                  />
                </Col>
              </Form.Group>
            </div>
            <div>
              <Form.Group as={Row} controlId="exampleForm.ControlTextarea1">
                <Form.Label column sm="2.5" className="fontPromotion">
                  ชื่อหัวข้อรายละเอียด :
                  </Form.Label>
                <Col>
                  <Form.Control
                    onChange={this.handleChange}
                    value={this.state.head_des_promo}
                    name="head_des_promo"
                    type="text"
                    placeholder="ชื่อหัวข้อ"
                  />
                </Col>
              </Form.Group>
            </div>
            <div>
              <Form.Group as={Row} controlId="exampleForm.ControlTextarea1">
                <Form.Label column sm="2.5" className="fontPromotion">
                  รายละเอียด :
                  </Form.Label>
                <Col>
                  <div style={{ border: "1px solid #ddd" }}>
                    <SunEditor setOptions={{
                      height: 200,
                      buttonList: buttonList.complex // Or Array of button list, eg. [['font', 'align'], ['image']]
                      // Other option
                    }}
                      onImageUploadBefore={this.handleImageUploadBefore()}
                      onImageUpload={this.handleImageUpload()}
                      onPaste={this.handlePaste()}
                      onImageUploadError={this.handleImageUploadError()}
                      setContents={this.state.content}
                      onChange={this.onEditorStateChange} />
                  </div>
                </Col>
              </Form.Group>
            </div>
          </Form>

          <div
            style={{
              paddingLeft: "15rem",
              paddingRight: "15rem",
              justifyContent: "center",
              display: "flex"
            }}
          >
            <button
              className="rcornerspromo1"
              onClick={() => this.handleSubmit()}
            >
              {this.state.isExampleLoading && <Spinner animation="border" variant="primary" />}
                บันทึก
              </button>
          </div>
        </div>
      </div>
    );
  }
}
