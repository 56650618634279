import React, { Component } from 'react'
import { _ip, POST, GET } from '../../service'
import '../../Asset/scss/Promotions.scss'
import Slider from "react-slick";

const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [{
        breakpoint: 1480,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
        }
    },
    {
        breakpoint: 1280,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2
        }
    },
    {
        breakpoint: 780,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false
        }
    }
    ]
};
export default class PromotionDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            promotion: {
                promo: {
                    source_pro: ''
                },
                detail: {
                    head_des_promo: '',
                    des_promo: ''
                },
            },
            promotionall: [],
        }
    }
    componentDidMount() {
        this.getPromotion()
        this.getPromotionall()
    }
    async getPromotionall() {
        try {
            let res = await GET('promo_detail')
            this.setState({ promotionall: res })
            // console.log('getPromotion', res)
        } catch (error) {
            console.log('getPromotion err', error)
        }
    }
    async getPromotion() {
        let id_pro = this.props.match.params.proId
        try {
            let res = await POST('promotion_detail_id', { id_pro })
            this.setState({ promotion: res })

        } catch (error) {
            console.log('getPromotion err', error)
        }
    }
    render() {
        let { promotion, promotionall } = this.state
        return (
            <React.Fragment>
                <div className="header header-75">
                    <div className="header-title">โปรโมชันพิเศษ</div>
                </div>
                <div className="promotion_dt">
                    <div className="promotion_dt-title">{promotion.detail ? promotion.detail.head_des_promo : ''}</div>
                    <div className="promotion_dt-img">
                        <img src={_ip + '/' + promotion.promo.source_pro} alt="" />
                    </div>
                    <div className='promotion_dt-content' dangerouslySetInnerHTML={{ __html: promotion.detail ? promotion.detail.des_promo : '' }}></div>
                </div>
                <div className="home-content-slide plus-100">
                    <div className="home-content-slide-title">
                        <strong>ซุปเปอร์โปร!</strong><br />
                        <span>พบกับโปรโมชันพิเศษมากมาย ที่คัดสรรมาสำหรับลูกค้าคนสำคัญ</span>
                    </div>
                    <Slider {...settings}>
                        {promotionall.map((e, i) =>
                            <div className="slide-promotion" key={i} onClick={() => window.location.assign(`/promotion/${e.id_pro}/${encodeURIComponent(e.name_pro.replace(/ /g, '-'))}`)} >
                                <div className="slide-promotion-thumb">
                                    <div className="slide-promotion-thumb-img">
                                        <img src={_ip + "/" + e.source_pro} alt="" />
                                    </div>
                                    <div className="slide-promotion-thumb-content">
                                        <span>{e.name_pro}</span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Slider>
                </div>
            </React.Fragment>
        )
    }
}
