import React, { Component } from 'react'

import Select from './Select'
import '../../Asset/scss/Products.scss'
import { GET, _ip, POST } from '../../service'


export default class Selectcar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            secondCar: [],
            searchfile: [],
            searchPrice: {
                min: 0,
                max: 99999999
            }
        }
    }
    componentDidMount = async () => {
        try {
            let secondCar = await GET("second_detail");

            this.setState({
                secondCar: secondCar
            })

        } catch (error) {
        }
    }
    handleChange = (action, value) => {
        let { searchPrice } = this.state;
        searchPrice[action] = value;
        this.setState({
            searchPrice,
        });
    };
    onSubmitSearch = async () => {
        let searchfile = await POST("get_price_between", this.state.searchPrice)
        this.setState({
            secondCar: searchfile
        })
    }
    render() {
        const { secondCar } = this.state
        return (

            <React.Fragment>
                <div className="header">
                    <div className="header-title">Guarantee used car</div>
                </div>
                <Select
                    handleChange={this.handleChange}
                    onSubmitSearch={this.onSubmitSearch} />
                <div className="product-car row">
                    {secondCar.map((e, i) =>
                        <div className="col-xl-3 col-lg-4 col-sm-6 col-12">
                            <div className="product-car-thumb" key={i}>
                                <div className="product-car-thumb-img">
                                    <img src={_ip + '/' + e.source_second} alt="" />
                                </div>
                                <div className="product-car-thumb-content">
                                    <strong>{e.name_second}</strong>

                                    <p> <span>{e.price_second.toLocaleString()} บาท</span></p>
                                    <button className="btn" onClick={() => window.location.assign(`/other/guaranteeusedcar/${e.id_second}/${e.name_second.replace(/ /g, '-').toLowerCase()}`)}>ดูรายละเอียด</button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </React.Fragment>

        )
    }
}
