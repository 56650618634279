import React, { Component } from 'react'
import { _ip, GET } from '../service'
import { Carousel } from "react-bootstrap";
import Slider from "react-slick";
// import moment from 'moment'
// import 'moment/locale/th'
import '../Asset/scss/Home.scss'

const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [{
        breakpoint: 1480,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
        }
    },
    {
        breakpoint: 1280,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2
        }
    },
    {
        breakpoint: 780,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false
        }
    }
    ]
};

export default class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            banner: [],
            car: [],
            promotion: [],
            news: []
        }
    }
    componentDidMount() {
        this.getBanner()
        this.getCar()
        this.getPromotion()
        this.getNews()
    }
    async getBanner() {
        try {
            let res = await GET('banner_detail')
            this.setState({ banner: res })
            console.log('getBanner', res)
        } catch (error) {
            console.log('getBanner err', error)
        }
    }
    async getCar() {
        try {
            let res = await GET('car_detail')
            this.setState({ car: res })
            // console.log('getCar', res)
        } catch (error) {
            console.log('getCar err', error)
        }
    }
    async getPromotion() {
        try {
            let res = await GET('promo_detail')
            this.setState({ promotion: res })
            // console.log('getPromotion', res)
        } catch (error) {
            console.log('getPromotion err', error)
        }
    }
    async getNews() {
        try {
            let res = await GET('news_detail')
            this.setState({ news: res })

        } catch (error) {
            console.log('getNews err', error)
        }
    }
    render() {
        let state = this.state
        return (
            <div className="home">
                <div className="home-banner desktop">
                    {state.banner.length > 0 && <Carousel
                        interval={6000}
                        nextIcon={<i className="fas fa-chevron-right"></i>}
                        prevIcon={<i className="fas fa-chevron-left"></i>}
                    >
                        {state.banner.map((e, index) =>
                            <Carousel.Item key={index} >
                                <img src={_ip + "/" + e.source_banner} alt="" />
                            </Carousel.Item>
                        )}
                    </Carousel>}
                </div>
                <div className="home-banner mobile">
                    {state.banner.length > 0 && <Carousel
                        interval={6000}
                        nextIcon={<i className="fas fa-chevron-right"></i>}
                        prevIcon={<i className="fas fa-chevron-left"></i>}
                    >
                        {state.banner.length > 0 && state.banner.map((e, index) =>
                            <Carousel.Item key={index} >
                                <img src={_ip + "/" + e.source_banner_mobile} alt="" />
                            </Carousel.Item>
                        )}
                    </Carousel>}
                </div>
                {/* <div className="home-service">
                    <div className="home-service-list">
                        <div>
                            <i className="far fa-comment-alt" /><br />
                            <strong>ติดต่อรับบริการ</strong>
                        </div>
                        <div>
                            <img src={require('../Asset/images/home/contact_home.jpg')} alt="" />
                        </div>
                    </div>
                    <div className="home-service-list white">
                        <div>
                            <i className="fas fa-key" /><br />
                            <strong>ทดลองขับรถยนต์</strong>
                        </div>
                        <div>
                            <img src={require('../Asset/images/home/test_home.jpg')} alt="" />
                        </div>
                    </div>
                    <div className="home-service-list black">
                        <div>
                            <i className="fas fa-clipboard-list" /><br />
                            <strong>ต่อประกันภัย</strong>
                        </div>
                        <div>
                            <img src={require('../Asset/images/home/insurance_home.jpeg')} alt="" />
                        </div>
                    </div>
                </div> */}
                <div className="home-content-title">
                    <strong>"มิตซูอุดร"</strong><br />
                    <span className="font-2">เราบริการด้วยใจ ให้คุณมากว่ารถยนต์</span>
                </div>
                <div className="home-content-slide">
                    <div className="home-content-slide-title">
                        <strong>รุ่นรถยนต์</strong>
                    </div>
                    <Slider {...settings}>
                        {state.car.map((e, i) =>
                            <div className="slide-car" key={i} onClick={() => window.location.assign(`/products/${e.id_car}/${e.name_car.replace(/ /g, '-').toLowerCase()}`)}>
                                <div className="slide-car-thumb">
                                    <div className="slide-car-thumb-img">
                                        <img src={_ip + "/" + e.source_car} alt="" />
                                    </div>
                                    <div className="slide-car-thumb-content">
                                        <strong>{e.name_car}</strong>
                                        <span>เริ่มต้นที่ {e.start_price.toLocaleString()} บาท</span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Slider>
                </div>
                <div className="home-content-slide plus-100">
                    <div className="home-content-slide-title">
                        <strong>ซุปเปอร์โปร!</strong><br />
                        <span>พบกับโปรโมชันพิเศษมากมาย ที่คัดสรรมาสำหรับลูกค้าคนสำคัญ</span>
                    </div>
                    <Slider {...settings} >
                        {state.promotion.map((e, i) =>
                            <div className="slide-promotion" key={i} onClick={() => window.location.assign(`/promotion/${e.id_pro}/${encodeURIComponent(e.name_pro.replace(/ /g, '-'))}`)}>
                                <div className="slide-promotion-thumb">
                                    <div className="slide-promotion-thumb-img">
                                        <img src={_ip + "/" + e.source_pro} alt="" />
                                    </div>
                                    <div className="slide-promotion-thumb-content">
                                        <span>{e.name_pro}</span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Slider>
                </div>
                <div className="home-content-slide plus-100">
                    <div className="home-content-slide-title">
                        <strong>ข่าวสารและกิจกรรม</strong><br />
                        <span>ทันทุกข่าวสารและกิจกรรม ที่สร้างมาเพื่อลูกค้าคนพิเศษ</span>
                    </div>
                    <Slider {...settings}>
                        {state.news.map((e, i) =>
                            <div className="slide-promotion" key={i} onClick={() => window.location.assign(`/news/${e.id_news}/${e.name_news.replace(/ /g, '-')}`)}>
                                <div className="slide-promotion-thumb">
                                    <div className="slide-promotion-thumb-img">
                                        <img src={_ip + "/" + e.source_news} alt="" />
                                    </div>
                                    <div className="slide-promotion-thumb-content">
                                        <span>{e.name_news}{e.des_news}</span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Slider>
                </div>
                <div class="fb-customerchat"
                    page_id="1497377370530989"
                    theme_color="#0084ff"
                    logged_in_greeting="มิตซูอุดร ยินดีให้บริการ"
                    logged_out_greeting="มิตซูอุดร ยินดีให้บริการ">
                </div>
            </div>
        )
    }
}
