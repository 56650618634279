import React, { Component } from "react";
import {
  Row,
  Col,
  Form,
  FormControl, Spinner
} from "react-bootstrap";
import pic from "../../Asset/img/pic.png";
import { POST, _ip } from "../../service";
import Icons from '../../Component/Icons/Icons'
import Swal from "sweetalert2";
export default class Add_car extends Component {
  constructor(props) {

    super(props);
    this.state = {
      picCar: [],
      banner: "",
      fileshowCar: {},
      fileBanner: {},
      filePicCar: [],
      index: 0,
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      showCar: "",
      secondInfo: {
        name_second: "",
        source_second: "",
        des_second_car: "",
        price_second: "",
        header_des_second_car: ""
      },
      buffer: {
        picCarImg: ""
      },
      isExampleLoading: false
    };
  }
  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

  onClose = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };

  handleChange = (action, value) => {
    let { secondInfo } = this.state;
    // let { banSecondInfo} = this.state;
    // let { albumSecondInfo} = this.state;
    secondInfo[action] = value;
    // albumSecondInfo[action] = value;
    // banSecondInfo[action] = value;
    this.setState({
      secondInfo
      // albumSecondInfo,
      // banSecondInfo
    });
  };

  handleSubmit = async () => {
    const formData = new FormData();
    let { fileshowCar, fileBanner, filePicCar, secondInfo } = this.state;
    await formData.append("myImage", fileshowCar);
    await formData.append("myImage", fileBanner);
    filePicCar.forEach(async el => {
      await formData.append("myImage", el);
    });
    formData.append("name_second", secondInfo.name_second);
    formData.append("des_second_car", secondInfo.des_second_car);
    formData.append("price_second", secondInfo.price_second);
    formData.append("header_des_second_car", secondInfo.header_des_second_car);

    try {

      Swal.fire({
        title: 'คุณต้องการเพิ่มข้อมูลใช่หรือไม่?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'cancel'
      }).then(async (result) => {
        if (result.value) {
          try {
            this.setState({ isExampleLoading: true })
            let check = await POST("/create_second", formData, true);
            Swal.fire(
              'เพิ่มข้อมูลสำเร็จ',
              '',
              'success'

            ).then(() => {

              this.props.toggle("1");
              this.props.fetch();
              this.setState({
                picCar: [],
                banner: "",
                fileshowCar: {},
                fileBanner: {},
                filePicCar: [],
                index: 0,
                showingInfoWindow: false,
                activeMarker: {},
                selectedPlace: {},
                showCar: "",
                secondInfo: {
                  name_second: "",
                  source_second: "",
                  des_second_car: "",
                  price_second: "",
                  header_des_second_car: ""
                },
                buffer: {
                  picCarImg: ""
                },
              });
            })
          } catch (error) {
            Swal.fire(
              'กรุณากรอกข้อมูลให้ครบ',
              '',
              'warning'
            )
          }
        }
        this.setState({

          isExampleLoading: false,

        });
      })
    } catch (error) {
      Swal.fire(
        'กรุณากรอกข้อมูลให้ครบ',
        '',
        'warning'
      )
    }
  };

  handleshowCarImg = selectorFiles => {
    if (selectorFiles.length > 0) {
      var file = selectorFiles[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.setState({
          showCar: reader.result,
          fileshowCar: selectorFiles[0]
        });
      };
      reader.onerror = function (error) {
      };
    } else {
      this.setState({
        showCar: ""
      });
    }
  };

  clearshowCarImg = () => {
    this.setState({
      showCar: ""
    });
  };

  handleBannerImg = selectorFiles => {
    if (selectorFiles.length > 0) {
      var file = selectorFiles[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.setState({
          banner: reader.result,
          fileBanner: file
        });
      };
      reader.onerror = function (error) {
      };
    } else {
      this.setState({
        banner: ""
      });
    }
  };

  clearBannerImg = () => {
    this.setState({
      banner: ""
    });
  };

  handleCarImg = event => {
    let selectorFiles = event.target.files;
    if (selectorFiles.length > 0) {
      var file = selectorFiles[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let { buffer, filePicCar } = this.state;
        buffer.picCarImg = reader.result;
        filePicCar.push(file);
        this.setState({
          buffer: buffer,
          filePicCar: filePicCar
        });
      };
      reader.onerror = function (error) {
      };
    }
  };

  removeCarImg = (e, index) => {
    e.preventDefault();
    let { picCar } = this.state;
    picCar.splice(index, 1);
    this.setState({
      picCar: picCar
    });
  };

  onSubmit = e => {
    e.preventDefault();
    let picCar = this.state.picCar;
    picCar.push(this.state.buffer);
    this.setState({
      picCar,
      buffer: { picCarImg: "" }
    });
  };

  clearBufferImg = () => {
    let { buffer } = this.state;
    buffer.picCarImg = "";
    this.setState({
      buffer: buffer
    });
  };

  onClickUpload = async () => { };

  render() {
    let { buffer, picCar, fileBanner, fileshowCar, filePicCar } = this.state;
    return (
      <div>
        <div>
          <Form
            className="car"
          >
            <Form.Group as={Row} controlId="exampleForm.ControlTextarea1">
              <Form.Label
                column
                sm="2.5"
                style={{ fontSize: "16px", fontWeight: "bold" }}
              >
                ชื่อรถ :
            </Form.Label>
              <Col>
                <Form.Control
                  onChange={e => this.handleChange("name_second", e.target.value)}
                  value={this.state.secondInfo.name_second}
                  type="text"
                  placeholder="ชื่อรถ"
                />
              </Col>
            </Form.Group>
          </Form>

          <Row style={{ padding: "1rem" }}>
            <FormControl
              style={{ display: "none" }}
              id="someone"
              type="file"
              onChange={e => this.handleshowCarImg(e.target.files)}
            />
            <div
              style={{
                margin: "auto",
                width: "50rem",
                height: "400px",
                backgroundColor: "#eee",
                position: "relative"
              }}
              hidden={this.state.showCar !== ""}
            >
              <label for="someone">
                <div
                  className="border-banner text-center"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    style={{ width: "200px", height: "200px", margin: "auto" }}
                    src={pic}
                  />
                    เลือกรูปรถโชว์หน้าแรก
              </div>
              </label>
            </div>

            <div style={{ margin: "auto" }} hidden={this.state.showCar === ""}>
              <img
                width={1000}
                height={400}
                src={this.state.showCar}
                style={{ width: "auto", height: '400px' }}
              />
            </div>
          </Row>
          <div
            style={{
              paddingLeft: "15rem",
              paddingRight: "15rem",
              justifyContent: "center",
              display: "flex"
            }}
          >
            <img className="imgdelete"
              src={Icons.IconDelete}
              style={{
                cursor: "pointer", textAlign: "center", height: "2rem",
                width: "2rem"
              }}
              onClick={e => this.clearshowCarImg()}
            />
            {/* <FaTrash
                style={{ cursor: "pointer", height: "2rem", width: "2rem" }}
                onClick={e => this.clearshowCarImg()}
              /> */}
          </div>
          <Row style={{ padding: "1rem" }}>
            <FormControl
              style={{ display: "none" }}
              id="some"
              type="file"
              onChange={e => this.handleBannerImg(e.target.files)}
            />
            <div
              style={{
                margin: "auto",
                width: "50rem",
                height: "400px",
                backgroundColor: "#eee",
                position: "relative"
              }}
              hidden={this.state.banner !== ""}
            >
              <label for="some">
                <div
                  className="border-banner text-center"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    style={{ width: "200px", height: "200px", margin: "auto" }}
                    src={pic}
                  />
                    เลือกBanner
              </div>
              </label>
            </div>

            <div style={{ margin: "auto" }} hidden={this.state.banner === ""}>
              <img
                width={1000}
                height={400}
                src={this.state.banner}
                style={{ width: "auto", height: '400px' }}
              />
            </div>
          </Row>

          <div
            style={{
              paddingLeft: "15rem",
              paddingRight: "15rem",
              justifyContent: "center",
              display: "flex"
            }}
          >
            <img className="imgdelete"
              src={Icons.IconDelete}
              style={{
                cursor: "pointer", textAlign: "center", height: "2rem",
                width: "2rem"
              }}
              onClick={e => this.clearBannerImg()}
            />
            {/* <FaTrash
                style={{ cursor: "pointer", height: "2rem", width: "2rem" }}
                onClick={e => this.clearBannerImg()}
              /> */}
          </div>

          <Form
            className="car"
          >
            <div>
              <Form.Group as={Row} controlId="exampleForm.ControlTextarea1">
                <Form.Label
                  column
                  sm="2.5"
                  style={{ fontSize: "16px", fontWeight: "bold" }}
                >
                  ชื่อหัวข้อ :
              </Form.Label>
                <Col>
                  <Form.Control
                    onChange={e =>
                      this.handleChange("header_des_second_car", e.target.value)
                    }
                    value={this.state.secondInfo.header_des_second_car}
                    type="text"
                    placeholder="ชื่อหัวข้อ"
                  />
                </Col>
              </Form.Group>
            </div>
            <div>
              <Form.Group as={Row} controlId="exampleForm.ControlTextarea1">
                <Form.Label
                  column
                  sm="2.5"
                  style={{ fontSize: "16px", fontWeight: "bold" }}
                >
                  คำอธิบาย :
              </Form.Label>
                <Col>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    placeholder="เขียนคำอธิบาย"
                    onChange={e =>
                      this.handleChange("des_second_car", e.target.value)
                    }
                    value={this.state.secondInfo.des_second_car}
                  />
                </Col>
              </Form.Group>
            </div>
            <Form.Group as={Row} controlId="exampleForm.ControlTextarea1">
              <Form.Label
                column
                sm="2.5"
                style={{ fontSize: "16px", fontWeight: "bold" }}
              >
                ราคา :
            </Form.Label>
              <Col>
                <Form.Control
                  onChange={e =>
                    this.handleChange("price_second", e.target.value)
                  }
                  value={this.state.secondInfo.price_second}
                  type="text"
                  placeholder="ราคา"
                />
              </Col>
            </Form.Group>
          </Form>

          {/* เพิ่มรูปรถ */}
          <Row
            style={{ padding: "1rem", display: "flex", justifyContent: "center" }}
          >
            {picCar.length !== 0 &&
              picCar.map((el, i) => (
                <Col style={{ margin: "1rem 0 1rem 0" }}>
                  <div
                    style={{
                      margin: "auto",
                      width: "50rem",
                      height: "400px",
                      backgroundColor: 'transparent',
                      position: "relative"
                    }}
                  >
                    <div style={{ margin: "auto", height: '400px' }}>
                      <img
                        src={el.picCarImg}
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      padding: "1rem",
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    <button
                      className="rcornersaddproduct"
                      onClick={e => this.removeCarImg(e, i)}
                    >
                      ลบ
                  </button>
                  </div>
                </Col>
              ))}
            <Col>
              <FormControl
                style={{ display: "none" }}
                id="so"
                type="file"
                accept="image/x-png,image/jpeg"
                onChange={this.handleCarImg}
              />
              <div
                style={{
                  margin: "auto",
                  width: "50rem",
                  height: "400px",
                  backgroundColor: "#eee",
                  position: "relative"
                }}
                hidden={buffer.picCarImg !== ""}
              >
                <label for="so">
                  <div
                    className="border-banner text-center"
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      style={{ width: "200px", height: "200px", margin: "auto" }}
                      src={pic}
                    />
                      เลือกรูปรถ
                </div>
                </label>
              </div>

              <div
                style={{
                  margin: "auto",
                  justifyContent: "center",
                  display: "flex"
                }}
                hidden={buffer.picCarImg === ""}
              >
                <img
                  width={1000}
                  height={400}
                  src={buffer.picCarImg}
                  style={{ width: "auto", height: '400px' }}
                />
              </div>
              <div
                style={{
                  padding: "1rem",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <button className="rcornersaddproduct" onClick={this.onSubmit}>
                  เพิ่ม
              </button>
                <button
                  className="rcornersaddproduct"
                  onClick={e => this.clearBufferImg()}
                >
                  ลบ
              </button>
              </div>
            </Col>
          </Row>

          <div
            style={{
              paddingLeft: "15rem",
              paddingRight: "15rem",
              justifyContent: "center",
              display: "flex"
            }}
          >
            {/* <Button variant="dark" onClick={() => this.handleSubmit()}>
                      {this.state.isExampleLoading && (
                        <Spinner animation="border" variant="primary" />
                      )}
                      &nbsp;บันทึก
                    </Button> */}
            <button className="rcornerscar1" onClick={this.handleSubmit}>
              {this.state.isExampleLoading && <Spinner animation="border" variant="primary" />}
                บันทึก
          </button>
          </div>
        </div>
        {/* </Col>
                </Row> */}

      </div >
    );
  }
}

