import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import { GET } from './service'
import NavbarAdmin from './Component/SidebarAdmin/NavbarAdmin'
import SidebarAdmin from './Component/SidebarAdmin/SidebarAdmin'
import Responsive from 'react-responsive';
// import Icons from './Component/Icons/Icons'
// import './Asset/scss/MainStyle.scss'
import './Asset/scss/PageAdminStyle.scss'
import icon_danger from './Asset/img/icon-danger.png'
const Desktop = props => <Responsive {...props} minWidth={768} />;
const Mobile = props => <Responsive {...props} maxWidth={767} />;
class AppAdmin extends Component {
    constructor(props) {
        super(props)
        this.state = {
            total_user: 0,
            total_news: 0,
            total_advertise: 0
        }
    }


    render() {
        let { total_user, total_news, total_advertise } = this.state
        let pathname = window.location.pathname
        return (
            <React.Fragment>

                <div className="AppAdmin">
                    <Desktop>
                        <SidebarAdmin />
                        <div className="set-page-admin">
                            <NavbarAdmin />
                            <div className="element-body">
                                {this.props.children}
                            </div>
                        </div>
                    </Desktop>
                    <Mobile>
                        <div
                            className="mobilesize"
                        >
                            <div
                                className="danger"
                            >
                                <Row
                                    style={{ justifyContent: "center", padding: "1rem 0 2rem 0" }}
                                >
                                    <img src={icon_danger} width="100vw" height="auto" />
                                    <p />
                                </Row>
                                <Row>
                                    <center>ไม่รองรับการทำงานผ่าน Mobile</center>
                                </Row>
                            </div>
                        </div>
                    </Mobile>
                </div>
            </React.Fragment>
        )
    }
}

export default AppAdmin
