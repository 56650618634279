import React, { Component } from 'react'
import { _ip, GET } from '../../service'
import '../../Asset/scss/Promotions.scss'
// import Pagination from 'react-bootstrap/Pagination'
import Pagination from '../../Component/Pagination/Pagination'
export default class Promotios extends Component {
    constructor(props) {
        super(props)
        this.state = {
            promotion: [],
            currentPromotion: [],
            currentPage: null,
            totalPages: null
        }
    }
    componentDidMount() {
        this.getPromotion()
    }
    async getPromotion() {
        try {
            let res = await GET('promo_detail')
            this.setState({ promotion: res })

        } catch (error) {
            console.log('getPromotion err', error)
        }
    }
    onPageChanged = data => {
        const { promotion } = this.state;
        const { currentPage, totalPages, pageLimit } = data;

        const offset = (currentPage - 1) * pageLimit;
        const currentPromotion = promotion.slice(offset, offset + pageLimit);

        this.setState({ currentPage, currentPromotion, totalPages });
    }
    render() {

        const { promotion, currentPromotion, currentPage, totalPages } = this.state;
        const totalPromotion = promotion.length;

        if (totalPromotion === 0) return null;
        return (
            <React.Fragment>
                <div className="header header-white">
                    <div className="header-title">โปรโมชันพิเศษ</div>
                </div>
                <div className="promotion row">
                    {/* {
                    items.map((e, i) =>
                        <div className={`card-table ${classCard ? classCard : ''}`} key={i} onClick={() => onTableClick ? onTableClick(e, i) : null}>
                            {this.props.children.map((children, index) =>
                                <CardTableRow children={children} rowIndex={i} data={e} key={index} />
                            )}
                        </div>
                    )
                } */}

                    {currentPromotion.map((e, i) =>
                        <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-12 col-12">
                            <div className="promotion-thumb" key={i}>
                                <div className="promotion-thumb-img" onClick={() => window.location.assign(`/promotion/${e.id_pro}/${encodeURIComponent(e.name_pro.replace(/ /g, '-'))}`)}>
                                    <img src={_ip + '/' + e.source_pro} alt="" />
                                </div>
                                <div className="promotion-thumb-content">
                                    <strong>{e.name_pro}</strong>
                                    {e.des_pro && <p>{e.des_pro}</p>}
                                    <button className="btn" onClick={() => window.location.assign(`/promotion/${e.id_pro}/${encodeURIComponent(e.name_pro.replace(/ /g, '-'))}`)}><u>ดูรายละเอียด</u></button>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* {currentPromotion.map(country => <CountryCard key={country.cca3} country={country} />)} */}
                </div>
                <div className="promotion row">
                    <Pagination totalRecords={totalPromotion} pageLimit={8} pageNeighbours={1} onPageChanged={this.onPageChanged} />
                </div>
            </React.Fragment>
        )
    }
}
