
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import React, { Component } from "react";
import Search from "antd/lib/transfer/search";

export default class Select extends Component {

  render() {
    return (
      <Card>
        <div className="container ">
          <br />
          <Row>
            <Col md={4} lg={5}>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Control as="select" onChange={e => this.props.handleChange('min', e.target.value)}>
                  <option value={0}>ช่วงราคา</option>
                  <option value={500000}>500,000</option>
                  <option value={600000}>600,000</option>
                  <option value={700000}>700,000</option>
                  <option value={800000}>800,000</option>
                  <option value={900000}>900,000</option>
                  <option value={1000000}>1,000,000</option>
                  <option value={2000000}>2,000,000</option>
                  <option value={2500000}>2,500,000</option>
                </Form.Control>
              </Form.Group>
            </Col>{" "}
            <Col md={4} lg={5}>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Control as="select" onChange={e => this.props.handleChange('max', e.target.value)}>
                  <option value={99999999}>ถึง</option>
                  <option value={600000}>600,000</option>
                  <option value={700000}>700,000</option>
                  <option value={800000}>800,000</option>
                  <option value={900000}>900,000</option>
                  <option value={1000000}>1,000,000</option>
                  <option value={1100000}>1,100,000</option>
                  <option value={2100000}>2,100,000</option>
                  <option value={2600000}>2,600,000</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col md={4} lg={2}>
              <div className="buttoncar" href="selectcar" onClick={this.props.onSubmitSearch} >
                ค้นหารถยนต์{" "} </div>
            </Col>
          </Row>
        </div>

      </Card>
    );
  }
}
