import React, { Component } from 'react'
import '../../Asset/scss/Service.scss'

export default class Service extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="header header-25 header-white">
                    <div className="header-title">บริการหลังการขาย</div>
                </div>
                <div>
                    <div className="service-content-title">
                        <span>Genuine Service</span>
                        <span>Genuine Technician</span>
                        <span>Genuine Performance</span>
                        <span>Genuine Paint and Body Repair</span>
                        <span>Genuine used cars</span>
                    </div>
                    <div className="service-content-detail">
                        <div className="service-content-list">
                            <img src={require('../../Asset/images/service/service1.jpg')} alt="" />
                        </div>
                        <div className="service-content-list-border">
                            <div className="service-content-list-title">Genuine Service</div>
                            <p>คุณภาพสินค้า และบริการที่ดี<br />พร้อมบริการช่วยเหลือฉุกเฉิน<br />ตลอด 24 ชั่วโมง</p>
                        </div>
                    </div>
                    <div className="service-content-detail">
                        <div className="service-content-list">
                            <div className="service-content-list-title">Genuine Technician</div>
                            <p>ความเชี่ยาญที่เป็นของจริง<br />ของช่างที่ผ่านการฝึกฝน<br />จาก Education Academy</p>
                        </div>
                        <div className="service-content-list">
                            <img src={require('../../Asset/images/service/service2.JPG')} alt="" />
                        </div>
                    </div>
                    <div className="service-content-detail">
                        <div className="service-content-list">
                            <img src={require('../../Asset/images/service/service3.jpg')} alt="" />
                        </div>
                        <div className="service-content-list">
                            <div className="service-content-list-title">Genuine Performance</div>
                            <p>การดูแลและเข้าใจในสินค้า<br />และการบริการ<br />เพื่อให้ได้ที่สุดของความพึงพอใจ<br />และคุณภาพของรถยนต์ที่ยาวนาน</p>
                        </div>
                    </div>
                    <div className="service-content-detail">
                        <div className="service-content-list">
                            <div className="service-content-list-title">Genuine Paint and Body Repair</div>
                            <p>ครอบคลุมทุกงานซ่อมบำรุง<br />ซ่อมสีและตัวถังอย่างมืออาชีพ</p>
                        </div>
                        <div className="service-content-list">
                            <img src={require('../../Asset/images/service/service4.JPG')} alt="" />
                        </div>
                    </div>
                    <div className="service-content-detail">
                        <div className="service-content-list">
                            <img src={require('../../Asset/images/service/service6.jpg')} alt="" />
                        </div>
                        <div className="service-content-list-border">
                            <div className="service-content-list-title">Genuine used cars</div>
                            <p>มาตรฐานใหม่อีกระดับ<br />ของธุรกิจรถยนต์ใช้แล้ว<br />เพื่อเป็นการเพิ่มทางเลือกใหม่<br />ของลูกค้าด้วยรูปแบบธุรกิจ</p>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
