import React from "react";
import Test_driver from "../PageAdmin/people/People"
import Contact_service from "../PageAdmin/contact_admin/Contact_admin"
import Insurance from "../PageAdmin/insurance_admin/Insurance_admin"
import Banner from "../PageAdmin/banner_admin/Add_banner.js"
import News_admin from "../PageAdmin/news_admin/News_Admin"
import Product_admin from "../PageAdmin/Product&bro_admin/Product&bro_admin"
import Edit_product from "../PageAdmin/Product&bro_admin/Edit_product"
import Edit_car from "../PageAdmin/car_admin/Edit_car"
import Edit_promotion from "../PageAdmin/promotion_admin/Edit_promotion"
import Edit_new from "../PageAdmin/news_admin/Edit_new"
import Second_hand_car from "../PageAdmin/car_admin/Car_admin"
import Promotion_admin from "../PageAdmin/promotion_admin/Promotion_admin"

const admin_router = [
    {
        path: "/test_driver",
        exact: false,
        main: () => <Test_driver />
    },
    {
        path: "/contact_service",
        exact: false,
        main: () => <Contact_service />
    },
    {
        path: "/insurance",
        exact: false,
        main: () => <Insurance />
    },
    {
        path: "/banner",
        exact: false,
        main: () => <Banner />
    },
    {
        path: "/product_admin",
        exact: false,
        main: () => <Product_admin />
    },
    {
        path: "/second_hand_car",
        exact: false,
        main: () => <Second_hand_car />
    },
    {
        path: "/promotion_admin",
        exact: false,
        main: () => <Promotion_admin />
    },
    {
        path: "/news_admin",
        exact: false,
        main: () => <News_admin />
    }
    ,
    {
        path: "/edit_product/:id",
        exact: false,
        main: (props) => <Edit_product  {...props} />
    }
    ,
    {
        path: "/edit_car/:id",
        exact: false,
        main: (props) => <Edit_car  {...props} />
    }
    ,
    {
        path: "/edit_new/:id",
        exact: false,
        main: (props) => <Edit_new  {...props} />
    }
    ,
    {
        path: "/edit_promotion/:id",
        exact: false,
        main: (props) => <Edit_promotion  {...props} />
    }
]

export default admin_router