import React, { Component } from 'react'
import { Link } from 'react-router-dom'
// import Images from '../Images/Images'
import { Table, Row, Col } from "react-bootstrap";
import icon_danger from '../../Asset/img/icon-danger.png'
import Responsive from 'react-responsive';
const Mobile = props => <Responsive {...props} maxWidth={767} />;
const Desktop = props => <Responsive {...props} minWidth={768} />;
export default class SidebarAdmin extends Component {
    render() {
        let path = window.location.pathname
        return (
            <div>
                <Desktop>
                    <aside className="sidebar-admin">
                        <div>
                            {/* <img src={Images.LogoWeb} alt="" /> */}
                            <span>ADMIN</span>
                        </div>
                        <span>เมนู</span>
                        <Link className={path === '/test_driver' ? 'active' : ''} to={'/test_driver'}>ทดลองขับ</Link>
                        <Link className={path === '/contact_service' ? 'active' : ''} to={'/contact_service'} >นัดหมายเช็คระยะ</Link>
                        <Link className={path === '/insurance' ? 'active' : ''} to={'/insurance'} >ต่อประกัน</Link>
                        <Link className={path === '/banner' ? 'active' : ''} to={'/banner'} >แบนเนอร์</Link>
                        <Link className={path === '/product_admin' ? 'active' : ''} to={'/product_admin'} >รุ่นรถยนต์/โปรขัวร์</Link>
                        <Link className={path === '/second_hand_car' ? 'active' : ''} to={'/second_hand_car'} >รายการรถมือสอง</Link>
                        <Link className={path === '/promotion_admin' ? 'active' : ''} to={'/promotion_admin'} >โปรโมชั่น</Link>
                        <Link className={path === '/news_admin' ? 'active' : ''} to={'/news_admin'} >ข่าวสารและกิจกรรม</Link>

                    </aside>
                </Desktop>
                <Mobile>
                    <div></div>
                </Mobile>
            </div>

        )
    }
}
