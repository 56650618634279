import React, { Component } from "react";
import {
  Table
} from "reactstrap";

import Add_car from "./Add_car";

import { GET, POST, _ip } from "../../service";
import Swal from 'sweetalert2'
import { Tabs, Tab } from 'react-bootstrap'
import Icons from '../../Component/Icons/Icons'
import './carAdmin.scss'



export default class Car_admin extends Component {
  constructor(props) {

    super(props);
    this.toggle = this.toggle.bind(this);
    this.fetch = this.fetch.bind(this);
    this.state = {
      index: 0,
      secondInfo: [],
      activeTab: "1"
    };
  }
  fetch = async () => {
    try {
      let secondInfo = await GET("/second_detail");
      this.setState({
        secondInfo: secondInfo
      });

    } catch (error) {
    }
  };
  componentDidMount = async () => {
    this.fetch();
  };

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  deleteCar = async id_second => {
    Swal.fire({
      title: 'คุณต้องการลบใช่หรือไม่?',
      // text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then((result) => {
      if (result.value) {
        let del = POST("delete_second", { id_second });
        Swal.fire(
          'ลบข้อมูลสำเร็จ',
          '',
          'success'
          // 'Your file has been deleted.',
          // 'success'
        )
      }
      this.fetch();
    })
  };
  render() {
    const { secondInfo } = this.state;

    return (
      <div>

        <Tabs defaultActiveKey="รายการทั้งหมด" id="uncontrolled-tab-example" className="tab">
          <Tab eventKey="รายการทั้งหมด" title="รายการทั้งหมด">
            <div className="rcornerscontact" style={{ color: "rgb(249, 0, 1)", marginTop: '10px' }}>รายการทั้งหมด</div>
            <Table striped bordered hover>
              <thead>
                <tr className="Fontadmin">
                  <th style={{ width: "2rem" }}>ลำดับ</th>
                  <th>ชื่อ</th>
                  <th style={{ width: "12rem" }}> ภาพ</th>
                  <th style={{ width: "8rem" }}>Function</th>
                </tr>
              </thead>
              {secondInfo.length > 0
                ? secondInfo.map((index, i) => (
                  <tbody key={index.id_second}>
                    <tr style={{ textAlign: "center" }}>
                      <td>{i + 1}</td>
                      <td>{index.name_second}</td>
                      <td style={{ display: "", margin: "auto" }}>
                        <div>
                          <img
                            src={_ip + "/" + index.source_second}
                          />
                        </div>
                      </td>
                      <td className="function-car">
                        <div >

                          <a href={`/edit_car/${index.id_second}`}>
                            <i class="far fa-edit">
                            </i>
                          </a>
                        </div>
                        <div>
                          <a className="trash">
                            <img className="imgdelete"
                              src={Icons.IconDelete}
                              style={{
                                cursor: "pointer", textAlign: "center", height: "2rem",
                                width: "2rem"
                              }}
                              onClick={e => this.deleteCar(index.id_second)}
                            />
                            {/* <FaTrash
                                  onClick={() => this.deleteCar(index.id_second)}
                                /> */}
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ))
                : null}
            </Table>
          </Tab>
          <Tab eventKey="เพิ่มรถยนต์มือสอง" title="เพิ่มรถยนต์มือสอง">
            <div className="rcornerscontact" style={{ color: "rgb(249, 0, 1)", marginTop: '10px' }}>เพิ่มรถยนต์มือสอง</div>
            <Add_car toggle={this.toggle} fetch={this.fetch} />
          </Tab>
        </Tabs>
      </div >
    );
  }
}


