import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  FormControl, Spinner
} from "react-bootstrap";

import pic from "../../Asset/img/pic.png";
import Responsive from "react-responsive";
import icon_danger from "../../Asset/img/icon-danger.png";
import { GET, POST, _ip } from "../../service";
import swal from "sweetalert";
import Swal from "sweetalert2";





export default class Edit_car extends Component {
  constructor(props) {
    super(props);
    this.state = {
      picCar: [],
      banner: "",
      fileshowCar: {},
      fileBanner: {},
      file_source: [],
      filePicCar: [],
      index: 0,
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      isExampleLoading: false,
      showCar: "",
      secondInfo: {
        name_second: "",
        source_second: "",
        des_second_car: "",
        price_second: "",
        header_des_second_car: ""
      },
      buffer: {
        source: ""
      },
      splice: []
    };
  }
  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

  onClose = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };
  handleChange = ({ target: { value, name } }) =>
    this.setState(
      { [name]: value },
    );

  componentDidMount = async () => {
    let { id } = this.props.match.params

    let id_second = id


    try {

      let car_second = await POST("car_second", { id_second });

      this.setState({
        name_second: car_second.detail.name_second,
        showCar: car_second.detail.source_second,
        des_second_car: car_second.detail.des_second_car,
        price_second: car_second.detail.price_second,
        header_des_second_car: car_second.detail.header_des_second_car
      });

      this.setState({
        picCarImg: car_second.album
      });


      this.setState({
        banner: car_second.banner.source_ban_second
      });

    } catch (error) { }
  };
  handleSubmit = async () => {
    let changeIndex = []
    if (typeof this.state.fileshowCar.name == 'string') {
      changeIndex.push("fileshowCar")
    } if (typeof this.state.fileBanner.name == 'string') {
      changeIndex.push("fileBanner")
    }
    //  if (typeof this.state.file_source.name == 'string') {
    //   changeIndex.push("file_source")
    // }

    const { name_second, des_second_car, price_second, header_des_second_car } = this.state;
    const formData = new FormData();
    if (name_second && des_second_car && price_second && header_des_second_car) {
      formData.append("id_second", this.props.match.params.id);
      await formData.append("myImage", this.state.fileshowCar);
      await formData.append("myImage", this.state.fileBanner);

      await formData.append("changeIndex", JSON.stringify(changeIndex))

      formData.append("name_second", this.state.name_second);
      formData.append("des_second_car", this.state.des_second_car);
      formData.append("price_second", this.state.price_second);
      formData.append("header_des_second_car", this.state.header_des_second_car);

      try {
        Swal.fire({
          title: 'คุณต้องกาแก้ไขข้อมูลใช่หรือไม่?',
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          cancelButtonText: 'cancel'
        }).then(async (result) => {
          if (result.value) {
            try {
              this.setState({ isExampleLoading: true })
              let check = await POST("update_second", formData, true)

              formData.delete("myImage");

              this.state.file_source.forEach(async (e) => {
                await formData.append("album", e);
              })

              this.state.splice.forEach(async el => {
                await formData.append("splice", el)
              })
              let check2 = await POST("update_album_second", formData, true)
              Swal.fire(
                'แก้ไขข้อมูลสำเร็จ',
                '',
                'success'
              ).then(() => {
                this.props.history.push("/second_hand_car")
              })
            } catch (error) {
              swal({
                title: "กรุณากรอกข้อมูลให้ครบ",
                icon: "warning"
              });
            }
          }
          // this.props.fetch();
          this.setState({
            showingInfoWindow: false,
          });
        })


      } catch (error) {
      }
    } else {
      swal({
        title: "กรุณากรอกข้อมูลให้ครบ",
        icon: "warning"
      });
    }
  };
  //   try {
  //     let check = await POST("/update_second", formData, true);
  //     if (check.message === "success") {
  //       swal({
  //         title: "บันทึกสำเร็จ",
  //         icon: "success"
  //       }).then(() => {
  //         this.props.history.push("/car_admin")
  //       })
  //     } else {
  //       swal({
  //         title: "กรุณากรอกข้อมูลให้ครบ",
  //         icon: "warning"
  //       });
  //     }
  //   } catch (error) {
  //   }
  // };

  handleshowCarImg = selectorFiles => {
    if (selectorFiles.length > 0) {
      var file = selectorFiles[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.setState({
          showCar: reader.result,
          fileshowCar: selectorFiles[0]
        });
      };
      reader.onerror = function (error) {
      };
    } else {
      this.setState({
        showCar: ""
      });
    }
  };

  clearshowCarImg = () => {
    this.setState({
      showCar: ""
    });
  };

  handleBannerImg = selectorFiles => {
    if (selectorFiles.length > 0) {
      var file = selectorFiles[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.setState({
          banner: reader.result,
          fileBanner: file
        });
      };
      reader.onerror = function (error) {
      };
    } else {
      this.setState({
        banner: ""
      });
    }
  };

  clearBannerImg = () => {
    this.setState({
      banner: ""
    });
  };

  handleCarImg = event => {
    let selectorFiles = event.target.files;
    if (selectorFiles.length > 0) {
      var file = selectorFiles[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let { buffer, filePicCar, file_source } = this.state;
        buffer.source = reader.result;
        file_source.push(file)
        filePicCar.push(file);
        this.setState({
          buffer: buffer,
          filePicCar: filePicCar,
          file_source: file_source
        });
      };
      reader.onerror = function (error) {
      };
    }
  };

  removeCarImg = (e, index) => {
    e.preventDefault();
    let { picCarImg, file_source, splice } = this.state;
    splice.push(picCarImg[index].id)
    picCarImg.splice(index, 1);
    file_source.splice(index, 1);
    this.setState({
      picCarImg: picCarImg,
      file_source: file_source,
      splice
    });
  };

  onSubmit = e => {
    e.preventDefault();
    let { picCarImg, file_source } = this.state;
    picCarImg.push(this.state.buffer);
    this.setState({
      picCarImg,
      buffer: { source: "" }
    });
  };

  clearBufferImg = () => {
    let { buffer } = this.state;
    buffer.source = "";
    this.setState({
      buffer: buffer
    });
  };

  onClickUpload = async () => { };

  render() {
    let { buffer, picCar } = this.state;
    const { banner, showCar, picCarImg } = this.state;
    return (
      <div>

        <div style={{ paddingTop: "100px", paddingBottom: "1rem" }}>
          <div
            style={{
              color: "black",
              marginTop: "2rem",
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <div className="rcornersbanner" style={{ color: "rgb(249, 0, 1)" }}>
              แก้ไขรถยนต์มือสอง
          </div>
            <Card.Link className=" rcornersnewsAdminCar " href="/second_hand_car">
              กลับ
          </Card.Link>
          </div>
          <Form
            className="car"
          >
            <Form.Group as={Row} controlId="exampleForm.ControlTextarea1">
              <Form.Label
                column
                sm="2.5"
                style={{ fontSize: "16px", fontWeight: "bold" }}
              >
                ชื่อรถ :
            </Form.Label>
              <Col>
                <Form.Control
                  value={this.state.name_second}
                  type="text"
                  onChange={this.handleChange}
                  name="name_second"
                  placeholder="ชื่อรถ"
                />
              </Col>
            </Form.Group>
          </Form>

          <Row style={{ padding: "1rem" }}>
            <FormControl
              style={{ display: "none" }}
              id="someone"
              type="file"
              onChange={e => this.handleshowCarImg(e.target.files)}
            />
            <div
              style={{
                margin: "auto",
                width: "50rem",
                height: "400px",
                backgroundColor: "#eee",
                position: "relative"
              }}
              hidden={this.state.showCar !== ""}
            >
              <label for="someone">
                <div
                  className="border-banner text-center"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    style={{ width: "200px", height: "200px", margin: "auto" }}
                    src={pic}
                  />
                    เลือกรูปรถโชว์หน้าแรก
              </div>
              </label>
            </div>

            <div style={{ margin: "auto" }} hidden={this.state.showCar === ""}>
              <img

                name="showCar"
                src={
                  showCar.indexOf("static") !== -1 ? _ip + "/" + showCar : showCar
                }

                style={{ width: "500px", height: "auto" }}
              />
            </div>
          </Row>
          <div
            style={{
              paddingLeft: "15rem",
              paddingRight: "15rem",
              justifyContent: "center",
              display: "flex"
            }}
          >
            <button
              className="rcornersnewsAdminCar"
              onClick={e => this.clearshowCarImg()}
            >
              ลบ
              </button>

          </div>
          <Row style={{ padding: "1rem" }}>
            <FormControl
              style={{ display: "none" }}
              id="some"
              type="file"
              onChange={e => this.handleBannerImg(e.target.files)}
            />
            <div
              style={{
                margin: "auto",
                width: "50rem",
                height: "400px",
                backgroundColor: "#eee",
                position: "relative"
              }}
              hidden={this.state.banner !== ""}
            >
              <label for="some">
                <div
                  className="border-banner text-center"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    style={{ width: "200px", height: "200px", margin: "auto" }}
                    src={pic}
                  />
                    เลือกBanner
              </div>
              </label>
            </div>

            <div style={{ margin: "auto" }} hidden={this.state.banner === ""}>
              <img
                width={1000}
                height={400}
                // src={this.state.banner}
                name="banner"
                src={
                  banner.indexOf("static") !== -1 ? _ip + "/" + banner : banner
                }
                style={{ width: "500px", height: "auto" }}
              />
            </div>
          </Row>

          <div
            style={{
              paddingLeft: "15rem",
              paddingRight: "15rem",
              justifyContent: "center",
              display: "flex"
            }}
          >
            <button
              className="rcornersnewsAdminCar"
              onClick={e => this.clearBannerImg()}
            >
              ลบ
              </button>

          </div>

          <Form
            className="car"
          >
            <div>
              <Form.Group as={Row} controlId="exampleForm.ControlTextarea1">
                <Form.Label
                  column
                  sm="2.5"
                  style={{ fontSize: "16px", fontWeight: "bold" }}
                >
                  ชื่อหัวข้อ :
              </Form.Label>
                <Col>
                  <Form.Control
                    onChange={this.handleChange}
                    name="header_des_second_car"
                    value={this.state.header_des_second_car}
                    type="text"
                    placeholder="ชื่อหัวข้อ"
                  />
                </Col>
              </Form.Group>
            </div>
            <div>
              <Form.Group as={Row} controlId="exampleForm.ControlTextarea1">
                <Form.Label
                  column
                  sm="2.5"
                  style={{ fontSize: "16px", fontWeight: "bold" }}
                >
                  คำอธิบาย :
              </Form.Label>
                <Col>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    placeholder="เขียนคำอธิบาย"
                    onChange={this.handleChange}
                    name="des_second_car"
                    value={this.state.des_second_car}
                  />
                </Col>
              </Form.Group>
            </div>
            <Form.Group as={Row} controlId="exampleForm.ControlTextarea1">
              <Form.Label
                column
                sm="2.5"
                style={{ fontSize: "16px", fontWeight: "bold" }}
              >
                ราคา :
            </Form.Label>
              <Col>
                <Form.Control
                  onChange={this.handleChange}
                  name="price_second"
                  value={this.state.price_second}
                  type="text"
                  placeholder="ราคา"
                />
              </Col>
            </Form.Group>
          </Form>

          {/* เพิ่มรูปรถ */}
          <Row
            style={{ padding: "1rem", display: "flex", justifyContent: "center" }}
          >
            {picCarImg &&
              picCarImg.map((el, i) => (
                <Col style={{ margin: "1rem 0 1rem 0" }}>
                  <div
                    style={{
                      margin: "auto",
                      width: "50rem",
                      height: "400px",
                      backgroundColor: 'transparent',
                      position: "relative"
                    }}
                  >
                    <div style={{ margin: "auto", height: '400px' }}>
                      <img

                        alt="picCarImg"
                        // src={el.picCarImg}
                        src={
                          el.source.indexOf("static") !== -1
                            ? _ip + "/" + el.source
                            : el.source
                        }
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      padding: "1rem",
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    <button
                      className="rcornersnewsAdminCar"
                      onClick={e => this.removeCarImg(e, i)}
                    >
                      ลบ
                  </button>
                  </div>
                </Col>
              ))}
            <Col>
              <FormControl
                style={{ display: "none" }}
                id="so"
                type="file"
                accept="image/x-png,image/jpeg"
                onChange={this.handleCarImg}
              />
              <div
                style={{
                  margin: "auto",
                  width: "50rem",
                  height: "400px",
                  backgroundColor: "#eee",
                  position: "relative"
                }}
                hidden={buffer.source !== ""}
              >
                <label for="so">
                  <div
                    className="border-banner text-center"
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      style={{ width: "200px", height: "200px", margin: "auto" }}
                      src={pic}
                    />
                      เลือกรูปรถ
                </div>
                </label>
              </div>

              <div
                style={{
                  margin: "auto",
                  justifyContent: "center",
                  display: "flex"
                }}
                hidden={buffer.source === ""}
              >
                <img
                  width={1000}
                  height={400}
                  alt="picCarImg"
                  src={buffer.source}
                  style={{ width: "auto", height: '400px' }}
                />
              </div>
              <div
                style={{
                  padding: "1rem",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <button className="rcornersnewsAdminCar" onClick={this.onSubmit}>
                  เพิ่ม
              </button>
                <button
                  className="rcornersnewsAdminCar"
                  onClick={e => this.clearBufferImg()}
                >
                  ลบ
              </button>
              </div>
            </Col>
          </Row>

          <div
            style={{
              paddingLeft: "15rem",
              paddingRight: "15rem",
              justifyContent: "center",
              display: "flex"
            }}
          >
            <button className="rcornerscar1" onClick={this.handleSubmit}>
              {this.state.isExampleLoading && <Spinner animation="border" variant="primary" />}
                บันทึก
          </button>
          </div>
        </div>
      </div >
    );
  }
}
