import React, { Component } from 'react'
import '../Asset/scss/About.scss'

export default class About extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="about-title">เกี่ยวกับเรา</div>
                <div className="about-definition">
                    “เราพร้อมแล้วที่จะมุ่งมั่นพัฒนาคุณภาพบุคลากรพร้อมบริการทุกระดับ <br />บริการทุกท่านด้วยรอยยิ้มและความเต็มใจ”
                </div>
                <div className="about-content">
                    <div className="about-content-img">
                        <img src={require('../Asset/images/about/about3.jpg')} alt="" />
                    </div>
                    <div className="about-content-detail">
                        <p>
                            บริษัท มิตซูอุดร จำกัด ได้รับแต่งตั้งให้เป็นตัวแทนจำหน่ายรถยนต์มิตซูบิชิ
                            จากบริษัท มิตซูบิชิมอเตอร์(ประเทศไทย) เมื่อปี พ.ศ. 2555 โดยให้บริการรถยนต์ครบวงจร
                            ทั้งส่วนงานขาย งานบริการหลังงานขาย ศูนย์บริการ ศูนย์อะไหล่และศูนย์ซ่อมสีและตัวถังที่ทันสมัย
                            ระดับมาตรฐาน บริษัทฯ เริ่มก่อตั้ง สาขาแรก ในพื้นที่ 5 ไร่ บนถนนทหาร ใจกลางเมืองอุดรธานี
                            ต่อมาในปี พ.ศ. 2561 ได้ขยายสาขาอำเภอบ้านผือ เพื่อเพิ่มพื้นที่การให้บริการลูกค้าในจังหวัดอุดรธานี
                            และอำเภอใกล้เคียง ได้อย่างทั่วถึง
                    </p>
                        <p>
                            ปัจจุบัน บริษัทฯ มีพนักงานกว่า 100 คน ซึ่งพนักงานทุกคน พร้อมให้การบริการลูกค้า ด้วยทีมขายให้
                            คำแนะนำด้านผลิตภัณฑ์ รถมิตซูบิชิทุกรุ่น และแนะนำปรึกษาข้อมูลด้านการเงินให้กับลูกค้าได้อย่างเหมาะสม
                            ส่วนทีมช่างผู้ชำนาญการ ระดับมืออาชีพ ผ่านการฝึกอบรมทักษะตามมาตรฐานของ บริษัท มิตซูบิชิมอเตอร์ (ประเทศไทย) จำกัด
                            เพื่อพร้อมสำหรับการให้บริการลูกค้า ซึ่งลูกค้าทุกท่านจะมั่นใจได้ว่ารถยนต์ของท่านจะได้รับการดูแลเป็นอย่างดีจากเรา
                            เราใส่ใจในการดูแลรักษารถยนต์ของท่าน ด้วยอะไหล่แท้ อุปกรณ์เครื่องมือที่ทันสมัย และส่วน Guarantee Used Car
                            ให้บริการแลกเปลี่ยนรถยนต์ รับซื้อรถยนต์ทุกรุ่นทุกยี่ห้อ ราคาดี
                    </p>
                    </div>
                </div>
                <div className="about-content d-flex justify-content-between flex-wrap">
                    <div className="content-card">
                        <div className="content-card-title">วิสัยทัศน์</div>
                        <div className="content-card-detail">
                            <div className="content-card-img">
                                <img src={require('../Asset/images/about/about.jpg')} alt="" />
                            </div>
                            <p>“มิตซูบิชิอุดร” เราบริการด้วยใจให้คุณมากกว่ารถยนต์</p>
                        </div>
                    </div>
                    <div className="content-card">
                        <div className="content-card-title">คำสโลแกนของบริษัท</div>
                        <div className="content-card-detail">
                            <div className="content-card-img">
                                <img src={require('../Asset/images/about/about1.jpg')} alt="" />
                            </div>
                            <p>“มุ่งพัฒนาสู่การเป็นองค์กรชั้นนำในธุรกิจรถยนต์ โดยเน้นการร่วมกันทำงานเป็นทีมเพื่อให้เกิดการบริการที่ดีและประทับใจ”</p>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    }
}
