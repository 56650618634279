import React, { Component } from "react";
import {
  Row,
  Col,
  FormControl,
  Spinner
} from "react-bootstrap";
import Icons from '../../Component/Icons/Icons'
import pic from "../../Asset/img/pic.png";
import Table from "react-bootstrap/Table";
import { GET, POST, _ip } from "../../service";
import Swal from "sweetalert2";
import './Add_banner.scss'


export default class Add_banner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filePic: {},
      filePicM: {},
      index: 0,
      banner: [],
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      bannerInfo: {
        source_banner: "",
        name_banner: "",
        id_banner: ""
      },
      bannerInfoM: {
        source_banner: "",
        name_banner: "",
        id_banner: ""
      },
      pic: "",
      isExampleLoading: false,
      mobile: ""
    };

  }

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

  onClose = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };
  componentDidMount() {

    this.fetch()
  }
  handleChange = (action, value) => {
    let { bannerInfo } = this.state;
    bannerInfo[action] = value;
    this.setState({
      bannerInfo
    });
  };
  handleChangeM = (action, value) => {
    let { bannerInfoM } = this.state;
    bannerInfoM[action] = value;
    this.setState({
      bannerInfoM
    });
  };


  handleSubmit = async () => {
    const formData = new FormData();
    formData.append("myImage", this.state.filePic);
    formData.append("name_banner", this.state.bannerInfo.name_banner);
    const formData2 = new FormData();
    formData2.append("myImage", this.state.filePicM);
    formData2.append("name_banner", this.state.bannerInfoM.name_banner);
    try {
      Swal.fire({
        title: "คุณต้องการเพิ่มข้อมูลใช่หรือไม่?",
        type: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "cancel"
      }).then(async result => {
        if (result.value) {
          try {
            this.setState({ isExampleLoading: true });
            let check = await POST("/create_banner", formData, true);
            formData2.append("id_banner", check);
            let check2 = await POST("/create_banner_mobile", formData2, true);
            Swal.fire("เพิ่มข้อมูลสำเร็จ", "", "success");
            this.fetch();
            this.setState({
              isExampleLoading: false,
              bannerInfo: {
                source_banner: ""
              },
              pic: "",
              mobile: ""
            });
          } catch (error) {
            Swal.fire("เกิดข้อผิดพลาดกรุณาลองใหม่", "", "warning");
            this.setState({
              isExampleLoading: false,
            });
          }
        }

      });

    } catch (error) {
      Swal.fire("เกิดข้อผิดพลาดกรุณาลองใหม่", "", "warning");
    }
  };
  fetch = async () => {
    try {
      let banner = await GET("banner_detail");

      this.setState({
        banner: banner
      });

    } catch (error) { }
  };



  handleImage = selectorFiles => {
    if (selectorFiles.length > 0) {
      var file = selectorFiles[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.setState({
          pic: reader.result
        });
        this.setState({
          filePic: selectorFiles[0]
        });
      };
      reader.onerror = function (error) { };
    } else {
      this.setState({
        pic: ""
      });
    }
  };
  handleshowCarImg = selectorFiles => {
    if (selectorFiles.length > 0) {
      var file = selectorFiles[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        console.log(reader.result)
        this.setState({
          mobile: reader.result
        });
        this.setState({
          filePicM: selectorFiles[0]
        });
      };
      reader.onerror = function (error) { };
    } else {
      this.setState({
        mobile: ""
      });
    }
  };
  clearImage = () => {
    this.setState({
      pic: ""
    });
  };
  clearImageMobile = () => {
    this.setState({
      mobile: ""
    });
  };

  deleteBanner = async id_banner => {
    Swal.fire({
      title: "คุณต้องการลบข้อมูลใช่หรือไม่?",
      // text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "cancel"
    }).then(result => {
      if (result.value) {
        let del = POST("delete_banner", { id_banner });
        Swal.fire("ลบข้อมูลสำเร็จ", "", "success");
      }
      this.fetch();
    });
  };


  render() {
    const { banner } = this.state;

    return (
      <div>
        <Row style={{ marginRight: "0px" }}>
          <Col xs={4} md={12}>
            <div className="Fontadmin" >
              <Row>
                <Col md={12}>
                  <div
                    className="rcornerscontact"
                    style={{ color: "rgb(249, 0, 1)" }}
                  >
                    อัปโหลดแบนเนอร์
                    </div>
                </Col>

              </Row>

              <Row style={{ padding: "1rem" }}>
                <FormControl
                  style={{ display: "none" }}
                  id="some1"
                  type="file"
                  accept="image/x-png,image/jpeg"
                  onChange={e => this.handleImage(e.target.files)}
                />
                <div
                  style={{
                    margin: "auto",
                    width: "1000px",
                    height: "400px",
                    backgroundColor: "#eee",
                    position: "relative"
                  }}
                  hidden={this.state.pic !== ""}
                >
                  <label for="some1">
                    <div
                      className="border-banner text-center"
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        style={{
                          width: "200px",
                          height: "200px",
                          margin: "auto"
                        }}
                        src={pic}
                      />
                        เลือกBanner
                      </div>
                  </label>
                </div>
                <div
                  style={{ margin: "auto" }}
                  hidden={this.state.pic === ""}
                >
                  <img
                    src={this.state.pic}
                    value={this.state.bannerInfo.source_banner}
                    style={{ width: "auto", height: '400px' }}
                  />
                </div>
              </Row>
              <Row>
                <Col
                  md={12}
                  style={{ justifyContent: "center", display: "flex" }}
                >
                  <img className="imgdelete"
                    src={Icons.IconDelete}
                    style={{
                      cursor: "pointer", textAlign: "center", height: "2rem",
                      width: "2rem"
                    }}
                    onClick={e => this.clearImage()}
                  />
                </Col>
              </Row>
              <Row style={{ padding: "1rem" }}>
                <FormControl
                  style={{ display: "none" }}
                  id="some"
                  type="file"
                  accept="image/x-png,image/jpeg"
                  onChange={e => this.handleshowCarImg(e.target.files)}
                />
                <div
                  style={{
                    margin: "auto",
                    width: "1000px",
                    height: "400px",
                    backgroundColor: "#eee",
                    position: "relative"
                  }}
                  hidden={this.state.mobile !== ""}
                >
                  <label for="some">
                    <div
                      className="border-banner text-center"
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        style={{
                          width: "200px",
                          height: "200px",
                          margin: "auto"
                        }}
                        src={pic}
                      />
                      <p>เลือกbanner MobileSize</p> (600px * 600px)
                    </div>
                  </label>
                </div>
                <div
                  style={{ margin: "auto" }}
                  hidden={this.state.mobile === ""}
                >
                  <img
                    src={this.state.mobile}
                    value={this.state.bannerInfoM.source_banner}
                    style={{ width: "auto", height: '400px' }}
                  />
                </div>
              </Row>
              <Row>
                <Col
                  md={7}
                  style={{ justifyContent: "flex-end", display: "flex" }}
                >

                  <button
                    className="rcornersbanner1"
                    onClick={() => this.handleSubmit()}
                  >
                    {this.state.isExampleLoading && (
                      <Spinner animation="border" variant="primary" />
                    )}
                      อัปโหลดแบนเนอร์
                </button>
                </Col>
                <Col
                  md={4}
                  style={{ justifyContent: "left", display: "flex" }}
                >
                  <img className="imgdelete"
                    src={Icons.IconDelete}
                    style={{
                      cursor: "pointer", textAlign: "center", height: "2rem",
                      width: "2rem"
                    }}
                    onClick={e => this.clearImageMobile()}
                  />
                </Col>
              </Row>


              <Row style={{ padding: "1rem" }}>
                <div
                  className="rcornerscontact"
                  style={{ color: "rgb(249, 0, 1)" }}
                >
                  ไฟล์แบนเนอร์
                  </div>
              </Row>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>ลำดับแบนเนอร์</th>
                    <th>ไฟล์แบนเนอร์</th>
                    <th > ภาพ </th>
                    <th>Function</th>
                  </tr>
                </thead>
                {banner.length > 0
                  ? banner.map((index, i) => (
                    <tbody key={index.id_banner}>
                      <tr>
                        <td>{i + 1}</td>
                        <td>{index.source_banner}</td>
                        <td style={{ display: "", margin: "auto" }}>
                          <div>
                            <img
                              src={_ip + "/" + index.source_banner}
                              style={{ width: "8rem", height: "4rem" }}
                            />
                          </div>
                        </td>
                        <td className="function-product">
                          <img className="imgdelete"
                            src={Icons.IconDelete}
                            style={{
                              cursor: "pointer", textAlign: "center", height: "2rem",
                              width: "2rem"
                            }}
                            onClick={() => this.deleteBanner(index.id_banner)}
                          />
                          {/* <FaTrash
                              className='function-banner'
                              onClick={() =>
                                this.deleteBanner(index.id_banner)
                              }
                            /> */}
                        </td>
                      </tr>
                    </tbody>
                  ))
                  : null}
              </Table>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
