import React, { Component } from 'react'
import { _ip, GET } from '../../service'
import '../../Asset/scss/Products.scss'

export default class Products extends Component {
    constructor(props) {
        super(props)
        this.state = {
            car: []
        }
    }
    componentDidMount() {
        this.getCar()
    }
    async getCar() {
        try {
            let res = await GET('car_detail')
            this.setState({ car: res })
            // console.log('getCar', res)
        } catch (error) {
            console.log('getCar err', error)
        }
    }
    render() {
        let { car } = this.state
        return (
            <React.Fragment>
                <div className="header">
                    <div className="header-title">รุ่นรถยนต์</div>
                </div>
                <div className="product-car row">
                    {car.map((e, i) =>
                        <div className="col-xl-3 col-lg-4 col-sm-6 col-12">
                            <div className="product-car-thumb" key={i}>
                                <div className="product-car-thumb-img">
                                    <img src={_ip + '/' + e.source_car} alt="" />
                                </div>
                                <div className="product-car-thumb-content">
                                    <strong>{e.name_car}</strong>
                                    <p><span>เริ่มต้นที่ </span> <span>{e.start_price.toLocaleString()} บาท</span></p>
                                    <button className="btn" onClick={() => window.location.assign(`/products/${e.id_car}/${e.name_car.replace(/ /g, '-').toLowerCase()}`)}>ดูรายละเอียด</button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </React.Fragment>
        )
    }
}
