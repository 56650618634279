import React, { Component } from 'react'
import '../Asset/scss/Contact.scss'

export default class Contact extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="header">
                    <div className="header-title">ติดต่อเรา</div>
                </div>
                <div className="contact">
                    <div className="contact-title">สำนักงานใหญ่</div>
                    <div className="contact-content">
                        <strong className="contact-content-title">บริษัท มิตซูอุดร จำกัด (สำนักงานใหญ่)</strong>
                        <p>81/1 ถนนทหาร ตำบลหมากแข้ง อำเภอเมือง จังหวัดอุดรธานี 41000</p>
                        <p className="d-flex"><strong>วันและเวลาทำการ :</strong>
                            <ul>
                                <li>จันทร์ - เสาร์ 08.30 - 17.00 น.</li>
                                <li>อาทิตย์ 08.30 - 16.30 น. (เฉพาะฝ่ายขาย)</li>
                            </ul>
                        </p>
                        <p><strong>ฝ่ายขาย :</strong><a href="tel:042-237333" target="_blank">042-237333 ต่อ 101</a></p>
                        <p><strong>นัดหมายศูนย์บริการ :</strong> <span><a href="tel:088-548-8181" target="_blank">088-548-8181</a>, <a href="tel:042-237333" target="_blank">042-237333</a></span></p>
                        <p><strong>Line :</strong> <a href="https://lin.ee/uwiTPCA" target="_blank">@mitsuudon</a></p>
                        <p><strong>Facebook :</strong><a href="https://www.facebook.com/MitsuudonFanpage/" target="_blank">มิตซูอุดร</a> </p>
                    </div>
                    <div className="contact-map">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d951.8600284320173!2d102.79962!3d17.390654!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfa66a880d352fdda!2sMitsubishi%20Udon!5e0!3m2!1sen!2sus!4v1585561292139!5m2!1sen!2sus"
                            frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0" />
                    </div>
                </div>
                <div className="contact">
                    <div className="contact-title">สาขาบ้านผือ</div>
                    <div className="contact-content">
                        <strong className="contact-content-title">บริษัท มิตซูอุดร จำกัด (สาขาบ้านผือ)</strong>
                        <p>81/1 หมู่ 8 ตำบลบ้านผือ อำเภอสาขาบ้านผือ จังหวัดอุดรธานี 41000</p>
                        <p><strong>วันและเวลาทำการ :</strong> <span>จันทร์ - เสาร์ 08.30 - 17.00 น.</span></p>
                        <p><strong>ฝ่ายขาย :</strong><a href="tel:062-196-7888" target="_blank">062-196-7888</a></p>
                        <p><strong>นัดหมายศูนย์บริการ :</strong><a href="tel:062-196-8777" target="_blank">062-196-8777</a></p>
                        <p><strong>Line :</strong><a href="https://lin.ee/3EEAHfJiC" target="_blank">@mitsuudon_banphue</a></p>
                        <p><strong>Facebook :</strong><a href="https://www.facebook.com/MitsuUdon.BaanPhue/" target="_blank">มิตซูอุดร สาขาบ้านผือ</a></p>
                    </div>
                    <div className="contact-map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d950.3117243530594!2d102.48919400000001!3d17.685793!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6ac0a62601f57d6a!2z4Lih4Li04LiV4LiL4Li54Lit4Li44LiU4LijIOC4quC4suC4guC4suC4muC5ieC4suC4meC4nOC4t-C4rQ!5e0!3m2!1sen!2sus!4v1585561959594!5m2!1sen!2sus"
                            frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0" />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
