import React, { Component } from 'react'
import { _ip, GET } from '../../service'
import '../../Asset/scss/News.scss'
import Pagination from '../../Component/Pagination/Pagination'
export default class News extends Component {
    constructor(props) {
        super(props)
        this.state = {
            news: [],
            currentNews: [],
            currentPage: null,
            totalPages: null
        }
    }
    componentDidMount() {
        this.getNews()
    }
    async getNews() {
        try {
            let res = await GET('news_detail')
            this.setState({ news: res })

        } catch (error) {
            console.log('getNews err', error)
        }
    }
    onPageChanged = data => {
        const { news } = this.state;
        const { currentPage, totalPages, pageLimit } = data;

        const offset = (currentPage - 1) * pageLimit;
        const currentNews = news.slice(offset, offset + pageLimit);

        this.setState({ currentPage, currentNews, totalPages });
    }
    render() {

        const { news, currentNews, currentPage, totalPages } = this.state;
        const totalNews = news.length;
        if (totalNews === 0) return null;
        return (
            <React.Fragment>
                <div className="header header-white">
                    <div className="header-title">ข่าวสารและกิจกรรม</div>
                </div>
                <div className="news row">
                    {currentNews.map((e, i) =>
                        <div className="col-xxl-3 col-xl-4 col-lg-6 col-sm-12 col-12">
                            <div className="news-thumb" key={i}>
                                <div className="news-thumb-img" onClick={() => window.location.assign(`/news/${e.id_news}/${e.name_news.replace(/ /g, '-')}`)}>
                                    <img src={_ip + '/' + e.source_news} alt="" />
                                </div>
                                <div className="news-thumb-content">
                                    <strong>{e.name_news}</strong>
                                    <p>{e.des_news}</p>
                                    <button className="btn" onClick={() => window.location.assign(`/news/${e.id_news}/${e.name_news.replace(/ /g, '-')}`)}>ดูรายละเอียด</button>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
                <div className="news row">
                    <Pagination totalRecords={totalNews} pageLimit={8} pageNeighbours={1} onPageChanged={this.onPageChanged} />
                </div>
            </React.Fragment>
        )
    }
}
