import React, { Component } from "react";

import Add_new from "./Add_new";

import { GET, POST, _ip } from "../../service";
import icon_danger from '../../Asset/img/icon-danger.png'
import Icons from '../../Component/Icons/Icons'
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
  Table
} from "reactstrap";
import classnames from "classnames";
import swal from 'sweetalert';
import Swal from 'sweetalert2'
import { Tabs, Tab } from 'react-bootstrap'
import './NewsAdmin.scss'

export default class News_Admin extends Component {
  constructor(props) {

    super(props);
    this.toggle = this.toggle.bind(this);
    this.fetch = this.fetch.bind(this);
    this.state = {
      index: 0,
      newsInfo: [],
      activeTab: "1"
    };
  }
  fetch = async () => {
    try {
      let newsInfo = await GET("/news_detail");

      this.setState({
        newsInfo: newsInfo
      });

    } catch (error) {
    }
  }
  componentDidMount = async () => {
    this.fetch()
  };

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  deleteNew = async (id_news) => {
    Swal.fire({
      title: 'คุณต้องการลบใช่หรือไม่?',
      // text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then((result) => {
      if (result.value) {
        let del = POST("delete_news", { id_news })
        Swal.fire(
          'ลบข้อมูลสำเร็จ',
          '',
          'success'
          // 'Your file has been deleted.',
          // 'success'
        )
      }
      this.fetch();
    })
  };

  render() {
    const { newsInfo } = this.state;
    return (
      <div>

        <Tabs defaultActiveKey="รายการทั้งหมด" id="uncontrolled-tab-example" className="tab">
          <Tab eventKey="รายการทั้งหมด" title="รายการทั้งหมด">
            <div className="rcornerscontact" style={{ color: "rgb(249, 0, 1)", marginTop: '10px' }}>รายการทั้งหมด</div>
            <Table striped bordered hover>
              <thead>
                <tr className="Fontadmin">
                  <th style={{ width: "2rem" }}>ลำดับ</th>
                  <th>หัวข้อ</th>
                  <th style={{ width: "10rem" }}>Function</th>
                </tr>
              </thead>
              {newsInfo.length > 0
                ? newsInfo.map((index, i) => (
                  <tbody key={index.id_news}>
                    <tr style={{ textAlign: "center" }}>
                      <td>{i + 1}</td>
                      <td>{index.name_news}</td>
                      <td className="function-new">
                        <div >
                          <a href={`/edit_new/${index.id_news}`} >
                            <i class="far fa-edit">
                            </i>
                          </a>
                        </div>
                        <div>
                          <a className='trash'>
                            <img className="imgdelete"
                              src={Icons.IconDelete}
                              style={{
                                cursor: "pointer", textAlign: "center", height: "2rem",
                                width: "2rem"
                              }}
                              onClick={e => this.deleteNew(index.id_news)}
                            />
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>

                ))
                : null}
            </Table>
          </Tab>
          <Tab eventKey="เพิ่มข่าวสารและกิจกรรม" title="เพิ่มข่าวสารและกิจกรรม">
            <div className="rcornerscontact" style={{ color: "rgb(249, 0, 1)", marginTop: '10px' }}>เพิ่มข่าวสารและกิจกรรม</div>
            <Add_new toggle={this.toggle} fetch={this.fetch} />
          </Tab>
        </Tabs>
      </div>
    );
  }
}


